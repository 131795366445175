<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg
      :class="{
        [$style.up]: direction === 'up',
        [$style.down]: direction === 'down',
        [$style.left]: direction === 'left',
        [$style.right]: direction === 'right',
      }"
      :viewBox="getSVGViewboxByIconSize(props.size)"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <template v-if="size === 'extra-small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.96967 5.53033C9.26256 5.82322 9.73744 5.82322 10.0303 5.53033C10.3232 5.23744 10.3232 4.76256 10.0303 4.46967L6.53033 0.96967C6.23744 0.676777 5.76256 0.676777 5.46967 0.96967L1.96967 4.46967C1.67678 4.76256 1.67678 5.23744 1.96967 5.53033C2.26256 5.82322 2.73744 5.82322 3.03033 5.53033L5.25 3.31066V10.5C5.25 10.9142 5.58579 11.25 6 11.25C6.41421 11.25 6.75 10.9142 6.75 10.5V3.31066L8.96967 5.53033Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12.5429 7.20711C12.9334 7.59763 13.5666 7.59763 13.9571 7.20711C14.3476 6.81658 14.3476 6.18342 13.9571 5.79289L8.70711 0.542893C8.31658 0.152369 7.68342 0.152369 7.29289 0.542893L2.04289 5.79289C1.65237 6.18342 1.65237 6.81658 2.04289 7.20711C2.43342 7.59763 3.06658 7.59763 3.45711 7.20711L7 3.66421V14.75C7 15.3023 7.44772 15.75 8 15.75C8.55229 15.75 9 15.3023 9 14.75V3.66421L12.5429 7.20711Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.2045 10.7955C18.6438 11.2348 19.3562 11.2348 19.7955 10.7955C20.2348 10.3562 20.2348 9.64384 19.7955 9.2045L12.7955 2.2045C12.3562 1.76517 11.6438 1.76517 11.2045 2.2045L4.2045 9.2045C3.76517 9.64384 3.76517 10.3562 4.2045 10.7955C4.64384 11.2348 5.35616 11.2348 5.79549 10.7955L10.875 5.71599V21C10.875 21.6213 11.3787 22.125 12 22.125C12.6213 22.125 13.125 21.6213 13.125 21V5.71599L18.2045 10.7955Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M24.9443 14.1389C25.4813 14.6759 26.3519 14.6759 26.8889 14.1389C27.4258 13.602 27.4258 12.7314 26.8889 12.1944L16.9722 2.27773C16.4352 1.74076 15.5646 1.74076 15.0276 2.27773L5.11098 12.1944C4.57401 12.7314 4.57401 13.602 5.11098 14.1389C5.64795 14.6759 6.51855 14.6759 7.05552 14.1389L14.6249 6.56954V28.75C14.6249 29.5094 15.2405 30.125 15.9999 30.125C16.7593 30.125 17.3749 29.5094 17.3749 28.75V6.56955L24.9443 14.1389Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import AppIcon from './AppIcon.vue';
import { type IconSize, getBoundsByIconSize, getSVGViewboxByIconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
  direction: 'up' | 'down' | 'right' | 'left';
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}

.up {
  transform: rotate(0deg);
}

.down {
  transform: rotate(180deg);
}

.right {
  transform: rotate(90deg);
}

.left {
  transform: rotate(-90deg);
}
</style>
