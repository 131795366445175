<template>
  <!-- Первая модалка, на которой спрашивают точно ли хотите отписаться. -->
  <app-modal-wrapper
    :max-width="400"
    :min-height="200"
    :data-tid="
      $getTestElementIdentifier(
        $ElementTestIdentifierScope.CancelSubscriptionModal,
        'confirmDisableAutoRenewModalWrapper',
      )
    "
  >
    <template v-if="pageTexts">
      <modal-title :title="pageTexts.title" />
      <modal-description :description="pageTexts.description" />

      <div :class="$style.buttons">
        <app-button
          variation="button-primary"
          :aria-label="$t('ariaLabel.disableAutoRenewal.cancelDisableSubscription')"
          :title="$t('ariaLabel.disableAutoRenewal.cancelDisableSubscription')"
          :text="pageTexts.keepSubscriptionBtnText"
          :data-tid="
            $getTestElementIdentifier($ElementTestIdentifierScope.CancelSubscriptionModal, 'cancelDisableButton')
          "
          @click="cancelPopupKeep"
        />

        <app-button
          :aria-label="$t('ariaLabel.disableAutoRenewal.confirmDisableSubscription')"
          :title="$t('ariaLabel.disableAutoRenewal.confirmDisableSubscription')"
          variation="link-alert"
          :text="pageTexts.unsubscribeBtnText"
          :data-tid="
            $getTestElementIdentifier($ElementTestIdentifierScope.CancelSubscriptionModal, 'confirmDisableButton')
          "
          @click="cancelConfirm"
        />
      </div>
    </template>

    <app-loader v-else />
  </app-modal-wrapper>
</template>

<script lang="ts" setup>
import { useSubscriptionAnalytics } from '@package/sdk/src/analytics';
import type { CancelSubscriptionPopupStrings } from '@package/sdk/src/api';
import { PageSlug } from '@package/sdk/src/api';
import { onMounted, ref } from 'vue';

import { useSubscriptionCancel } from '@/code/subscription/use-subscription-cancel';
import ModalDescription from '@/components/modals/ModalDescription.vue';
import ModalTitle from '@/components/modals/ModalTitle.vue';
import AppButton from '@/components/ui/AppButton.vue';
import { AppRoute } from '@/platform/router/routes';
import { useLayoutStore } from '@/stores/use-layout-store';
import { usePageStringsStore } from '@/stores/use-page-strings-store';

const { fetchFrontendPages } = usePageStringsStore();
const { $analyticSender } = useNuxtApp();
const subscriptionAnalytics = useSubscriptionAnalytics($analyticSender);
const { setModalView } = useSubscriptionCancel();

const router = useRouter();
const layoutStore = useLayoutStore();
const { $emitter } = useNuxtApp();

const pageTexts = ref<CancelSubscriptionPopupStrings | null>(null);

const cancelPopupKeep = () => {
  subscriptionAnalytics.onClickSubscribeManagePopup(false);

  setModalView('cancelConfirm', false);
  layoutStore.setCurrentModalName(null);

  // Человек решил остаться, уводим его на главную
  router.push({ name: AppRoute.Index });
};

const cancelConfirm = () => {
  subscriptionAnalytics.onClickSubscribeManagePopup(true);

  layoutStore.setCurrentModalName('ReasonsDisableAutoRenewalModal');
  $emitter.emit('app.modal.cancelConfirm.firstStep');
};

onMounted(async () => {
  subscriptionAnalytics.onShowSubscribeManagePopup();

  pageTexts.value = await fetchFrontendPages<CancelSubscriptionPopupStrings>(PageSlug.CancelSubscriptionPopup);
});
</script>

<style lang="scss" module>
.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--g-spacing-8);
  margin-top: var(--g-spacing-32);
}
</style>
