<template>
  <div :class="$style.playerSidebar">
    <div v-if="selectedItem" :class="$style.contentSelected">
      <app-link
        :to="link"
        :aria-label="$t('ariaLabel.content.watch', { title: selectedItem.contentTitle })"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Playlists, 'selectedContent')"
        @click="onContentLinkClick"
      >
        <div :class="$style.contentContainer">
          <div :class="$style.contentPosterWrapper">
            <div :key="selectedItem.contentPoster" :class="$style.contentPoster">
              <app-img
                :src="selectedItem.contentPoster as string"
                :width="280"
                :image-class="$style.contentPosterImage"
                :lazy="true"
                use-stub
              />
            </div>
          </div>

          <div :class="$style.contentInfo">
            <p :class="$style.contentTitle" :title="selectedItem.contentTitle" :aria-label="selectedItem.contentTitle">
              {{ selectedItem.contentTitle }}
            </p>
            <app-button
              :text="primaryButtonText"
              variation="button-secondary"
              :title="primaryButtonText"
              :aria-label="primaryButtonText"
              :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Playlists, 'watchButton')"
              @click="injectAdriverScript(AdriverAnalyticEvent.PromoWatchButton)"
            />
          </div>
        </div>
      </app-link>
      <p :class="$style.itemMore">{{ $t('playlistPage.moreInPlaylist') }}</p>
    </div>

    <div v-if="items" ref="sidebarEl" :class="$style.itemsWrapper">
      <div v-for="item in items" :key="item.id" :class="$style.itemWrapper">
        <slot :item="item" name="item">
          <div
            ref="sidebarEls"
            :data-id="item.id"
            :class="{
              [$style.active]: selectedItem && item.id === selectedItem.id,
              [$style.itemContent]: true,
            }"
            :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Playlists, 'contentItem')"
            @click="selectItem(item)"
          >
            <div
              :class="{
                [$style.noImage]: !item.preview,
                [$style.imageWrapper]: true,
              }"
            >
              <div :class="$style.imageContainer">
                <app-img
                  :src="item.preview || Stub.Default"
                  :width="280"
                  alt="img"
                  :image-class="$style.itemImage"
                  :lazy="true"
                />
              </div>
            </div>

            <div :class="$style.itemInfo">
              <span :class="$style.itemTitle" data-nosnippet>{{ item.contentTitle }}</span>
              <p :class="$style.itemDescription">
                <span data-nosnippet>
                  {{ item.title }}
                </span>
              </p>
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import useContentMediaButtonTexts from '@package/content-utils/src/code/content-media-button-texts';
import type { ContentMoment, Moment } from '@package/sdk/src/api';
import { DisposableStore, KeyCode, UnexpectedComponentStateError } from '@package/sdk/src/core';
import { useIntersectionObserver } from '@vueuse/core';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import type { RouteLocationRaw } from 'vue-router';

import { AdriverAnalyticEvent, useKmzaDriverEvents } from '@/code/kmza/use-driver-kmza';
import AppButton from '@/components/ui/AppButton.vue';
import AppImg from '@/components/ui/AppImg.vue';
import AppLink from '@/components/ui/AppLink.vue';
import { scrollToItem } from '@/platform/base/dom';
import { Stub } from '@/platform/image/Img';
import useMobile from '@/platform/layout/use-mobile';
import useLocale from '@/platform/localization/use-locale';
import { useCatalogStore } from '@/stores/use-catalog-store';

type CommonMoment = Moment | ContentMoment;

interface Props {
  items: CommonMoment[];
  useObserver: boolean;
  selectedItem: CommonMoment;
  link: RouteLocationRaw;
}

const props = withDefaults(defineProps<Props>(), {
  items: () => [],
  useObserver: false,
  link: '',
});

const emit = defineEmits<{
  (e: 'select-item', item: CommonMoment): void;
  (e: 'click-content-link'): void;
}>();

const sidebarEl = ref<HTMLDivElement>();

defineExpose({
  sidebarEl,
});

const isMobile = useMobile();
const { $keyboardHandler, $contentCacheManager } = useNuxtApp();
const { getMyChannelPlayButtonText } = useContentMediaButtonTexts();
const catalogStore = useCatalogStore();
const disposableStore = new DisposableStore();

const { injectAdriverScript } = useKmzaDriverEvents();
const { translateContentButtonText } = useLocale();

const isIntersect = ref(false);

if (props.useObserver) {
  const { stop: stopIntersect } = useIntersectionObserver(
    sidebarEl,
    ([{ isIntersecting }]) => {
      isIntersect.value = isIntersecting;
    },
    {
      threshold: [1, 1],
      root: null,
    },
  );

  onBeforeUnmount(() => {
    stopIntersect();
  });
}

const primaryButtonText = computed(() =>
  translateContentButtonText(
    getMyChannelPlayButtonText({
      contentType: props.selectedItem?.contentType,
    }),
  ),
);

const selectedItemIndex = computed(() => props.items?.findIndex((item) => item.id === props.selectedItem?.id));

const sidebarEls = ref<HTMLElement[]>();

const selectItem = (item: CommonMoment) => {
  emit('select-item', item);
};

const doSelectFromKeyboard = (item?: CommonMoment) => {
  if (!item || !isIntersect.value) {
    return;
  }

  selectItem(item);
  scrollToActiveItem();
};

onMounted(() => {
  if (!isMobile && props.useObserver) {
    disposableStore.add(
      $keyboardHandler.on(KeyCode.ArrowDown, (data) => {
        data.event.preventDefault();
        const item = props.items?.at(selectedItemIndex.value + 1);
        doSelectFromKeyboard(item);
      }),
    );

    disposableStore.add(
      $keyboardHandler.on(KeyCode.ArrowUp, (data) => {
        data.event.preventDefault();
        const item = props.items?.at(selectedItemIndex.value - 1);
        doSelectFromKeyboard(item);
      }),
    );
  }

  window.setTimeout(() => {
    scrollToActiveItem();
  }, 1000);
});

onBeforeUnmount(() => {
  disposableStore.dispose();
});

const scrollToActiveItem = () => {
  if (!props.selectedItem) {
    throw new UnexpectedComponentStateError('selectedItem');
  }

  if (!sidebarEls.value) {
    throw new UnexpectedComponentStateError('sidebarEls');
  }

  const { id } = props.selectedItem;
  const currentEl = sidebarEls.value.find((element) => element.getAttribute('data-id') === id);

  if (!currentEl || !sidebarEl.value) {
    return;
  }

  scrollToItem(currentEl, sidebarEl.value);
};

const onContentLinkClick = () => {
  $contentCacheManager.addToCache(props.selectedItem as ContentMoment);
  catalogStore.setCurrentLinkedContentKinom(props.selectedItem as ContentMoment);
  emit('click-content-link');
};
</script>

<style lang="scss" module>
@use '@/assets/aspect-ratio' as aspect-ratio;
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use '@/assets/breakpoints' as breakpoints;

.playerSidebar {
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.imageWrapper {
  margin-right: var(--g-spacing-20);
  position: relative;
  width: 140px;
}

.imageWrapper.noImage {
  position: relative;

  .itemImage {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    transform: translate(-50%, -50%);
  }
}

.imageContainer {
  width: 140px;
  height: 100%;
  border-radius: 24px;
  overflow: hidden;
  transform: translateZ(0);
}

.itemWrapper,
.contentContainer {
  padding-left: var(--g-spacing-24);
  padding-right: var(--g-spacing-24);
}

.itemsWrapper {
  border-radius: 30px;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 30px;
  scroll-behavior: smooth;

  &::-webkit-scrollbar-thumb {
    background-color: var(--color-bg-tertiary);
  }
}

.itemsWrapper .active {
  border-radius: 24px;
  background-color: var(--color-bg-tertiary);
  cursor: default;
}

.itemWrapper {
  margin-top: var(--g-spacing-16);
  width: 100%;

  &:first-child {
    margin-top: 0;
  }
}

.itemContent {
  display: flex;
  border-radius: 24px;
  min-height: 92px;

  &:hover {
    border-radius: 24px;
    background-color: var(--color-bg-tertiary);
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
}

.itemImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.itemInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 287px;
}

.itemTitle,
.itemDescription {
  font-style: normal;
}

.itemTitle {
  margin-bottom: var(--g-spacing-8);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--color-text-primary);

  @include fonts.WebBody-1;
}

.itemDescription {
  margin: 0;
  color: var(--color-text-secondary);

  @include fonts.WebBody-3;
}

.contentContainer {
  display: flex;
  align-items: center;
  padding-bottom: var(--g-spacing-16);
  padding-top: var(--g-spacing-16);
}

.contentPosterWrapper {
  position: relative;
  flex-shrink: 0;
  margin-right: var(--g-spacing-16);
  width: 94px;
  border-radius: 24px;
  background-color: var(--color-bg-tertiary);
  overflow: hidden;
  cursor: pointer;
}

.contentPoster {
  display: flex;

  @include aspect-ratio.ratio(1, 1.4);
}

.contentPosterImage {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contentPosterImage.stub {
  top: 50%;
  left: 50%;
  display: block !important;
  width: 40%;
  transform: translate(-50%, -50%);
}

.contentTitle {
  margin-bottom: var(--g-spacing-24);
  cursor: pointer;
  transition: color 0.3s ease-in-out;

  @include fonts.WebBody-1;

  &:hover {
    color: var(--color-text-secondary);
  }
}

.itemMore {
  padding: var(--g-spacing-16) var(--g-spacing-24);
  text-transform: uppercase;
  color: var(--color-text-secondary);

  @include fonts.WebHeadline-3;
}

@include breakpoints.min-width-1921 {
  .itemInfo {
    width: 467px;
  }
}

@include breakpoints.max-width-1280 {
  .itemInfo {
    width: 207px;
  }
}

@include breakpoints.max-width-1024 {
  .contentContainer {
    padding: var(--g-spacing-12) var(--g-spacing-8);
  }

  .itemInfo {
    width: initial;
  }

  .itemWrapper {
    margin-top: var(--g-spacing-8);
    padding: 0 var(--g-spacing-8);
  }

  .itemWrapper:first-child {
    margin: 0;
  }
}

@include breakpoints.max-width-540 {
  .contentPosterWrapper {
    width: 74px;
  }

  .itemMore {
    display: none;
  }

  .itemTitle {
    white-space: initial;
  }

  .itemInfo {
    padding: var(--g-spacing-4);
  }
}

@include breakpoints.max-width-375 {
  .imageWrapper {
    margin-right: var(--g-spacing-10);
  }
}
</style>
