export enum PromoCodeState {
  NotSettled,
  Loading,
  Success,
  Error,
}

export enum BonusProgram {
  PromoCode = 'promo-code',
  Referral = 'referral',
}

export interface IBonusProgramItem {
  title: string;
  routeName: string;
}
