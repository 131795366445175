<template>
  <app-sdk-checkbox :id="id" :model-value="modelValue" :value="value" :label="label" @update:model-value="onUpdate" />
</template>

<script lang="ts" setup>
import AppSdkCheckbox from '@package/ui/src/components/AppCheckbox.vue';

defineProps({
  id: { type: String, required: false },
  modelValue: { type: [Array, Boolean] },
  value: { type: [Boolean, Object] },
  label: { type: String, default: '' },
});

const emit = defineEmits(['update:modelValue']);

const onUpdate = (value: boolean) => {
  emit('update:modelValue', value);
};
</script>
