import { getWebDetailedDeviceName } from '@package/sdk/src/core';

import useEnvironment from '../../platform/environment/use-environment';
import type { IUserCreateSession } from '../user/use-users-api';
import { getOperationSystemName, PLATFORM_NAME } from './platform';

export default function useDeviceInfo(): IUserCreateSession {
  const os = getOperationSystemName();
  const { browserName, browserVersion, osVersion } = getWebDetailedDeviceName();
  const { appVersion } = useEnvironment();

  const deviceName = (() => {
    if (!browserVersion) {
      return `v.${osVersion} / ${browserName}`;
    }

    return `v.${osVersion} / ${browserName} v.${browserVersion}`;
  })();

  return {
    device: {
      type: PLATFORM_NAME,
      os,
      app_version: appVersion,
      name: deviceName,
      uid: '0',
    },
  };
}
