<template>
  <app-icon :width="width" :height="height">
    <svg
      v-if="size === 'normal'"
      width="72"
      height="48"
      viewBox="0 0 72 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7177 30.1389L7.55656 13.7143H0L11.1622 38.2041H17.8789C21.4845 30.2855 25.3864 22.0737 29.1892 13.7143H21.978L14.7177 30.1389Z"
        fill="#00C7B2"
      />
      <path
        d="M39.3778 37.9648C39.3778 40.3754 38.5184 42.2935 35.4146 42.2935H30.1622V48H35.9401C42.5775 48 45.7297 44.5077 45.7297 37.7196V13.7143H39.3788V37.9648H39.3778Z"
        fill="#00C7B2"
      />
      <path
        d="M65.4194 13.7143V27.7601C65.4194 31.0237 63.8361 33.3481 60.4231 33.3481C56.9107 33.3481 55.2282 31.2211 55.2282 27.7601V13.7143H48.6486V28.4516C48.6486 35.7216 52.9531 39.1837 60.2747 39.1837C67.2994 39.1837 72 35.6725 72 28.2542V13.7143H65.4194Z"
        fill="#00C7B2"
      />
      <path
        d="M42.3759 0C40.0925 0 38.3161 1.29409 37.4711 3.20624C36.6567 1.29409 34.9373 0 32.6885 0C29.4116 0 27.2432 2.58927 27.2432 5.8513C27.2432 9.11332 29.4116 11.7551 32.6885 11.7551C34.9373 11.7551 36.6557 10.4348 37.4701 8.5073C38.3151 10.4337 40.0925 11.7551 42.3749 11.7551C45.4595 11.7551 47.6757 9.11442 47.6757 5.8513C47.6757 2.58818 45.4595 0 42.3759 0Z"
        fill="#00C7B2"
      />
      <path d="M36.973 13.7143H30.1622V38.2041H36.973V13.7143Z" fill="#00C7B2" />
    </svg>

    <svg
      v-else-if="size === 'mini'"
      width="58"
      height="38"
      viewBox="0 0 58 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.1012 23.7428L6.21317 11H0L9.17778 30H14.7004C17.665 23.8565 20.8733 17.4855 24 11H18.0708L12.1012 23.7428Z"
        fill="#00C7B2"
      />
      <path
        d="M31.6957 30.0973C31.6957 31.9956 30.978 33.5061 28.3861 33.5061H24V38H28.825C34.3677 38 37 35.2498 37 29.9042V11H31.6966V30.0973H31.6957Z"
        fill="#00C7B2"
      />
      <path
        d="M52.6456 11V22.0295C52.6456 24.5923 51.3574 26.4176 48.5804 26.4176C45.7225 26.4176 44.3535 24.7473 44.3535 22.0295V11H39V22.5726C39 28.2814 42.5024 31 48.4596 31C54.1753 31 58 28.2428 58 22.4176V11H52.6456Z"
        fill="#00C7B2"
      />
      <path
        d="M33.8499 0C32.0619 0 30.6708 0.990787 30.0092 2.45477C29.3714 0.990787 28.025 0 26.2641 0C23.698 0 22 1.98241 22 4.4799C22 6.97739 23.698 9 26.2641 9C28.025 9 29.3706 7.98911 30.0084 6.5134C30.67 7.98827 32.0619 9 33.8491 9C36.2646 9 38 6.97822 38 4.4799C38 1.98157 36.2646 0 33.8499 0Z"
        fill="#00C7B2"
      />
      <path d="M29 11H24V30H29V11Z" fill="#00C7B2" />
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import AppIcon from './AppIcon.vue';

const props = defineProps<{
  size: 'normal' | 'mini';
}>();

const width = computed(() => (props.size === 'normal' ? 72 : 58));
const height = computed(() => (props.size === 'normal' ? 48 : 38));
</script>
