export default function useThemeColor() {
  const setThemeColor = (color: string) => {
    const themeElement = document.querySelector('meta[name="theme-color"]');
    themeElement?.setAttribute('content', color);
  };

  return {
    setThemeColor,
  };
}
