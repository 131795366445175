import type { ParentalControlModalState } from '@package/sdk/src/api';

import type { CollectionStorage } from '@/code/collections/collections';
import type { AppTheme } from '@/code/layout/use-app-theme';

export enum LocalStorageKey {
  AdmitadUid = 'admitad_uid',
  ParentalControlModalState = 'parental_control_modal_state',
  AppLanguage = 'viju.app_lang',
  TvPromoRedirectUrlAfterReg = 'viju.tv_redirect_url_after_reg',
  TvPromoPopupShown = 'viju.tv_promo_popup_shown',
  MyChannelLikes = 'viju.my_channel_local_likes',
  MyChannelDislikes = 'viju.my_channel_local_dislikes',
  Collection = 'viju.my_local_collection',

  MyChannelOnboardingPopupShown = 'viju.my_channel_onboarding_popup_shown',
  MyChannelWatchAndRatePopupShown = 'viju.my_channel_watch_and_rate_popup_shown',
  MyChannelRegistrationPopupShown = 'viju.my_channel_registration_popup_shown',
  MyChannelLikePopupShown = 'viju.my_channel_like_popup_shown',
  MyChannelDislikePopupShown = 'viju.my_channel_dislike_popup_shown',
  MyChannelCollectionPopupShown = 'viju.my_channel_collection_popup_shown',
}

export interface LocalStorageValueMap {
  admitad_uid: string;
  parental_control_modal_state: ParentalControlModalState;

  'viju.tv_redirect_url_after_reg': string;
  'viju.tv_promo_popup_shown': boolean;
  'viju.app_theme': AppTheme;
  'viju.my_channel_likes': string[];
  'viju.collection': CollectionStorage;
  'viju.my_channel_onboarding_popup_shown': boolean;
  'viju.my_channel_watch_and_rate_popup_shown': boolean;
  'viju.my_channel_like_popup_shown': boolean;
  'viju.my_channel_dislike_popup_shown': boolean;
  'viju.my_channel_collection_popup_shown': boolean;
  'viju.my_channel_registration_popup_shown': boolean;
}
