import type { VijuPlayer } from '@package/media-player/src/player/modules/instance/player';
import type { IDisposable } from '@package/sdk/src/core';
import * as Sentry from '@sentry/vue';

export default function usePlayerSentryError() {
  let disposable: IDisposable | undefined;

  const startRecordingPlayerExceptions = (player: VijuPlayer) => {
    disposable = player.on('error', (error) => Sentry.captureException(error));
  };

  onUnmounted(() => {
    disposable?.dispose();
  });

  return {
    startRecordingPlayerExceptions,
  };
}
