import { ProcessingMapper } from '../base/processing-mapper';
import { ApiFastFilter } from '../content/content-types/filtered-media';
import { FastFilter } from '../content/types/filtered-media';

export class FastFilterMapper {
  static mapMany(filters: ApiFastFilter[]): FastFilter[] {
    return ProcessingMapper.process(filters.map(FastFilterMapper.map));
  }

  static map({ ...rest }: ApiFastFilter): FastFilter {
    return ProcessingMapper.process({ ...rest });
  }
}
