import { AppLanguage } from '../localization/language';
import type { CompanyInformationMultipleLanguages } from './variation-types';

const TECH_SUPPORT_EMAIL = 'help@viju.am';
const SocialMediaLink = {
  Telegram: 'https://t.me/viju_kino',
  TelegramBot: 'https://t.me/vijusupport_bot',
  Vk: 'https://vk.me/viju_kino',
};
const OFFICE_ADDRESS_RU = 'Республика Армения, г. Ереван, проспект Саят-Новы, 19/1, офис 129';
const OFFICE_ADDRESS_EN = 'Republic of Armenia, Yerevan, Sayat-Nova Avenue, 19/1, office 129';

const amCompanyInformation: CompanyInformationMultipleLanguages = {
  [AppLanguage.RU]: {
    supportEmail: TECH_SUPPORT_EMAIL,
    contacts: [
      {
        title: 'Email:',
        value: TECH_SUPPORT_EMAIL,
      },
    ],
    socials: SocialMediaLink,
    officeAddress: OFFICE_ADDRESS_RU,
    workTime: 'Понедельник — пятница с 9:00 до 18:00',
    companyInformation: ['OOO «Ви Бродкастинг»', 'ИНН: 02867688', 'ОГРН: 273.110.1245813'],
  },
  [AppLanguage.EN]: {
    supportEmail: TECH_SUPPORT_EMAIL,
    contacts: [
      {
        title: 'Email:',
        value: TECH_SUPPORT_EMAIL,
      },
    ],
    socials: SocialMediaLink,
    officeAddress: OFFICE_ADDRESS_EN,
    workTime: 'Monday - Friday from 9:00 to 18:00',
    companyInformation: ['LLC «V Broadkasting»', 'TIN: 02867688', 'RN: 273.110.1245813'],
  },
};

export function getAmCompanyInformation(appLanguage: AppLanguage) {
  return amCompanyInformation[appLanguage] || amCompanyInformation[AppLanguage.EN];
}
