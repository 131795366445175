import type { SessionTokens } from '@package/sdk/src/api';
import type { CookieOptions } from 'nuxt/app';
import { useCookie } from 'nuxt/app';

import type { CollectionStorage } from '@/code/collections/collections';
import type { AppTheme } from '@/code/layout/use-app-theme';

import { AppLanguage } from '../localization/language';
import { CookieName } from './cookies';

type Nullable<T> = { [K in keyof T]: T[K] };

type CookieValueMap = Nullable<{
  [CookieName.DebugProductionApiForced]: boolean;
  [CookieName.GoogleAnalyticsId]: string;
  [CookieName.VisitorId]: string;

  [CookieName.IsBarrierPassed]: boolean;
  [CookieName.UserId]: string | undefined;
  [CookieName.Auth]: SessionTokens | undefined;

  [CookieName.ReferralModalShowingDate]: Date;

  [CookieName.BookmarkAddInfoCounter]: number;
  [CookieName.BookmarkRemoveInfoCounter]: number;

  [CookieName.AppLanguage]: AppLanguage;

  [CookieName.Collection]: CollectionStorage | undefined;

  [CookieName.Promocode]: string;
  [CookieName.AppTheme]: AppTheme;
}>;

export default function useAppCookie<T extends CookieName>(key: T, options?: CookieOptions) {
  return useCookie<CookieValueMap[T]>(key, options as any);
}
