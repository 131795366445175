<template>
  <ul :class="$style.list">
    <li
      v-for="option in options"
      :key="option.value"
      :class="$style.listItem"
      :aria-label="option.label"
      :title="option.label"
      @click="onOptionClick(option)"
    >
      {{ option.label }}
    </li>
  </ul>
</template>

<script lang="ts" setup>
import type { DropdownOption } from '@/components/app-dropdown/AppDropdown.vue';

const emit = defineEmits<{
  (e: 'on-option-click', option: DropdownOption): void;
}>();

withDefaults(
  defineProps<{
    options: DropdownOption[];
  }>(),
  {},
);

const onOptionClick = (option: DropdownOption) => {
  emit('on-option-click', option);
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts' as fonts;

.list {
  display: flex;
  padding: var(--g-spacing-8) 0;
  width: 100%;
  border-radius: var(--g-border-round-12);
  background-color: var(--color-notheme-bg-secondary-70);
  flex-direction: column;
  backdrop-filter: blur(10px);
}

.listItem {
  padding: var(--g-spacing-16);
  width: 100%;
  color: var(--color-notheme-text-primary);
  cursor: pointer;
  transition: background-color 0.2s ease-out;
  border-bottom: 1px solid var(--color-notheme-bg-tertiary-80);

  @include fonts.WebLabel-2;

  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background-color: var(--color-notheme-bg-tertiary-80);
  }
}
</style>
