<template>
  <div v-if="isAuth" class="i-flocktory" :data-fl-user-email="flocktoryUser"></div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { useSessionStore } from '@/stores/use-session-store';

const sessionsStore = useSessionStore();
const { user, isAuth } = storeToRefs(sessionsStore);

const flocktoryUser = computed(() => {
  if (!user.value) {
    return '';
  }
  return user.value!.email || `${user.value!.phoneNumber}@unknown.email`;
});
</script>
