import revive_payload_client_tgfIYh5tNk from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_encoding@0.1.13_eslint@8.57.0_ioredis@5._kbvajveb25kuurn7wlmq4q4jae/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_QueWPYrMEM from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_encoding@0.1.13_eslint@8.57.0_ioredis@5._kbvajveb25kuurn7wlmq4q4jae/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_oN1PxzjnrY from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_encoding@0.1.13_eslint@8.57.0_ioredis@5._kbvajveb25kuurn7wlmq4q4jae/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_q5MoGPSaRz from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_encoding@0.1.13_eslint@8.57.0_ioredis@5._kbvajveb25kuurn7wlmq4q4jae/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_TCEqil05rO from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_encoding@0.1.13_eslint@8.57.0_ioredis@5._kbvajveb25kuurn7wlmq4q4jae/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_M07WjPIX57 from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_encoding@0.1.13_eslint@8.57.0_ioredis@5._kbvajveb25kuurn7wlmq4q4jae/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_tonejM11Rd from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_encoding@0.1.13_eslint@8.57.0_ioredis@5._kbvajveb25kuurn7wlmq4q4jae/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_qT6nhgBDGv from "/srv/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_@vue+composition-api@1.7.2_vue@3.5.8_typescript@5.6.2___magicast@0.3.5_rol_aguast5jaigqlajdnvoqgnw53q/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/srv/app/apps/web/.nuxt/components.plugin.mjs";
import prefetch_client_Cf2A5wWHdD from "/srv/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_encoding@0.1.13_eslint@8.57.0_ioredis@5._kbvajveb25kuurn7wlmq4q4jae/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import nuxt_plugin_IScYOqucHV from "/srv/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@2.79.1_webpack-sources@3.2.3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import pwa_icons_Zqw7KbVQCn from "/srv/app/node_modules/.pnpm/@vite-pwa+nuxt@0.8.1_magicast@0.3.5_rollup@2.79.1_vite@5.4.8_@types+node@22.5.5_sass@1.79.3_t_ifh2tvzm7pvinfko7cbcetja7e/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_GYkgDzQBpM from "/srv/app/node_modules/.pnpm/@vite-pwa+nuxt@0.8.1_magicast@0.3.5_rollup@2.79.1_vite@5.4.8_@types+node@22.5.5_sass@1.79.3_t_ifh2tvzm7pvinfko7cbcetja7e/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import global_variables_injector_57JJW3i2Jo from "/srv/app/apps/web/src/plugins/global-variables-injector.ts";
import i18n_YCWKwfVXF6 from "/srv/app/apps/web/src/plugins/i18n.ts";
import fetch_chunk_error_wsemGEFjub from "/srv/app/apps/web/src/plugins/fetch-chunk-error.ts";
import http_client_GlLqeBauh7 from "/srv/app/apps/web/src/plugins/http-client.ts";
import sentry_h0fMjCG9AB from "/srv/app/apps/web/src/plugins/sentry.ts";
import analytics_ao9ICGyq1A from "/srv/app/apps/web/src/plugins/analytics.ts";
import o_auth_q4QM5teNWl from "/srv/app/apps/web/src/plugins/o-auth.ts";
import broadcast_channel_E2KTU8l9I0 from "/srv/app/apps/web/src/plugins/broadcast-channel.ts";
import boot_dFreyY2dpY from "/srv/app/apps/web/src/plugins/boot.ts";
export default [
  revive_payload_client_tgfIYh5tNk,
  unhead_QueWPYrMEM,
  router_oN1PxzjnrY,
  payload_client_q5MoGPSaRz,
  navigation_repaint_client_TCEqil05rO,
  check_outdated_build_client_M07WjPIX57,
  chunk_reload_client_tonejM11Rd,
  plugin_vue3_qT6nhgBDGv,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Cf2A5wWHdD,
  nuxt_plugin_IScYOqucHV,
  pwa_icons_Zqw7KbVQCn,
  pwa_client_GYkgDzQBpM,
  global_variables_injector_57JJW3i2Jo,
  i18n_YCWKwfVXF6,
  fetch_chunk_error_wsemGEFjub,
  http_client_GlLqeBauh7,
  sentry_h0fMjCG9AB,
  analytics_ao9ICGyq1A,
  o_auth_q4QM5teNWl,
  broadcast_channel_E2KTU8l9I0,
  boot_dFreyY2dpY
]