import { getFirstElement } from '@package/sdk/src/core';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { removeHttps } from '@/platform/base/string';
import { AppLanguage, LanguageTitle } from '@/platform/localization/language';
import { useHostname } from '@/platform/network/use-hostname';
import { AppVariation } from '@/platform/variation/interfaces';
import useVariationVariables from '@/platform/variation/use-variation-variables';
import { useLayoutStore } from '@/stores/use-layout-store';

type LanguageItem = {
  title: LanguageTitle;
  value: AppLanguage;
};

export const availableLanguages = {
  [AppVariation.Ru]: [AppLanguage.RU],
  [AppVariation.Am]: [AppLanguage.EN, AppLanguage.RU, AppLanguage.AM],
};

const languageTitleMap = {
  [AppLanguage.RU]: LanguageTitle.Ru,
  [AppLanguage.EN]: LanguageTitle.En,
  [AppLanguage.AM]: LanguageTitle.Am,
};

const generateLanguageList = (appLanguages: AppLanguage[]): LanguageItem[] => {
  return appLanguages.map((lang) => ({
    value: lang,
    title: languageTitleMap[lang],
  }));
};

export default function useHostVariation() {
  const { appVariation } = useVariationVariables();
  const hostName = removeHttps(useHostname());
  const { currentAppLanguage } = storeToRefs(useLayoutStore());

  const marketLanguages = availableLanguages[appVariation];

  const languageList = computed<LanguageItem[]>(() => {
    if (marketLanguages.length > 0) {
      return generateLanguageList(marketLanguages);
    }

    return [];
  });

  const baseTemplateTitle = computed(() => {
    if (appVariation === AppVariation.Am) {
      return `Movies and series online in HD quality - look at ${hostName}`;
    }

    return `Фильмы и сериалы онлайн в HD качестве - смотрите на ${hostName}`;
  });

  const appLanguage = computed(() => {
    if (currentAppLanguage.value) {
      return currentAppLanguage.value;
    }

    return getFirstElement(marketLanguages) as AppLanguage;
  });

  const isPromocodeEnabled = true;

  const isRecommendationsTechShown = appVariation === AppVariation.Ru;

  return {
    hostName,
    isRecommendationsTechShown,
    languageList,
    baseTemplateTitle,
    isPromocodeEnabled,
    appVariation,
    appLanguage,
  };
}
