import { isClient } from '@vueuse/core';
import { H3Event } from 'h3';

import { isFirefox, isSafari } from './platform';

export const scrollToItem = (targetEl: HTMLElement, containerEl: HTMLElement, scrollOptions = { smooth: true }) => {
  if (scrollOptions.smooth) {
    containerEl.style.scrollBehavior = 'smooth';
  }

  const { width: containerWidth, height: containerHeight } = containerEl.getBoundingClientRect();
  const { width: elWidth, height: elHeight } = targetEl.getBoundingClientRect();

  const elOffsetLeft = targetEl.offsetLeft;
  const elOffsetTop = targetEl.offsetTop;

  const newScrollLeft = elOffsetLeft - (containerWidth - elWidth);
  const newScrollTop = elOffsetTop - (containerHeight + elHeight);

  containerEl.scrollLeft = newScrollLeft;
  containerEl.scrollTop = newScrollTop;

  if (scrollOptions.smooth) {
    containerEl.style.scrollBehavior = '';
  }
};

export const getFullscreenElement = () => {
  if (!isClient) {
    return undefined;
  }

  if (isSafari) {
    return document.webkitFullscreenElement;
  }

  if (isFirefox) {
    return document.mozFullScreenElement;
  }

  return document.fullscreenElement;
};

export const isMobileDevice = (req: H3Event): boolean => {
  if (isClient) {
    return /Mobi/i.test(window.navigator.userAgent);
  }

  if (!isClient) {
    const ua = req.node.req.headers['user-agent'] as string;

    return /Mobi/i.test(ua);
  }

  return false;
};

export const detectInputAutoFill = (element: HTMLInputElement): Promise<boolean> => {
  return new Promise((resolve) => {
    window.setTimeout(() => {
      resolve(window.getComputedStyle(element, null).getPropertyValue('appearance') === 'menulist-button');
    }, 600);
  });
};
