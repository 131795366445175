export function calculateAspectRatio(a: number, b: number): number {
  return b === 0 ? a : calculateAspectRatio(b, a % b);
}

export function calculateHeightAspectRatio(width: number, ratio1: number, ratio2: number): number {
  const ratio = width / ratio1;

  return ratio * ratio2;
}

export default function isNegativeNumber(num: number): boolean {
  return num < 0;
}
