<template>
  <div
    ref="popupRef"
    :class="{ [$style.abstractMyChannel]: true, [$style.abstractMyChannelMobile]: isMobile }"
    @click="onClose"
  >
    <icon-kinom :size="windowWidth > 1024 ? 'medium' : 'small'" :class="$style.icon" />
    <div :class="$style.textWrapper">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onClickOutside, useWindowSize } from '@vueuse/core';

import useMobile from '../../platform/layout/use-mobile';
import IconKinom from '../icons/IconKinom.vue';

const props = defineProps<{
  onClose?: VoidFunction;
}>();

const isMobile = useMobile();
const popupRef = ref<HTMLDivElement>();

const { width: windowWidth } = useWindowSize();

onClickOutside(popupRef, () => {
  if (props.onClose) {
    props.onClose();
  }
});
</script>

<style lang="scss" module>
@use '@/assets/breakpoints' as breakpoints;

.abstractMyChannel {
  display: flex;
  align-items: flex-start;
  padding: var(--g-spacing-20) var(--g-spacing-16) var(--g-spacing-16) var(--g-spacing-16);
  max-width: 320px;
  border-radius: var(--g-border-round-12);
  background: var(--color-notheme-bg-secondary-80);
  color: var(--color-notheme-text-primary) !important;
  backdrop-filter: blur(6.050000190734863px);
  white-space: pre-line;
  cursor: default;
}

.abstractMyChannelMobile {
  width: 100%;
  max-width: 640px;
}

.textWrapper {
  margin-left: var(--g-spacing-8);
}

.icon {
  color: var(--color-icon-accent);
}

.close {
  position: absolute;
  top: var(--g-spacing-16);
  right: var(--g-spacing-16);
}

@include breakpoints.max-width-1024 {
  .icon {
    margin-top: var(--g-spacing-2);
  }
}
</style>
