import useLogger from '@package/logger/src/use-logger';
import type { AnalyticEventNameNew } from '@package/sdk/src/analytics';

const logger = useLogger('vk-sender');

declare global {
  interface Window {
    _tmr: VkEventData[];
  }
}

interface VkEventData {
  type: 'reachGoal';
  id: number;
  value?: any;
  goal: string;
  params?: { product_id: string };
}

export class VkEventSender {
  private vkId: number | undefined;
  private isDev: boolean = false;

  private executeSafe(callback: (id: number) => void) {
    if (!this.vkId || this.isDev) {
      return;
    }

    try {
      Reflect.apply(callback, undefined, [this.vkId]);
    } catch (error) {
      logger.error(error);
    }
  }

  public addToCart(contentId: string) {
    this.executeSafe((id: number) => {
      window._tmr.push({
        type: 'reachGoal',
        id,
        value: '0',
        goal: 'addToCart',
        params: { product_id: contentId },
      });
    });
  }

  public purchase(contentId: string, price: number) {
    this.executeSafe((id) => {
      window._tmr.push({
        type: 'reachGoal',
        id,
        value: price,
        goal: 'purchase',
        params: { product_id: contentId },
      });
    });
  }

  public addToWishlist(contentId: string) {
    this.executeSafe((id) => {
      window._tmr.push({
        type: 'reachGoal',
        id,
        value: '1р',
        goal: 'addToWishList',
        params: { product_id: contentId },
      });
    });
  }

  public sendViewProductEvent(contentId: string) {
    this.executeSafe((id) => {
      window._tmr.push({
        type: 'reachGoal',
        id,
        value: '1р',
        goal: 'viewProduct',
        params: { product_id: contentId },
      });
    });
  }

  public sendEvent(event: AnalyticEventNameNew) {
    this.executeSafe((id) => {
      window._tmr.push({ type: 'reachGoal', id, goal: event });
    });
  }

  public initialize(options: { id: number; isDev: boolean }) {
    this.vkId = options.id;
    this.isDev = options.isDev;

    window._tmr = window._tmr || [];
  }
}
