<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small'">
        <path
          d="M5.5257 1.92294C5.67765 1.46709 6.32243 1.46709 6.47438 1.92294L6.58447 2.25321C7.08214 3.74623 8.25372 4.91781 9.74675 5.41548L10.077 5.52557C10.5329 5.67752 10.5329 6.32231 10.077 6.47426L9.74675 6.58435C8.25372 7.08202 7.08214 8.2536 6.58447 9.74662L6.47438 10.0769C6.32243 10.5327 5.67765 10.5327 5.5257 10.0769L5.41561 9.74662C4.91793 8.2536 3.74635 7.08202 2.25333 6.58435L1.92306 6.47426C1.46722 6.32231 1.46721 5.67752 1.92306 5.52557L2.25333 5.41549C3.74635 4.91781 4.91793 3.74623 5.41561 2.25321L5.5257 1.92294Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          d="M7.28848 1.88441C7.51641 1.20064 8.48359 1.20064 8.71151 1.88441L8.87664 2.37981C9.62316 4.61935 11.3805 6.37672 13.6201 7.12323L14.1155 7.28836C14.7992 7.51629 14.7992 8.48346 14.1155 8.71139L13.6201 8.87652C11.3805 9.62303 9.62316 11.3804 8.87664 13.6199L8.71151 14.1153C8.48358 14.7991 7.51641 14.7991 7.28848 14.1153L7.12335 13.6199C6.37684 11.3804 4.61947 9.62303 2.37993 8.87652L1.88453 8.71139C1.20076 8.48346 1.20076 7.51629 1.88453 7.28836L2.37994 7.12323C4.61947 6.37672 6.37684 4.61935 7.12335 2.37981L7.28848 1.88441Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          d="M11.0513 3.84588C11.3552 2.93419 12.6447 2.93418 12.9486 3.84588L13.1688 4.50641C14.1642 7.49246 16.5073 9.83562 19.4934 10.831L20.1539 11.0511C21.0656 11.355 21.0656 12.6446 20.1539 12.9485L19.4934 13.1687C16.5073 14.164 14.1642 16.5072 13.1688 19.4932L12.9486 20.1538C12.6447 21.0655 11.3552 21.0655 11.0513 20.1538L10.8311 19.4932C9.83574 16.5072 7.49259 14.164 4.50654 13.1687L3.846 12.9485C2.93431 12.6446 2.93431 11.355 3.846 11.0511L4.50653 10.831C7.49258 9.83562 9.83574 7.49246 10.8311 4.50641L11.0513 3.84588Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          d="M14.656 4.44866C15.0865 3.15709 16.9134 3.15709 17.3439 4.44866L17.6559 5.38441C19.0659 9.61465 22.3854 12.9341 26.6156 14.3442L27.5514 14.6561C28.843 15.0866 28.843 16.9135 27.5514 17.3441L26.6156 17.656C22.3854 19.0661 19.0659 22.3855 17.6559 26.6158L17.3439 27.5515C16.9134 28.8431 15.0865 28.8431 14.656 27.5515L14.3441 26.6158C12.934 22.3855 9.61453 19.0661 5.38429 17.656L4.44854 17.3441C3.15697 16.9135 3.15696 15.0866 4.44853 14.6561L5.38429 14.3442C9.61453 12.9341 12.934 9.61465 14.3441 5.38441L14.656 4.44866Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import AppIcon from './AppIcon.vue';
import { type IconSize, getBoundsByIconSize, getSVGViewboxByIconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
