<template>
  <app-modal-wrapper
    :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.CancelSubscriptionModal, 'bonusModalWrapper')"
  >
    <modal-title :title="modalOptions.cancelTextTitle" />
    <modal-description :description="modalOptions.cancelTextSubtitle" />

    <div :class="$style.buttons">
      <app-button
        :text="modalOptions.cancelTextAcceptButton"
        :aria-label="$t('ariaLabel.disableAutoRenewal.confirmDisableSubscription')"
        :title="$t('ariaLabel.disableAutoRenewal.confirmDisableSubscription')"
        :data-tid="
          $getTestElementIdentifier($ElementTestIdentifierScope.CancelSubscriptionModal, 'watchWithBonusButton')
        "
        @click="currentHandler"
      />

      <app-button
        variation="link-alert"
        :aria-label="$t('ariaLabel.disableAutoRenewal.cancelDisableSubscription')"
        :title="$t('ariaLabel.disableAutoRenewal.cancelDisableSubscription')"
        :text="modalOptions.cancelTextRefuseButton"
        :data-tid="
          $getTestElementIdentifier($ElementTestIdentifierScope.CancelSubscriptionModal, 'unsubscribeAnywayButton')
        "
        @click="cancelSub({ userRejectObjection: true })"
      />
    </div>
  </app-modal-wrapper>
</template>

<script lang="ts" setup>
import { useSubscriptionAnalytics } from '@package/sdk/src/analytics';
import type { CancelReason } from '@package/sdk/src/api';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { useSubscriptionCancel } from '@/code/subscription/use-subscription-cancel';
import { useSubscriptionManagement } from '@/code/subscription/use-subscription-management';
import ModalDescription from '@/components/modals/ModalDescription.vue';
import ModalTitle from '@/components/modals/ModalTitle.vue';
import AppButton from '@/components/ui/AppButton.vue';
import { AppRoute } from '@/platform/router/routes';
import { useLayoutStore } from '@/stores/use-layout-store';

type CancelSubOptions = { userRejectObjection: boolean };

const { $analyticSender } = useNuxtApp();
const subscriptionAnalytics = useSubscriptionAnalytics($analyticSender);

const layoutStore = useLayoutStore();

const { userHasInteractWithModals, applyCancelSubscriptionObjection, setModalView, selectedReasons } =
  useSubscriptionCancel();

const { toggleAutoRenew } = useSubscriptionManagement();
const { modalOptions } = storeToRefs(layoutStore);

const router = useRouter();

const currentHandler = computed(() =>
  modalOptions.value.canUseCancelSubscriptionBonus ? applyCancelSubscriptionWithBonus : cancelBonusKeep,
);

const applyCancelSubscriptionWithBonus = () => {
  applyCancelSubscriptionObjection();

  layoutStore.setCurrentModalName(null);
};

const cancelSub = async (options?: CancelSubOptions) => {
  subscriptionAnalytics.onClickSubscribeCancelLastChance(true);

  // Пользователь отказался от продления
  setModalView(modalOptions.value.modalName, false);

  if (options?.userRejectObjection) {
    userHasInteractWithModals.value = true;
  }

  const reasonIds = selectedReasons.value.map((reason: CancelReason) => reason.id);

  try {
    await toggleAutoRenew(reasonIds, modalOptions.value.reasonText);
  } catch (error) {
    // do nothing
  }

  layoutStore.setCurrentModalName(null);
};

const cancelBonusKeep = () => {
  subscriptionAnalytics.onClickSubscribeCancelLastChance(false);

  userHasInteractWithModals.value = true;
  setModalView(modalOptions.value.modalName, false);

  layoutStore.setCurrentModalName(null);
  // Человек решил остаться, уводим его на главную
  router.push({ name: AppRoute.Index });
};

onMounted(() => {
  subscriptionAnalytics.onShowSubscribeCancelLastChance();

  selectedReasons.value = modalOptions.value.userSelectedReasons;
});
</script>

<style lang="scss" module>
.buttons {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  row-gap: var(--g-spacing-8);
  margin-top: var(--g-spacing-32);
}
</style>
