import { storeToRefs } from 'pinia';

import { AnalyticEventType, getAnalyticApplicationType } from '@/code/kmza/get-kmza-application-type-value';
import { RouteQuery } from '@/platform/router/query';
import { useAnalyticsStore } from '@/stores/use-analytics-store';

export enum CityAdsAnalyticEvent {
  Common = 'common',
}

interface InjectScriptPayload {
  orderId: string;
  orderTotal: number;
  currency: string;
}
const dict: Record<CityAdsAnalyticEvent, string> = {
  [CityAdsAnalyticEvent.Common]: '//p.cityadstrack.com/analytics.gif',
};

export function useKmzaCityAdsEvents() {
  const { cityAdsClickId } = storeToRefs(useAnalyticsStore());
  const route = useRoute();

  const injectCityAdsScript = (event: CityAdsAnalyticEvent, { orderId, orderTotal, currency }: InjectScriptPayload) => {
    const params = `${orderId}/ct/q1/c/38633?click_id=${cityAdsClickId.value}&order_total=${orderTotal}&currency=${currency}`;
    const scriptSrc = `https://cityadstrack.com/tr/js/${params}`;
    const frameSrc = `https://cityadstrack.com/tr/xframe/${params}`;

    const script = document.createElement('script');
    const frame = document.createElement('iframe');
    const noscript = document.createElement('noscript');
    const type = getAnalyticApplicationType(AnalyticEventType.CITY_ADS, event);

    script.src = scriptSrc;
    script.type = 'text/javascript';
    script.dataset.type = type;
    script.async = true;

    frame.src = frameSrc;
    frame.dataset.type = type;
    frame.width = '1';
    frame.height = '1';

    noscript.appendChild(frame);
    document.head.appendChild(script);
    document.head.appendChild(noscript);
  };

  const removeCityAdsScript = (event: CityAdsAnalyticEvent) => {
    const type = getAnalyticApplicationType(AnalyticEventType.CITY_ADS, event);
    const frame = document.querySelector(`iframe[data-type="${type}"]`);
    const script = document.querySelector(`script[data-type="${type}"]`);

    if (!frame || !script) {
      return;
    }

    frame.remove();
    script.remove();
  };

  const saveCityAdsClickId = () => {
    const clickId = route.query[RouteQuery.ClickId] as string;

    if (clickId) {
      cityAdsClickId.value = clickId;
    }
  };

  const injectCityAdsPixel = (event: CityAdsAnalyticEvent) => {
    const pixel = document.createElement('img');

    const pixelSrc = dict[event];

    if (!pixelSrc) {
      return;
    }

    pixel.alt = 'cityads pixel';
    pixel.dataset.type = getAnalyticApplicationType(AnalyticEventType.CITY_ADS, event);
    pixel.width = 0;
    pixel.height = 0;
    pixel.src = pixelSrc;
    // в доке city ads добавляется атрибут onerror, поэтому тоже добавляем
    pixel.onerror = function () {
      const s = document.createElement('script');
      s.src = this.src + '?iserror';
      s.async = !0;
      document.body.appendChild(s);
    };

    document.body.appendChild(pixel);
  };

  return {
    injectCityAdsPixel,
    injectCityAdsScript,
    removeCityAdsScript,
    saveCityAdsClickId,
  };
}
