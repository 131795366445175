<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11ZM7.94194 4.05806C8.18602 4.30214 8.18602 4.69786 7.94194 4.94194L6.88388 6L7.94194 7.05806C8.18602 7.30214 8.18602 7.69786 7.94194 7.94194C7.69786 8.18602 7.30214 8.18602 7.05806 7.94194L6 6.88388L4.94194 7.94194C4.69786 8.18602 4.30214 8.18602 4.05806 7.94194C3.81398 7.69786 3.81398 7.30214 4.05806 7.05806L5.11612 6L4.05806 4.94194C3.81398 4.69786 3.81398 4.30214 4.05806 4.05806C4.30214 3.81398 4.69786 3.81398 4.94194 4.05806L6 5.11612L7.05806 4.05806C7.30214 3.81398 7.69786 3.81398 7.94194 4.05806Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8 15.5C12.1421 15.5 15.5 12.1421 15.5 8C15.5 3.85786 12.1421 0.5 8 0.5C3.85786 0.5 0.5 3.85786 0.5 8C0.5 12.1421 3.85786 15.5 8 15.5ZM10.8687 5.13128C11.2104 5.47299 11.2104 6.02701 10.8687 6.36872L9.23744 8L10.8687 9.63128C11.2104 9.97299 11.2104 10.527 10.8687 10.8687C10.527 11.2104 9.97299 11.2104 9.63128 10.8687L8 9.23744L6.36872 10.8687C6.02701 11.2104 5.47299 11.2104 5.13128 10.8687C4.78957 10.527 4.78957 9.97299 5.13128 9.63128L6.76256 8L5.13128 6.36872C4.78957 6.02701 4.78957 5.47299 5.13128 5.13128C5.47299 4.78957 6.02701 4.78957 6.36872 5.13128L8 6.76256L9.63128 5.13128C9.97299 4.78957 10.527 4.78957 10.8687 5.13128Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.707 8.29282C16.0976 8.68335 16.0976 9.31651 15.707 9.70704L13.4142 11.9999L15.7071 14.2928C16.0976 14.6833 16.0976 15.3165 15.7071 15.707C15.3166 16.0976 14.6834 16.0976 14.2929 15.707L12 13.4141L9.70704 15.707C9.31651 16.0976 8.68335 16.0976 8.29282 15.707C7.9023 15.3165 7.9023 14.6833 8.29282 14.2928L10.5858 11.9999L8.29289 9.70704C7.90237 9.31651 7.90237 8.68335 8.29289 8.29282C8.68342 7.9023 9.31658 7.9023 9.70711 8.29282L12 10.5857L14.2928 8.29282C14.6833 7.9023 15.3165 7.9023 15.707 8.29282Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15.9999 30.1668C23.824 30.1668 30.1666 23.8242 30.1666 16.0002C30.1666 8.17613 23.824 1.8335 15.9999 1.8335C8.17588 1.8335 1.83325 8.17613 1.83325 16.0002C1.83325 23.8242 8.17588 30.1668 15.9999 30.1668ZM21.134 10.866C21.6221 11.3541 21.6221 12.1456 21.134 12.6337L17.7678 15.9998L21.134 19.366C21.6221 19.8541 21.6221 20.6456 21.134 21.1337C20.6458 21.6219 19.8544 21.6219 19.3662 21.1337L16.0001 17.7676L12.634 21.1337C12.1458 21.6219 11.3544 21.6219 10.8662 21.1337C10.378 20.6456 10.378 19.8541 10.8662 19.366L14.2323 15.9998L10.8662 12.6337C10.378 12.1456 10.378 11.3541 10.8662 10.866C11.3544 10.3778 12.1458 10.3778 12.634 10.866L16.0001 14.2321L19.3662 10.866C19.8544 10.3778 20.6458 10.3778 21.134 10.866Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import AppIcon from './AppIcon.vue';
import { type IconSize, getBoundsByIconSize, getSVGViewboxByIconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
