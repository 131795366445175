<template>
  <div :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.ParentalModal, 'childrenAccessModal')">
    <app-img :width="330" :image-class="$style.image" src="https://viasat-small.cdnvideo.ru/viasat/test/pics/1_m.jpg" />

    <div
      :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.ParentalModal, 'childrenAccessModal')"
      :class="$style.wrapper"
    >
      <modal-title :title="$t('parentalControl.modal.ageLimit')" />
      <modal-description :description="$t('parentalControl.modal.childAccessQuestion')" />

      <div :class="$style.buttons">
        <app-button
          :text="$t('common.yes')"
          variation="link-primary"
          :aria-label="$t('ariaLabel.parentalControl.yes')"
          :title="$t('ariaLabel.parentalControl.yes')"
          :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.ParentalModal, 'haveAccessButton')"
          @click="emit('confirm', true)"
        />
        <app-button
          :text="$t('common.no')"
          :aria-label="$t('ariaLabel.parentalControl.no')"
          :title="$t('ariaLabel.parentalControl.no')"
          :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.ParentalModal, 'noAccessButton')"
          @click="emit('confirm', false)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useParentalControlAnalytics } from '@package/sdk/src/analytics';

import ModalDescription from '../modals/ModalDescription.vue';
import ModalTitle from '../modals/ModalTitle.vue';
import AppButton from '../ui/AppButton.vue';
import AppImg from '../ui/AppImg.vue';

const emit = defineEmits<{
  (e: 'confirm', kidsAccess: boolean): void;
}>();

const { $analyticSender } = useNuxtApp();
const { onAutoParentalKidsAccessDisplayed } = useParentalControlAnalytics($analyticSender);

onMounted(() => {
  onAutoParentalKidsAccessDisplayed();
});
</script>

<style lang="scss" module>
@use '../../assets/breakpoints' as breakpoints;

.image {
  width: 100%;
  max-height: 200px;
  border-radius: 18px 18px 0 0;
  object-fit: cover;
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--g-spacing-8);
  margin-top: var(--g-spacing-32);
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: var(--g-spacing-32);
}

@include breakpoints.max-width-800 {
  .wrapper {
    padding: var(--g-spacing-20) 30px;
  }

  .image {
    max-height: 300px;
    border-radius: 0;
  }
}
</style>
