<template>
  <div
    :class="$style.label"
    :style="{
      position: position,
      top: $toPixel(top),
      left: handleLeftRight(left),
      right: handleLeftRight(right),
    }"
  >
    <div
      v-if="arrowPosition"
      :style="{
        left: handleLeftRight(arrowLeft),
        right: handleLeftRight(arrowRight),
      }"
      :class="{
        [$style.arrowUp]: arrowPosition === 'up',
        [$style.arrowDown]: arrowPosition === 'down',
        [$style.arrowLeft]: arrowPosition === 'left',
        [$style.arrowRight]: arrowPosition === 'right',
      }"
    />
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { toPixel } from '@package/sdk/src/core';

withDefaults(
  defineProps<{
    position?: 'absolute' | 'fixed';
    left?: number;
    right?: number;
    top?: number;
    arrowPosition?: 'up' | 'down' | 'left' | 'right';
    arrowLeft?: number;
    arrowRight?: number;
  }>(),
  {
    position: 'absolute',
    top: 0,
  },
);

const handleLeftRight = (position: number | undefined) => {
  if (!position) {
    return undefined;
  }

  return toPixel(position);
};
</script>

<style module lang="scss">
@use '@package/ui/src/styles/fonts.scss' as fonts;

.label {
  position: relative;
  z-index: var(--z-index-app-label);
  padding: var(--g-spacing-8) var(--g-spacing-16);
  border-radius: var(--g-border-round-10);
  background-color: var(--color-bg-secondary);
  transition: opacity 0.1s ease;

  @include fonts.WebBody-3;
}

.label a {
  cursor: pointer;
  text-decoration: underline;
  color: inherit;
}

.arrowUp {
  position: absolute;
  top: -6px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 6px solid var(--color-bg-secondary);
}

.arrowDown {
  position: absolute;
  bottom: -6px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 6px solid var(--color-bg-secondary);
}

.arrowRight {
  position: absolute;
  top: 50%;
  right: -6px;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 6px solid var(--color-bg-secondary);
}

.arrowLeft {
  position: absolute;
  top: 50%;
  left: -13px;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-right: 6px solid var(--color-bg-secondary);
}
</style>
