import { ParentalControlModalState, ParentalControlStatus, ParentalControlStep } from '@package/sdk/src/api';

export default function useParentalControl() {
  const resolveParentalControlStep = ({
    shouldPassParentalControlPrompt,
    currentDeviceParentalControlStatus,
    isAccountParentalControlSettled,
    modalState,
  }: {
    shouldPassParentalControlPrompt: boolean;
    currentDeviceParentalControlStatus: ParentalControlStatus;
    isAccountParentalControlSettled: boolean;
    modalState: ParentalControlModalState;
  }) => {
    // Если нет карт/подписок, то всю эту хрень не отображаем
    if (!shouldPassParentalControlPrompt) {
      return ParentalControlStep.Hidden;
    }

    // Если при ответе на вопрос о том, что есть ли дети - он ответил нет. Тогда не показываем модалку
    if (currentDeviceParentalControlStatus === ParentalControlStatus.NoChildrenAccess) {
      return ParentalControlStep.Hidden;
    }

    // Если был установлен родительский контроль, и был выбран профиль
    if (isAccountParentalControlSettled) {
      // Если на этом устройстве еще не был установлен код
      if (currentDeviceParentalControlStatus === ParentalControlStatus.NotSet) {
        return ParentalControlStep.ChildrenHaveAccessPrompt;
      }

      if (modalState === ParentalControlModalState.ChosenProfile) {
        return ParentalControlStep.Hidden;
      }

      return ParentalControlStep.ChooseProfile;
    }

    // Если нет родительского контроля
    if (!isAccountParentalControlSettled) {
      // Если нет родительского контроля, и вдруг юзер еще никак с модалкой не работал
      // показываем ему окно с вопросом о детях
      if (modalState === ParentalControlModalState.None) {
        return ParentalControlStep.ChildrenHaveAccessPrompt;
      }

      if (currentDeviceParentalControlStatus === ParentalControlStatus.ChildrenAccess) {
        if (modalState === ParentalControlModalState.HasChildren) {
          return ParentalControlStep.SetPinCode;
        }

        return ParentalControlStep.Hidden;
      }

      // Если нет род.контроля, и не был установлен пин код (то есть до этого он нажал "на детей")
      if (currentDeviceParentalControlStatus === ParentalControlStatus.NotSet) {
        return ParentalControlStep.SetPinCode;
      }

      return ParentalControlStep.ChildrenHaveAccessPrompt;
    }

    // Если есть дети, предлагем установить пин код
    if (
      currentDeviceParentalControlStatus === ParentalControlStatus.ChildrenAccess &&
      modalState !== ParentalControlModalState.None
    ) {
      return ParentalControlStep.SetPinCode;
    }

    return ParentalControlStep.Hidden;
  };

  return {
    resolveParentalControlStep,
  };
}
