import { isClient } from '@vueuse/core';
import { useRequestHeaders } from 'nuxt/app';

export function useHostname(): string {
  const headers = useRequestHeaders();

  const host = `${isClient ? window.location.origin : headers.host}`;

  return host;
}
