import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "80",
  height: "80",
  fill: "none",
  viewBox: "0 0 80 80"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"40\" cy=\"40\" r=\"40\" fill=\"url(#paint0_linear_38010_89683)\"></circle><path fill=\"#fff\" d=\"M20 32.366a4.366 4.366 0 1 0 8.732 0 4.366 4.366 0 0 0-8.732 0m32.018 0a4.366 4.366 0 1 0 8.732 0 4.366 4.366 0 0 0-8.732 0M54.2 42.554h-4.375a.734.734 0 0 0-.737.673c-.346 4.502-4.12 8.059-8.714 8.059-4.593 0-8.377-3.557-8.714-8.06a.734.734 0 0 0-.737-.672H26.55a.726.726 0 0 0-.728.764c.4 7.668 6.777 13.79 14.554 13.79s14.153-6.122 14.554-13.79a.73.73 0 0 0-.728-.764\"></path><defs><linearGradient id=\"paint0_linear_38010_89683\" x1=\"0\" x2=\"80\" y1=\"40\" y2=\"40\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#46B1AB\"></stop><stop offset=\"0\" stop-color=\"#467EB1\"></stop><stop offset=\"1\" stop-color=\"#9D62C1\"></stop></linearGradient></defs>", 3)
  ])))
}
export default { render: render }