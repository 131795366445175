export enum MyChannelKinomCardDisplayedTitle {
  Title = 'title',
  ContentTitle = 'content-title',
}

export interface MyChannelKinomCardInfo {
  title: string;
  preview: string;
  genre?: string;
  year?: number;
}
