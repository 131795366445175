import type { ApiBonus, Bonus } from '@package/sdk/src/api';
import { BonusMapper } from '@package/sdk/src/api';
import type { ApiPromoOffersV2 } from '@package/sdk/src/api/offers/api-offers-v2-type';
import type { PromoOfferV2 } from '@package/sdk/src/api/offers/offer-v2';
import { PromoOfferMapperV2 } from '@package/sdk/src/api/offers/offer-v2-mapper';

import { ENDPOINTS } from '@/platform/http';
import useHostVariation from '@/platform/variation/use-host-variation';

export function usePromocodeApi() {
  const { $http } = useNuxtApp();

  const { appLanguage } = useHostVariation();

  const applyPromocode = (promocode: string): Promise<Bonus> => {
    return $http.post<ApiBonus>(ENDPOINTS.BONUS_PROMO_CODES_APPLY, { code: promocode }).then(BonusMapper.map);
  };

  const getPromocodeData = (code: string): Promise<PromoOfferV2> => {
    return $http
      .get<ApiPromoOffersV2>(ENDPOINTS.OFFERS, {
        query: {
          platform: 'web',
          lang: appLanguage.value,
          code,
        },
        signRequest: true,
      })
      .then(PromoOfferMapperV2.map);
  };

  return { applyPromocode, getPromocodeData };
}
