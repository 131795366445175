import useLogger from '@package/logger/src/use-logger';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { extraErrorDataIntegration } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';

import { ApiError } from '@/platform/http/errors';

import useEnvironment from '../platform/environment/use-environment';
import useHostVariation from '../platform/variation/use-host-variation';

enum SentryEnvironment {
  Development = 'development',
  Production = 'production',
  Stage = 'stage',
  Unknown = 'unknown',
}

function getEnvironmentName(isRelease: boolean, isDev: boolean): SentryEnvironment {
  switch (true) {
    // Мы на production.
    case isRelease:
      return SentryEnvironment.Production;
    // мы в режиме разработки
    case isDev:
      return SentryEnvironment.Development;
    // мы на тестовом стенде (testvip)
    case !isDev:
      return SentryEnvironment.Stage;
  }

  throw new UnexpectedComponentStateError('sentryEnvironment');
}

export default defineNuxtPlugin((nuxtApp) => {
  const { isDev, isRelease } = useEnvironment();
  const router = useRouter();
  const { appVariation } = useHostVariation();
  const logger = useLogger('sentry');

  const { vueApp } = nuxtApp;

  const environment = getEnvironmentName(isRelease, isDev);

  // Если мы запустили через npm run dev - Sentry НЕ запускаем.
  if (environment === SentryEnvironment.Unknown || environment === SentryEnvironment.Development) {
    return;
  }

  Sentry.init({
    app: vueApp,
    dsn: 'https://2b313105483f48938ba66c6b3f09c542@sentry.viju.ru/3',
    integrations: [
      extraErrorDataIntegration(),
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: [],
      }),
    ],
    tracesSampleRate: 1.0, // Sentry recommends adjusting this value in production
    environment,
    beforeSend(event: Sentry.Event, hint?: Sentry.EventHint) {
      const originalException = hint?.originalException;

      if (!originalException) {
        return event;
      }

      if (!event.request) {
        return event;
      }

      const { message } = originalException as Error;

      if (originalException instanceof ApiError) {
        return null;
      }

      // DSML ошибки не отправляем в проде, чтобы не спамить фигней всякой
      if (originalException instanceof Error && originalException.name === 'DsmlEventError') {
        return null;
      }

      // Такое иногда вылетает у плеера, не отправляем в Sentry
      if (message?.includes('webkitExitFullScreen')) {
        return null;
      }

      if (message?.includes('function Error() { [native code] }')) {
        return null;
      }

      const { headers = {} } = event.request;
      const userAgent = (headers['User-Agent'] || '').toLowerCase();

      // Если это автотесты, не отправляем в Sentry
      if (userAgent.includes('headless')) {
        return null;
      }

      return event;
    },
  });

  Sentry.configureScope((scope) => {
    scope.setTag('variation', appVariation);
  });

  logger.info('Sentry installed');

  return {
    provide: {},
  };
});
