<template>
  <div ref="wrapperEl" :class="$style.codeInput">
    <input
      v-for="(_, i) in codeLength"
      :key="i"
      ref="input"
      :class="{
        [$style.inputTile]: true,
        [$style.inputTileError]: isError,
        [$style.inputTileSuccess]: isSuccess,
      }"
      type="number"
      inputmode="numeric"
      min="0"
      max="9"
      autocomplete="one-time-code"
      :value="innerModel[i]"
      :disabled="!!i && !input[i - 1]"
      @focus="updateFocusedTile(i)"
    />
  </div>
</template>

<script lang="ts" setup>
import { setCSSVariable, UnexpectedComponentStateError, vibrate } from '@package/sdk/src/core';
import { watchOnce } from '@vueuse/core';
import { nextTick, onBeforeUnmount, onMounted, ref, useCssModule } from 'vue';

const props = withDefaults(
  defineProps<{
    isSuccess?: boolean;
    isError?: boolean;
    autofocus?: boolean;
    modelValue?: string;
    codeLength?: number;
    isSetImmediate?: boolean;
  }>(),
  {
    isSuccess: false,
    isError: false,
    autofocus: false,
    modelValue: '',
    codeLength: 4,
    isSetImmediate: false,
  },
);

const input = ref<HTMLElement[]>([]);
const focusedTileIndex = ref<number>(0);
const innerModel = ref<number[] | string[]>([]);

const emit = defineEmits<{
  (e: 'update:modelValue', val: string): void;
  (e: 'submit'): void;
}>();

const isEmpty = (item: string | number) => item === '';

const updateFocusedTile = (index: number) => {
  focusedTileIndex.value = index;
};

const $style = useCssModule();

watch(
  () => props.isError,
  (val) => {
    if (val) {
      vibrate([200, 30, 200]);
    }
  },
);

const focusOnPrev = () => {
  if (focusedTileIndex.value) {
    return input.value[focusedTileIndex.value - 1].focus();
  }
};

const focusOnNext = () => {
  if (focusedTileIndex.value < props.codeLength - 1) {
    return input.value[focusedTileIndex.value + 1].focus();
  }
};

const changeValueAt = (char: string, index: number) => {
  innerModel.value[index] = char;

  const value = innerModel.value.join('');

  emit('update:modelValue', value);

  nextTick().then(() => {
    if (!isEmpty(char)) {
      focusOnNext();
    }
  });

  if (value.length >= props.codeLength) {
    emit('submit');
  }
};

const onBackspace = () => {
  if (document.activeElement?.classList.contains($style.inputTile)) {
    if (isEmpty(innerModel.value[focusedTileIndex.value])) {
      focusOnPrev();
    }

    changeValueAt('', focusedTileIndex.value);
  }
};

const numbers = /[0-9]/g;

const onInput = (event: Event) => {
  const currentTarget = document.activeElement;

  const e = event as InputEvent;
  const target = e.target as HTMLInputElement;

  if (!isEmpty(innerModel.value[input.value.indexOf(currentTarget as HTMLElement)])) {
    // @ts-ignore
    target.value = target._value;
    return;
  }

  if (!e.data?.match(numbers)) {
    target.value = '';
    return;
  }

  if (target.value.length) {
    target.value = e.data;
  }

  changeValueAt(e.data, input.value.indexOf(currentTarget as HTMLElement));
};

const onKeyDown = (e: KeyboardEvent) => {
  if (e.key === 'Backspace') {
    onBackspace();
  }
};

const resetInnerModel = () => {
  innerModel.value = Array(props.codeLength).fill('');
};

const wrapperEl = ref<HTMLElement>();

const setInnerModel = () => {
  innerModel.value = props.modelValue ? props.modelValue.split('') : Array(props.codeLength).fill('');
};

onMounted(async () => {
  if (!wrapperEl.value) {
    throw new UnexpectedComponentStateError('wrapperEl');
  }

  setCSSVariable('code-cells', String(props.codeLength), wrapperEl.value);

  window.addEventListener('input', onInput);
  window.addEventListener('keydown', onKeyDown);

  setInnerModel();
  await nextTick();

  if (props.autofocus) {
    input.value[0]?.focus();
  }
});

// в момент маунта props.modelValue еще равен '', поэтому смотрим один раз на его изменение в watchOnce
watchOnce(
  () => props.modelValue,
  () => {
    if (props.isSetImmediate) {
      setInnerModel();
    }
  },
);

onBeforeUnmount(() => {
  window.removeEventListener('input', onInput);
  window.removeEventListener('keydown', onKeyDown);
});

defineExpose({
  resetInnerModel,
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;

.codeInput {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: repeat(var(--code-cells), 50px);

  gap: 16px;
  justify-content: center;
}

.inputTile {
  height: 50px;
  border-radius: var(--g-border-round-12);
  border: 0;
  background-color: var(--color-bg-field);
  color: var(--color-text-primary);
  text-align: center;

  @include fonts.WebHeadline-1;

  &:focus {
    border: 2px solid var(--color-stroke-border-accent);
  }
}

.inputTileSuccess {
  border: 2px solid var(--color-stroke-border-accent);

  &:focus {
    border: 2px solid var(--color-stroke-border-accent);
  }
}

.inputTileError {
  border: 1px solid var(--color-stroke-border-negative);

  &:focus {
    border: 1px solid var(--color-stroke-border-negative);
  }
}
</style>
