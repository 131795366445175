import { Disposable } from '@package/sdk/src/core';
import type { IndexedDBItem } from '@package/sdk/src/core/database/indexed-db';
import { IndexedDBStorage } from '@package/sdk/src/core/database/indexed-db';
import { isClient } from '@vueuse/core';

export class HttpClientCacheController extends Disposable {
  private readonly indexedDB = new IndexedDBStorage({
    timeoutForReading: 2000,
    databaseName: 'web-cache',
    tableName: 'http-resources',
  });

  constructor() {
    super();
  }

  public addEntry(key: string, value: unknown, options?: { expires: number }) {
    if (!isClient) {
      return;
    }

    return this.indexedDB.write(key, value, options);
  }

  public removeEntry(key: string) {
    if (!isClient) {
      return undefined;
    }

    return this.indexedDB.delete(key);
  }

  public readEntry<T>(key: string): Promise<IndexedDBItem<T> | undefined> {
    if (!isClient) {
      return Promise.resolve(undefined);
    }

    return this.indexedDB.read<T>(key);
  }

  public clear() {
    this.indexedDB.clear();
  }
}
