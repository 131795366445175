import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "80",
  height: "80",
  fill: "none",
  viewBox: "0 0 80 80"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("circle", {
      cx: "40",
      cy: "40",
      r: "40",
      fill: "#34474A"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#fff",
      d: "M31.628 33.382q3.332-3.4 8.364-3.4t8.33 3.4q3.332 3.4 3.332 8.33t-3.332 8.33q-3.298 3.4-8.33 3.4-5.031 0-8.364-3.4-3.297-3.4-3.298-8.33t3.298-8.33m2.686 14.246q2.278 2.38 5.678 2.38t5.644-2.38q2.278-2.38 2.278-5.916t-2.278-5.916q-2.244-2.38-5.644-2.38t-5.678 2.38q-2.244 2.38-2.244 5.916t2.244 5.916"
    }, null, -1)
  ])))
}
export default { render: render }