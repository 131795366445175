import { ProcessingMapper } from '../base/processing-mapper';
import { ApiCloudPaymentsInvoiceResponse, CloudPaymentsInvoice } from './payments-types/cloudpayments';

export class CloudpaymentsInvoiceMapper {
  static map({ invoice_id, payment_widget }: ApiCloudPaymentsInvoiceResponse): CloudPaymentsInvoice {
    return ProcessingMapper.process({
      invoiceId: invoice_id,
      amount: payment_widget.amount,
      description: payment_widget.title,
      currency: payment_widget.currency,
      transactionType: payment_widget.transaction_type,
    });
  }
}
