import type { ApiOfferV2 } from '@PACKAGE/sdk/src/api/offers/api-offer-v2-type';
import type { ApiOffersV2, ApiPromoOffersV2 } from '@PACKAGE/sdk/src/api/offers/api-offers-v2-type';
import type { OfferV2, PromoOfferV2 } from '@PACKAGE/sdk/src/api/offers/offer-v2';
import type { OffersV2 } from '@PACKAGE/sdk/src/api/offers/offers-v2';

import { ProcessingMapper } from '../base/processing-mapper';

export class OfferMapperV2 {
  static mapMany(offers: ApiOfferV2[]): OfferV2[] {
    return ProcessingMapper.process(offers.map(OfferMapperV2.map));
  }

  static map(api: ApiOfferV2): OfferV2 {
    return ProcessingMapper.process({
      badge: api.keys.badge,
      benefitsText: api.keys.benefits_text || [],
      duration: api.duration,
      id: api.id,
      markedBest: api.marked_best,
      nextPeriodPriceText: api.keys.next_price_text,
      offerDurationText: api.keys.duration_text,
      offerSubtitle: api.keys.subtitle,
      offerTitle: api.keys.offer_name,
      oldPriceText: api.keys.price_strikethrough_text,
      price: api.price,
      priceCurrency: api.price_currency,
      priceText: api.keys.price_text,
      promoImage:
        api.keys.promo_image_url ||
        'https://viasat-small.cdnvideo.ru/viasat/store/marketing/offers/offer_bg_1.jpg' ||
        '',
      subscriptionImage:
        api.keys.subscription_image_url ||
        'https://viasat-small.cdnvideo.ru/viasat/store/marketing/offers/offer_bg_1.jpg' ||
        '',
      specialOfferText: api.keys.special_offer_text,
      titleText: api.title_text,
      trialAvailable: api.trial_available,
      trialDuration: api.trial_duration,
      trialPrice: api.trial_price,
      watchingButtonTitle: api.keys.watching_title?.replace('&nbsp;', ' '),
    });
  }
}

export class OffersMapperV2 {
  static map(api: ApiOffersV2): OffersV2 {
    const { general, offers } = api;

    return ProcessingMapper.process({
      general,
      offers: offers ? OfferMapperV2.mapMany(offers) : [],
    });
  }
}

export class PromoOfferMapperV2 {
  static map(api: ApiPromoOffersV2): PromoOfferV2 {
    const { offers } = api;

    return ProcessingMapper.process({
      ...OfferMapperV2.map(offers[0]),
      bonusType: api.offers[0].bonus_type,
      cardRequired: api.offers[0].is_card_required,
      watchingButtonTitlePromo: api.offers[0].keys.promo_watching_title?.replace('&nbsp;', ' '),
      watchingButtonSubtitlePromo: api.offers[0].keys.promo_watching_subtitle,
    });
  }
}
