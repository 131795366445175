import { ProcessingMapper } from '../base/processing-mapper';
import { ReferralProgram } from './promocode/referral-program';
import { ApiReferralProgram } from './referral-program-types';

export class ReferralProgramMapper {
  static mapMany(programs: ApiReferralProgram[]): ReferralProgram[] {
    return ProcessingMapper.process(programs.map(ReferralProgramMapper.map));
  }

  static map({ duration_unit, started_at, finished_at, referral_url, ...rest }: ApiReferralProgram): ReferralProgram {
    return ProcessingMapper.process({
      durationUnit: duration_unit,
      startedAt: started_at,
      finishedAt: finished_at,
      referralUrl: referral_url,
      ...rest,
    });
  }
}
