import { useAccountPageAnalytics } from '@package/sdk/src/analytics';

import getAnalyticPageValue from '../../code/kmza/get-kmza-page-value';
import { CookieName } from '../cookies/cookies';
import useAppCookie from '../cookies/use-app-cookie';
import { AppRoute } from '../router/routes';
import { AppLanguage } from './language';

export default function useAppLangSwitcher() {
  const langCookie = useAppCookie(CookieName.AppLanguage);
  const route = useRoute();
  const { $http, $analyticSender } = useNuxtApp();
  const accountPageAnalytics = useAccountPageAnalytics($analyticSender);

  const setLanguage = (language: AppLanguage) => {
    langCookie.value = language;

    $http.cache.clear();

    accountPageAnalytics.onClickLanguageSelect({
      selectedLang: language,
      page: getAnalyticPageValue(route.name as AppRoute),
    });

    window.location.reload();
  };

  return {
    setLanguage,
  };
}
