<template>
  <section ref="wrapperEl">
    <header :class="$style.header">
      <p>
        {{ title }}
      </p>
    </header>
    <ul
      :key="currentContentRoute"
      role="list"
      :class="{
        [$style.columnGrid]: gridLayout,
        [$style.columnBlock]: !gridLayout,
      }"
    >
      <slot>
        <li v-for="item in items" :key="item.slug">
          <app-link
            :class="$style.link"
            :to="{
              name: currentContentRoute === $AppRoute.Movies ? $AppRoute.MovieFilter : $AppRoute.SerialFilter,
              params: { f: item.slug },
            }"
            @click.prevent="
              onHeaderRouteClick(
                item,
                currentContentRoute === $AppRoute.Movies ? $AppRoute.MovieFilter : $AppRoute.SerialFilter,
              )
            "
          >
            {{ item.title }}
          </app-link>
        </li>
      </slot>
    </ul>
  </section>
</template>

<script lang="ts" setup>
import type { Country, FilterItem, Genre, Year } from '@package/sdk/src/api';
import { setCSSVariable, UnexpectedComponentStateError } from '@package/sdk/src/core';
import { storeToRefs } from 'pinia';
import { onMounted, ref } from 'vue';

import type { AppRoute } from '@/platform/router/routes';
import { useCatalogStore } from '@/stores/use-catalog-store';

import AppLink from '../ui/AppLink.vue';
import type { ContentRouteName } from './use-app-header-variables';

const catalogStore = useCatalogStore();
const router = useRouter();

const { selectedCountrySlugs, selectedGenreSlugs, selectedYearSlugs } = storeToRefs(catalogStore);

type CatalogFilter = Country | Genre | Year;

const props = withDefaults(
  defineProps<{
    title: string;
    items: CatalogFilter[];
    columns: number;
    currentContentRoute: ContentRouteName;
    gridLayout?: boolean;
  }>(),
  {
    columns: 3,
    gridLayout: true,
  },
);

const wrapperEl = ref<HTMLElement>();

const onHeaderRouteClick = async (item: FilterItem, route: AppRoute) => {
  catalogStore.selectedSlugsWatcher.pause();
  catalogStore.resetSlugs();

  if (item.filterType === 'country') {
    selectedCountrySlugs.value.push(item as Country);
  }

  if (item.filterType === 'genre') {
    selectedGenreSlugs.value.push(item as Genre);
  }

  if (item.filterType === 'year') {
    selectedYearSlugs.value.push(item as Year);
  }

  await router.push({ name: route, params: { f: item.slug } });

  catalogStore.selectedSlugsWatcher.resume();
};

onMounted(() => {
  if (!wrapperEl.value) {
    throw new UnexpectedComponentStateError('wrapperEl');
  }

  setCSSVariable('columns-count', String(props.columns), wrapperEl.value);
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;

.columnGrid {
  display: grid;
  grid-template-columns: repeat(var(--columns-count), 1fr);
  grid-column-gap: var(--g-spacing-10);
}

.columnBlock {
  column-count: var(--columns-count);
}

.header {
  margin-bottom: var(--g-spacing-16);
  color: var(--color-text-tertiary);
  text-transform: uppercase;

  @include fonts.WebHeadline-3;
}

.link {
  display: block;
  padding: var(--g-spacing-6) 0;
  color: var(--color-text-additional);
  transition: color 0.3s ease-in-out;

  @include fonts.WebBody-2;

  &:hover {
    color: var(--color-text-primary);
  }
}
</style>
