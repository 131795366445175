import type { TPluralArgs, TranslateArgs } from '@package/content-utils/src/code/content-media-button-texts';

import type ru from '../../../../../translations/web/ru.json';

export type TranslationKey = keyof typeof ru;

export declare function translate(key: TranslationKey | string, params?: Record<string, string | number>): string;
export declare function tPlural(
  key: TranslationKey | string,
  count: number,
  params?: Record<string, string | number>,
): string;
export declare function translateContentButtonText(args: string | undefined | TranslateArgs | TPluralArgs): string;

export declare function formatDate(date: Date | number): string;

export declare function formatDateWithCustomFormat(date: Date | number, format: string): string;

export declare function formatDuration(seconds: number): string;

interface UseLocaleOptions {
  translate: typeof translate;
  tPlural: typeof tPlural;
  translateContentButtonText: typeof translateContentButtonText;
  formatDate: typeof formatDate;
  formatDateWithCustomFormat: typeof formatDateWithCustomFormat;
  formatDuration: typeof formatDuration;
}

export default function useLocale(): UseLocaleOptions {
  const { $t, $tPlural, $translateContentButtonText, $formatDate, $formatDateWithCustomFormat, $formatDuration } =
    useNuxtApp();

  return {
    translate: $t,
    tPlural: $tPlural,
    translateContentButtonText: $translateContentButtonText,
    formatDate: $formatDate,
    formatDateWithCustomFormat: $formatDateWithCustomFormat,
    formatDuration: $formatDuration,
  };
}
