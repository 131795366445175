import { ProcessingMapper } from '../base/processing-mapper';
import { ApiCancelReason } from './cancel-reason-types';
import { CancelReason } from './types/cancel-reason';

export class CancelReasonMapper {
  static mapMany(items: ApiCancelReason[]): CancelReason[] {
    return ProcessingMapper.process(items.map(CancelReasonMapper.map));
  }

  static map({
    id,
    title,
    description,
    position,
    frontend_text_title,
    frontend_text_subtitle,
    frontend_text_accept_button,
    frontend_text_refuse_button,
  }: ApiCancelReason): CancelReason {
    return ProcessingMapper.process({
      id,
      title,
      description,
      position,
      texts: {
        title: frontend_text_title || '',
        subtitle: frontend_text_subtitle || '',
        acceptButton: frontend_text_accept_button || '',
        refuseButton: frontend_text_refuse_button || '',
      },
    });
  }
}
