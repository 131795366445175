import useLogger from '@package/logger/src/use-logger';
import { isString } from '@package/sdk/src/core';

export class FrameController {
  private _frame: Window | null = null;
  private readonly width: number;
  private readonly height: number;

  get frame() {
    return this._frame;
  }

  get frameConfigString() {
    if (process.server) return '';
    const top = window.screen.height / 2 - this.height / 2 || 0;
    const left = window.screen.width / 2 - this.width / 2 || 0;
    return `scrollbars=no,width=${this.width},height=${this.height},left=${left},top=${top}`;
  }

  constructor(width = 450, height = 700) {
    this.width = width;
    this.height = height;
  }

  public show(uri: string, name = 'popup'): FrameController {
    this._frame = window.open(uri, name, this.frameConfigString);
    this._frame?.focus();
    return this;
  }

  public close(): FrameController {
    this._frame?.close();
    return this;
  }

  public subscribeToReceivedMessage(callback = (_: MessageEvent) => {}) {
    const logger = useLogger('frame-controller');

    window.onmessage = (e: MessageEvent) => {
      // Какая-то еруенда с ym_promise лезет
      if (isString(e.data) && e.data.includes('__ym__promise_')) {
        return;
      }

      logger.error('on-message', e);

      if (e.origin !== window.location.origin) {
        return;
      }

      try {
        callback(e);
      } catch (error) {
        logger.error(error);
      }
    };
  }
}
