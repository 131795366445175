<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.21967 2.21967C2.51256 1.92678 2.98744 1.92678 3.28033 2.21967L6 4.93934L8.71967 2.21967C9.01256 1.92678 9.48744 1.92678 9.78033 2.21967C10.0732 2.51256 10.0732 2.98744 9.78033 3.28033L7.06066 6L9.78033 8.71967C10.0732 9.01256 10.0732 9.48744 9.78033 9.78033C9.48744 10.0732 9.01256 10.0732 8.71967 9.78033L6 7.06066L3.28033 9.78033C2.98744 10.0732 2.51256 10.0732 2.21967 9.78033C1.92678 9.48744 1.92678 9.01256 2.21967 8.71967L4.93934 6L2.21967 3.28033C1.92678 2.98744 1.92678 2.51256 2.21967 2.21967Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.41789 2.41789C2.80842 2.02737 3.44158 2.02737 3.83211 2.41789L8 6.58579L12.1679 2.41789C12.5584 2.02737 13.1916 2.02737 13.5821 2.41789C13.9726 2.80842 13.9726 3.44158 13.5821 3.83211L9.41421 8L13.5821 12.1679C13.9726 12.5584 13.9726 13.1916 13.5821 13.5821C13.1916 13.9726 12.5584 13.9726 12.1679 13.5821L8 9.41421L3.83211 13.5821C3.44158 13.9726 2.80842 13.9726 2.41789 13.5821C2.02737 13.1916 2.02737 12.5584 2.41789 12.1679L6.58579 8L2.41789 3.83211C2.02737 3.44158 2.02737 2.80842 2.41789 2.41789Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.7045 4.7045C5.14384 4.26517 5.85616 4.26517 6.2955 4.7045L12 10.409L17.7045 4.7045C18.1438 4.26517 18.8562 4.26517 19.2955 4.7045C19.7348 5.14384 19.7348 5.85616 19.2955 6.2955L13.591 12L19.2955 17.7045C19.7348 18.1438 19.7348 18.8562 19.2955 19.2955C18.8562 19.7348 18.1438 19.7348 17.7045 19.2955L12 13.591L6.2955 19.2955C5.85616 19.7348 5.14384 19.7348 4.7045 19.2955C4.26517 18.8562 4.26517 18.1438 4.7045 17.7045L10.409 12L4.7045 6.2955C4.26517 5.85616 4.26517 5.14384 4.7045 4.7045Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.81935 5.81923C6.35633 5.28226 7.22693 5.28226 7.7639 5.81923L16 14.0553L24.236 5.81923C24.773 5.28226 25.6436 5.28226 26.1806 5.81923C26.7175 6.3562 26.7175 7.2268 26.1806 7.76378L17.9445 15.9998L26.1806 24.2359C26.7175 24.7729 26.7175 25.6435 26.1806 26.1804C25.6436 26.7174 24.773 26.7174 24.236 26.1804L16 17.9444L7.7639 26.1804C7.22693 26.7174 6.35633 26.7174 5.81935 26.1804C5.28238 25.6435 5.28238 24.7729 5.81935 24.2359L14.0554 15.9998L5.81935 7.76378C5.28238 7.2268 5.28238 6.3562 5.81935 5.81923Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import AppIcon from './AppIcon.vue';
import { type IconSize, getBoundsByIconSize, getSVGViewboxByIconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
