import useLogger from '@package/logger/src/use-logger';
import { AnalyticEventNameNew, useAccountPageAnalytics } from '@package/sdk/src/analytics';
import type { PaymentOptions } from '@package/sdk/src/api';
import { SubscriptionPlatform } from '@package/sdk/src/api';
import { timeout, UnexpectedComponentStateError } from '@package/sdk/src/core';
import type { AnyFn } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { CityAdsAnalyticEvent, useKmzaCityAdsEvents } from '@/code/kmza/use-citya-kmza';
import { useKmzaWeboramaEvents, WeboramaAnalyticEvent } from '@/code/kmza/use-webama-kmza';
import { CookieName } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';
import { RouteQuery } from '@/platform/router/query';
import { AppRoute } from '@/platform/router/routes';
import useAppSessionStorage, { SessionStorageKey } from '@/platform/session-storage/useAppSessionStorage';
import { useAuthRegistrationStore } from '@/stores/use-auth-registration-store';
import { useOffersStore } from '@/stores/use-offers-store';
import { usePromocodeStore } from '@/stores/use-promocode-store';
import { useSessionStore } from '@/stores/use-session-store';

import { useCloudPayments } from './use-cloudpayments';
import { usePaymentsApi } from './use-payments-api';

export interface IRawApplyingOfferOptions {
  blockOptions: PaymentOptions;
  onSuccessCallback: AnyFn;
}

interface SuccessPaymentCallbackPayload {
  orderPrice: number;
  currency: string;
  orderId: string;
}

let sessionRedirectTimeoutMilliseconds = 1000;

export function usePaymentsActions() {
  const route = useRoute();
  const { $yandexMetricaEventSender, $vkEventSender } = useNuxtApp();
  const logger = useLogger('use-payments-actions');

  const offersStore = useOffersStore();
  const sessionStore = useSessionStore();
  const authRegistrationStore = useAuthRegistrationStore();
  const { injectWeboramaPixel } = useKmzaWeboramaEvents();
  const { injectCityAdsScript } = useKmzaCityAdsEvents();

  const cloudPayments = useCloudPayments();
  const paymentsApi = usePaymentsApi();

  const { isAuth } = storeToRefs(sessionStore);
  const { currentSystemPlatform } = storeToRefs(offersStore);
  const currentSessionContentId = useAppSessionStorage<string>(SessionStorageKey.LastOpenContentId, '');

  const isCloudpaymentsEnabled = computed(() => currentSystemPlatform.value === SubscriptionPlatform.Cloudpayments);

  const { $analyticSender } = useNuxtApp();
  const { onSuccessfulPayment } = useAccountPageAnalytics($analyticSender);
  const navigateAfterPayment = () => {
    const backUrl = (route.query[RouteQuery.BackRoute] as string) || '/';

    navigateTo({
      name: AppRoute.ThankYou,
      query: { [RouteQuery.BackRoute]: backUrl },
    });
  };

  const extractPaymentQRSignature = (payload: string) => paymentsApi.extractPaymentQRSignature(payload);

  const promocodeCookie = useAppCookie(CookieName.Promocode);
  const promocodeStore = usePromocodeStore();

  const onSuccessPayment = async ({ orderPrice, currency, orderId }: SuccessPaymentCallbackPayload) => {
    await timeout(sessionRedirectTimeoutMilliseconds);
    await sessionStore.updateCurrentUser();
    navigateAfterPayment();

    if (currentSessionContentId.value) {
      $vkEventSender.purchase(currentSessionContentId.value, orderPrice);
      currentSessionContentId.value = '';
    }

    $yandexMetricaEventSender.sendYmEventWithParams(AnalyticEventNameNew.ClickSubscribePay, {
      order_price: orderPrice,
      currency,
    });

    onSuccessfulPayment();

    injectCityAdsScript(CityAdsAnalyticEvent.Common, { orderId, orderTotal: orderPrice, currency });
    injectWeboramaPixel(WeboramaAnalyticEvent.HasLinkedCard);

    promocodeCookie.value = '';
    promocodeStore.setSavedPromocode('');
  };

  const createAddCardUrlPaymentSystem = () => {
    if (isCloudpaymentsEnabled.value) {
      sessionRedirectTimeoutMilliseconds = 2500;

      return cloudPayments.loadCloudpaymentsScriptWithCallback(() => cloudPayments.openCPWidget(onSuccessPayment));
    }

    throw new UnexpectedComponentStateError('currentSystemPlatform');
  };

  const applyOfferWithPaymentSystemExternal = (options: IRawApplyingOfferOptions) =>
    cloudPayments.loadCloudpaymentsScriptWithCallback(() => cloudPayments.openExternalPaymentCPWidget(options));

  const applyOfferWithPaymentSystem = (offerId: string) => {
    if (!isAuth.value) {
      return logger.warn('Calling applyOfferWithPayment system, is not allowed when isAuth=false');
    }

    authRegistrationStore.setIsImmediatePaymentRedirect(false);

    if (isCloudpaymentsEnabled.value) {
      sessionRedirectTimeoutMilliseconds = 1000;

      return cloudPayments.loadCloudpaymentsScriptWithCallback(() =>
        cloudPayments.openCPWidget(onSuccessPayment, offerId),
      );
    }

    throw new UnexpectedComponentStateError('currentSystemPlatform');
  };

  return {
    extractPaymentQRSignature,
    applyOfferWithPaymentSystemExternal,
    createAddCardUrlPaymentSystem,
    applyOfferWithPaymentSystem,
    navigateAfterPayment,
  };
}
