export type PaymentOptions = {
  publicId: string;
  accountId: string;
  invoiceId: string;
  description: string;
  amount: number;
  currency: string;
  email?: string;
  requireEmail?: boolean;
  autoClose?: number;
  language?: string;
  auth: boolean;
  data?: {
    CloudPayments: {
      UserPhone?: string;
      customerReceipt?: CustomerReceipt;
    };
  };
};

export interface CustomerReceipt {
  // товарные позиции
  Items: CustomerReceiptItem[];
  calculationPlace: string; // место осуществления расчёта, по умолчанию берется значение из кассы
  email?: string; // e-mail покупателя, если нужно отправить письмо с чеком
  phone?: string; // телефон покупателя в любом формате, если нужно отправить сообщение со ссылкой на чек
  amounts: {
    electronic: number; // Сумма оплаты электронными деньгами
    advancePayment: number; // Сумма из предоплаты (зачетом аванса) (2 знака после запятой)
    credit: number; // Сумма постоплатой(в кредит) (2 знака после запятой)
    provision: number; // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после запятой)
  };
}

export interface CustomerReceiptItem {
  label: string; // наименование товара
  price: number; // цена
  quantity: number; // количество
  amount: number; // сумма
  vat?: number; // ставка НДС
  method?: number; // тег-1214 признак способа расчета - признак способа расчета
  object?: number; // тег-1212 признак предмета расчета - признак предмета товара, работы, услуги, платежа, выплаты, иного предмета расчета
  measurementUnit?: string; // единица измерения
}

export type PaymentMethodType = 'auth' | 'charge';

type PaymentBlockOptions = {
  language?: string;
  applePaySupport?: boolean;
  googlePaySupport?: boolean;
  yandexPaySupport?: boolean;
  masterPassSupport?: boolean;
  tinkoffInstallmentSupport?: boolean;
  tinkoffPaySupport?: boolean;
  mirPaySupport?: boolean;
  sbpSupport?: boolean;
};

export type PaymentBlocksOptions = PaymentOptions | PaymentBlockOptions;

export type BlocksConfiguration = {
  components: {
    paymentButton: {
      text: string;
      fontSize: string;
    };
    paymentForm: {
      labelFontSize: string;
      activeLabelFontSize: string;
      fontSize: string;
    };
  };
  appearance: {
    colors: {
      primaryButtonColor: string; // hex color
      primaryButtonTextColor: string;
      primaryHoverButtonColor: string;
      primaryButtonHoverTextColor: string;
      activeInputColor: string;
      inputBackground: string;
      inputColor: string;
      inputBorderColor: string;
      errorColor: string;
    };
    borders: {
      radius: string;
    };
  };
};

export enum CloudpaymentsWidgetEventName {
  Destroy = 'destroy',
  Init = 'init',
  Success = 'success',
  Fail = 'fail',
  Cryptogram = 'cryptogram',
}

declare class PaymentBlocks {
  constructor(options: PaymentOptions, config: BlocksConfiguration);

  mount(element: Element): void;

  on<T = void>(eventName: CloudpaymentsWidgetEventName, handler: (...args: any[]) => T): void;
  off<T = void>(eventName: CloudpaymentsWidgetEventName, handler?: () => T): void;
  unmount(): void;
}

export declare class CloudPayments {
  PaymentBlocks: typeof PaymentBlocks;
}

export interface ApiCloudPaymentsInvoiceRequest {
  offer_id: string;
  user_bonus_id?: string;
}

export interface ApiCloudPaymentsInvoiceResponse {
  invoice_id: string;
  payment_widget: {
    title: string;
    amount: number;
    currency: 'RUB';
    transaction_type: PaymentMethodType;
  };
}

export interface ApiCloudPaymentsInfoResponse {
  type: 'cloudpayments';
  data: {
    public_id: string;
    currency: string;
    description: string;
  };
}

export interface CloudPaymentsInvoice {
  invoiceId: string;
  amount: number;
  description: string;
  currency: string;
  transactionType: PaymentMethodType;
}

export type WidgetParams = {
  offerId?: string;
  bonusId?: string;
  onSuccessPaymentCallback: () => void;
};
