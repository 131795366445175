<template>
  <app-button
    :class="$style.promocodeButton"
    :aria-label="$t('ariaLabel.header.promocodeButton')"
    :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Header, 'promocodeButton')"
    :text="text"
    size="small"
    variation="button-secondary"
    @click="onPromocodeButtonClick()"
  >
    <icon-gift v-if="isGiftIconShown" size="small" />
  </app-button>
</template>

<script lang="ts" setup>
import { usePromocodeActivationButton } from '@/code/promocode/use-promocode-activation-button';
import AppButton from '@/components/ui/AppButton.vue';

import IconGift from '../icons/IconGift.vue';

const { isShownPromocodeButton, onClick: onPromocodeButtonClick } = usePromocodeActivationButton();

defineProps<{
  isGiftIconShown: boolean;
  text: string;
}>();

defineExpose({
  isShownPromocodeButton,
});
</script>

<style lang="scss" module>
@use '@/assets/breakpoints' as breakpoints;

.promocodeButton {
  height: 40px;
  border-radius: var(--g-border-round-12);
  background-color: var(--color-bg-secondary-80);
  white-space: nowrap;
  flex-shrink: 0;

  &:hover:not([disabled]) {
    background-color: var(--color-states-bg-secondary-hover-70);
  }
}
</style>
