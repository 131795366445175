import { AnalyticEventNameNew } from '@package/sdk/src/analytics';
import type { FunctionalComponent } from 'vue';

import { AppLanguage } from '../localization/language';

export enum SocialMediaType {
  Telegram = 'telegram',
  Vk = 'vk',
  Ok = 'ok',
  Dzen = 'dzen',
}

interface Contact {
  title: string;
  value: string;
}

interface Socials {
  Vk?: string;
  Telegram?: string;
  TelegramBot?: string;
  Ok?: string;
  Dzen?: string;
}

export interface ICompanyInformation {
  supportEmail: string;
  officeAddress: string;
  businessCenter?: string;
  workTime: string;
  phone?: string;
  contacts: Contact[];
  socials: Socials;
  companyInformation: string[];
}

export interface CompanyInformationMultipleLanguages {
  [AppLanguage.RU]?: ICompanyInformation;
  [AppLanguage.EN]?: ICompanyInformation;
  [AppLanguage.AM]?: ICompanyInformation;
}

export interface ISocialMediaLink {
  name: SocialMediaType;
  icon: FunctionalComponent;
  href: string;
}

export interface IAppStoreLink {
  link: string;
  title: string;
  analyticEventName: AnalyticEventNameNew;
  icon: FunctionalComponent;
}
