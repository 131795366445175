import regex from '../../code/auth/regex';

export const EMAIL_REGEX = /^[-a-z0-9.~!$%^&*_=+}{'?]+@[a-z0-9_][-a-z0-9_]*\.[.-a-z0-9_]+$/i;

export const HAS_NUMBER_REGEX = /[0-9]/;

export function isOnlyLatinLetters(input: string): boolean {
  const char = !!input.match(/[a-zA-Z]/);

  return !!input.match(/^[a-zA-Z0-9\x21-\x7E]*$/) && char;
}

export function isContainsNumber(input: string): boolean {
  return HAS_NUMBER_REGEX.test(input);
}

export function isContainsLetters(input: string): boolean {
  return /[a-z]/i.test(input);
}

export function isEmail(input: string): boolean {
  return EMAIL_REGEX.test(input);
}

export function isEndsWithSlash(str: string) {
  return str.slice(-1) === '/';
}

export function removeHttps(url: string) {
  return url.replace(/^https?:\/\//, '');
}

export function removeHttp(url: string) {
  return url.replace(/^http?:\/\//, '');
}

export const onlyLetters = (value: string): string => {
  return value.replace(/[^a-zA-Z]+/g, '');
};

export const onlyDigits = (value: string): string => value.replace(regex.digits, '');

export function toPlainObject(value: any) {
  return JSON.parse(JSON.stringify(value));
}
