import { isClient } from '@vueuse/core';

import useMobile from '../../platform/layout/use-mobile';
import {
  getAndroidVersion,
  getIOSVersion,
  getOperationSystemName,
  getWindowsVersion,
  OperationSystem,
} from '../environment/platform';

interface IDebugInformation {
  browser: string;
  os: string;
  isMobile: boolean;
}

export default function useDebugInformation(): IDebugInformation {
  const isMobile = useMobile();

  const os = (() => {
    const _os = getOperationSystemName();
    let osVersion = '';

    switch (_os) {
      case OperationSystem.Android:
        osVersion = getAndroidVersion();
        break;
      case OperationSystem.Windows:
        osVersion = getWindowsVersion();
        break;
      case OperationSystem.IOS:
        osVersion = getIOSVersion();
        break;
    }

    return isClient ? `${_os}, version: ${osVersion}` : '';
  })();

  return {
    isMobile,
    browser: isClient ? window.navigator.appVersion : '',
    os,
  };
}
