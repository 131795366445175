import { ProcessingMapper } from '../base/processing-mapper';
import { ApiSitemapPageItem } from './seo-types/sitemap-page-item';
import { SitemapPageItem } from './types/sitemaps';

export class SitemapPageItemMapper {
  static mapMany(items: ApiSitemapPageItem[]): SitemapPageItem[] {
    return ProcessingMapper.process(items.map(SitemapPageItemMapper.map));
  }

  static map({ ...rest }: ApiSitemapPageItem): SitemapPageItem {
    return ProcessingMapper.process({ ...rest });
  }
}
