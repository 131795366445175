import { ParentalControlStep } from '@package/sdk/src/api';
import { defineStore } from 'pinia';
import { ref } from 'vue';

const useParentalControlStore = defineStore('parental-control', () => {
  const step = ref(ParentalControlStep.Hidden);

  const setModalStep = (value: ParentalControlStep) => {
    step.value = value;
  };

  const isLogoutButtonShown = ref(true);
  const setIsLogoutButtonShown = (value: boolean) => {
    isLogoutButtonShown.value = value;
  };

  const pinCode = ref('');
  const setPinCode = (value: string) => {
    pinCode.value = value;
  };

  const reset = () => {
    isLogoutButtonShown.value = true;
    step.value = ParentalControlStep.Hidden;
    pinCode.value = '';
  };

  return {
    pinCode,
    setPinCode,
    isLogoutButtonShown,
    setIsLogoutButtonShown,
    step,
    setModalStep,
    reset,
  };
});

export default useParentalControlStore;
