<template>
  <div
    :class="{
      [$style.ambient]: true,
      [$style.ambientDesktop]: !$isMobile,
      [$style.ambientMobile]: $isMobile,
    }"
  >
    <canvas v-show="isShown" ref="canvasElPrev" :class="$style.canvas" />
    <canvas v-show="isShown" ref="canvasElNext" :class="$style.canvas" />
  </div>
</template>

<script lang="ts" setup>
import useLogger from '@package/logger/src/use-logger';
import { isFunction } from '@package/sdk/src/core';
import { onBeforeUnmount, onMounted, ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    hexs: string[];
    rectHeight: number;
    type: 'mixed' | 'regular';
  }>(),
  {
    rectHeight: 100,
    type: 'regular',
  },
);

const logger = useLogger('AmbientVideoBackground.vue');

const canvasElNext = ref<HTMLCanvasElement>();
const canvasElPrev = ref<HTMLCanvasElement>();

const state = {
  ctxFadeTimeoutId: 0,
  ctxWidth: 1000,
  initialBrightnessValue: 30,
  endBrightnessValue: 80,
  isNextFadeRunning: false,
  isPrevFadeRunning: false,
};

const runCtxFade = (ctx: CanvasRenderingContext2D, mode: 'increase' | 'decrease') => {
  try {
    let _mode = mode;

    if (!isFunction(ctx.filter)) {
      return;
    }

    const brightnessValue = ctx.filter.replace('brightness(', '');
    const parsedValue = parseInt(brightnessValue, 10);

    const startValue = _mode === 'decrease' ? state.endBrightnessValue : state.initialBrightnessValue;
    const brightness = Number.isNaN(parsedValue) ? startValue : parsedValue;

    if (brightness <= state.initialBrightnessValue) {
      _mode = 'increase';
    }

    if (brightness >= state.endBrightnessValue) {
      _mode = 'decrease';
    }

    ctx.filter = `brightness(${_mode === 'decrease' ? brightness - 1 : brightness + 1}%)`;
    ctx.fillRect(0, 0, state.ctxWidth, props.rectHeight);

    const fps = 1000 / 20;

    state.ctxFadeTimeoutId = window.setTimeout(() => runCtxFade(ctx, _mode), fps);
  } catch (error) {
    console.error(error);
  }
};

const drawAmbient = (ctx: CanvasRenderingContext2D, hexs: string[]) => {
  try {
    const grd = ctx.createLinearGradient(0, 0, 200, 0);

    hexs.forEach((value, index) => {
      try {
        grd.addColorStop(index * 0.1, value);
      } catch (error) {
        logger.error(error);
      }
    });

    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, state.ctxWidth, props.rectHeight);
  } catch (error) {
    logger.error(error);
  }
};

const tick = (hexs: string[]) => {
  const prevCtx = canvasElPrev.value?.getContext('2d') as CanvasRenderingContext2D;
  const nextCtx = canvasElNext.value?.getContext('2d') as CanvasRenderingContext2D;

  if (hexs.length > 0) {
    drawAmbient(prevCtx, hexs);

    if (!state.isPrevFadeRunning) {
      runCtxFade(prevCtx, 'increase');
      state.isPrevFadeRunning = true;
    }
  }

  drawAmbient(nextCtx, hexs);

  if (!state.isNextFadeRunning) {
    runCtxFade(nextCtx, 'decrease');
    state.isNextFadeRunning = true;
  }
};

const drawMixedBlur = () => {
  if (!canvasElNext.value) {
    return;
  }

  const ctx = canvasElNext.value.getContext('2d') as CanvasRenderingContext2D;

  for (let i = 0; i < props.hexs.length; i++) {
    ctx.fillStyle = props.hexs[Math.floor(Math.random() * props.hexs.length)];
    ctx.beginPath();
    ctx.arc(
      Math.random() * canvasElNext.value.width,
      Math.random() * canvasElNext.value.height,
      Math.random() * 100 + 50,
      0,
      Math.PI * 2,
    );
    ctx.fill();
  }

  ctx.globalAlpha = 0.1;
  for (let i = 0; i < props.hexs.length; i++) {
    ctx.drawImage(canvasElNext.value, 0, 0);
  }
  ctx.globalAlpha = 1.0;
};

const drawHandlerMap = {
  mixed: drawMixedBlur,
  regular: tick,
};

watch(() => props.hexs, drawHandlerMap[props.type]);

const isShown = ref(true);

onMounted(() => {
  window.setTimeout(() => {
    isShown.value = true;
  }, 5000);
});

onBeforeUnmount(() => {
  if (state.ctxFadeTimeoutId) {
    window.clearTimeout(state.ctxFadeTimeoutId);
  }
});
</script>

<style module lang="scss">
.ambient {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
  transform: scale(1);
  pointer-events: none;
  filter: blur(35px);
}

.ambientDesktop {
  width: 100vw;
  height: calc(100vh + var(--app-header-height));
}

.ambientMobile {
  width: 100vw;
  height: 50vh;
}

.canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
