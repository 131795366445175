import { ProcessingMapper } from '../base/processing-mapper';
import { DeviceSession } from '../environment/device';
import { ApiDeviceSession } from './device';
import { DeviceInfoMapper } from './device-info';

export class DeviceSessionMapper {
  static mapMany(sessions: ApiDeviceSession[]): DeviceSession[] {
    return ProcessingMapper.process(sessions.map(DeviceSessionMapper.map));
  }

  static map({ id, created_at, active, current_session, device }: ApiDeviceSession): DeviceSession {
    return ProcessingMapper.process({
      id,
      createdAt: created_at,
      isActive: active,
      isCurrentSession: current_session,
      device: DeviceInfoMapper.map(device),
    });
  }
}
