import { UTCDate } from '@date-fns/utc';
import ConstantsConfigInstanceWeb from '@package/constants/code/constants-config-web';
import useLogger from '@package/logger/src/use-logger';
import { ensureStartSlash, isDefined, replaceURLVariables } from '@package/sdk/src/core';
import { HTTPStatusCode } from '@package/sdk/src/core/network/http-status-code';
import { isClient } from '@vueuse/core';
import CryptoJS from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';
import Utf16 from 'crypto-js/enc-utf16';
import MD5 from 'crypto-js/md5';
import { nanoid } from 'nanoid';
// @ts-expect-error
import { $Fetch, NitroFetchOptions, NitroFetchRequest } from 'nitropack';
import { useRequestHeaders } from 'nuxt/app';
import { storeToRefs } from 'pinia';
import * as qs from 'qs';
import { v4 } from 'uuid';

import { CookieName, cookies } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';
import useEnvironment from '@/platform/environment/use-environment';
import { ApiError } from '@/platform/http/errors';
import { HttpClientCacheController } from '@/platform/http/http-client-cache-controller';
import type { HttpRequestBody, HttpRequestEndpoint, HttpRequestOptions } from '@/platform/http/http-request';
import { HttpRequestMethod } from '@/platform/http/http-request';
import { getLanguageByISO639Format } from '@/platform/localization/language';
import { AppVariation } from '@/platform/variation/interfaces';
import useVariationVariables from '@/platform/variation/use-variation-variables';
import { useLayoutStore } from '@/stores/use-layout-store';
import { useSessionStore } from '@/stores/use-session-store';

// TODO: Надо разобраться с типами из Nitropack потом
type FetchOptions = any;
type FetchContext = any;

declare module 'nuxt/app' {
  interface NuxtApp {
    $http: any;
    $flagsHttp: any;
  }
}

const keyForEncr = '0YasTYdd8KR31crx4ZfUhyJv4yINTX74l7kpmDAeJhv9aH3Ur0rYFzEMPo04LpFdikDjBuexGStXOUqPwOYcjA==';

const logger = useLogger('http-client');

export default defineNuxtPlugin(({ provide }) => {
  const { normalizedApiFlagsBaseUrl, normalizedApiBaseUrl } = useEnvironment();
  const { appVariation } = useVariationVariables();
  const sessionStore = useSessionStore();
  const requestHeaders = useRequestHeaders();
  const { currentAppLanguage } = storeToRefs(useLayoutStore());

  const incomingRequestId = nanoid(3);

  const doSignRequest = (context: FetchContext, headers: Headers) => {
    const { options, request } = context;

    const requestType = options.method?.toUpperCase() || 'POST';

    const baseRequestString = request.toString();
    // Важно чтобы строка запроса начиналась с /, иначе токен будет невалидный, и 403
    const encodedURIString = ensureStartSlash(baseRequestString);

    const encReqBody = Base64.stringify(MD5(JSON.stringify(options.body)));
    const reqCTHeader = 'application/json';
    const platformDate = new UTCDate().toUTCString();

    const encryptedParts = [requestType, reqCTHeader, encReqBody, encodedURIString, platformDate];

    const stringForEncrypted = encryptedParts.join(',');
    const stringForEncryptedUtf16 = Utf16.stringify(Base64.parse(Base64.stringify(Utf16.parse(stringForEncrypted))));

    const platformLogin = 'web';
    const platformToken = Base64.stringify(CryptoJS.HmacSHA1(stringForEncryptedUtf16, keyForEncr));

    headers.set('Content-Type', reqCTHeader);
    headers.set('Http-Date', platformDate);
    headers.set('Content-MD5', encReqBody);
    headers.set('Authorization', `APIAuth ${platformLogin}:${platformToken}`);
  };

  const accessToken = useAppCookie(CookieName.Auth, { maxAge: cookies.auth.maxAge, path: '/' });
  const visitorIdCookie = useAppCookie(CookieName.VisitorId, {
    maxAge: cookies.visitorId.maxAge,
    path: '/',
    default: () => v4(),
  });

  const onResponse = ({ response }: FetchContext) => {
    const { status, url, _data } = response || {};

    if (status === HTTPStatusCode.Found || status === HTTPStatusCode.NotFound) {
      throw new ApiError(url as string, status as HTTPStatusCode, _data);
    }
  };

  const onRequest = (context: FetchContext) => {
    const { request, options } = context;
    const isRequestSigned = isDefined(options.signRequest) ? options.signRequest : false;
    const isForceAPIAuthToken = options.isForceAPIAuthToken;
    const timezoneOffset = new UTCDate().getTimezoneOffset();

    const { headers: contextHeaders } = options;
    const headers = new Headers(contextHeaders);

    const setupServerHeaders = () => {
      if (!requestHeaders) {
        return;
      }

      const host = requestHeaders.host;

      if (host) {
        const variationHeader = (() => {
          if (appVariation === AppVariation.Am) {
            return 'AM';
          }

          return 'RU';
        })();

        headers.set('X-Viju-Domain', variationHeader);
      }

      const entries = Object.entries(requestHeaders).filter(([key]) => key !== 'host');

      entries.forEach(([key, value]) => headers.set(key, value as string));
    };

    const signRequest = () => {
      if (isRequestSigned) {
        doSignRequest(context, headers);
      }

      if (!isForceAPIAuthToken && accessToken.value?.token) {
        headers.set('Authorization', `Bearer ${accessToken.value.token}`);
      }
    };

    signRequest();

    if (process.server) {
      setupServerHeaders();
    }

    headers.set('Accept-Language', getLanguageByISO639Format(currentAppLanguage.value));
    headers.set('VisitorId', visitorIdCookie.value);
    headers.set('X-TimeZone', String(timezoneOffset / 60));

    options.headers = headers;

    if (process.server) {
      logger.info(`HTTP Client/User - ${incomingRequestId}`, 'Request path', request);
    }
  };
  const onResponseError = ({ response, options }: FetchContext) => {
    const { url, _data, status } = response || {};

    logger.error(`[API][${options.method?.toUpperCase()} ${status}] ${url}`, _data);

    throw new ApiError(url as string, status as HTTPStatusCode, _data);
  };

  class HttpClient {
    private readonly _fetch: $Fetch<unknown, NitroFetchRequest>;
    private readonly _requestControllers = new Map<string, AbortController>();
    public readonly cache: HttpClientCacheController;

    constructor(baseURL: string) {
      const headers = {};

      const timeout = isClient
        ? ConstantsConfigInstanceWeb.getProperty('requestTimeoutMsClient')
        : ConstantsConfigInstanceWeb.getProperty('requestTimeoutMsServer');

      const fetchOptions = { baseURL, headers, timeout, onRequest, onResponseError, onResponse } as FetchOptions;

      this._fetch = $fetch.create(fetchOptions);
      this.cache = new HttpClientCacheController();
    }

    public cancelRequest = (path: string) => {
      this._requestControllers.get(path)?.abort();
      this._requestControllers.delete(path);
    };

    public get = <T>(endpoint: HttpRequestEndpoint, options?: HttpRequestOptions) => {
      return this.sendRequest<T>(HttpRequestMethod.GET, endpoint, null, options);
    };

    public post = <T>(endpoint: HttpRequestEndpoint, body: HttpRequestBody, options?: HttpRequestOptions) => {
      return this.sendRequest<T>(HttpRequestMethod.POST, endpoint, body, options);
    };

    public put = <T>(endpoint: HttpRequestEndpoint, body: HttpRequestBody, options?: HttpRequestOptions) => {
      return this.sendRequest<T>(HttpRequestMethod.PUT, endpoint, body, options);
    };

    public patch = <T>(endpoint: HttpRequestEndpoint, body: HttpRequestBody, options?: HttpRequestOptions) => {
      return this.sendRequest<T>(HttpRequestMethod.PATCH, endpoint, body, options);
    };

    public delete = <T>(endpoint: HttpRequestEndpoint, options?: HttpRequestOptions) => {
      return this.sendRequest<T>(HttpRequestMethod.DELETE, endpoint, null, options);
    };

    public processRequest = async <T>(
      endpoint: HttpRequestEndpoint,
      requestType: 'raw' | 'default',
      fetchOptions: NitroFetchOptions<any>,
      options?: HttpRequestOptions,
      retryCount = 1,
    ) => {
      const makeRequest = async () => {
        const { method } = fetchOptions;
        const { path: _path, cacheStrategy, cacheTimeMilliseconds } = endpoint;

        const isCacheableRequest =
          isClient && (cacheStrategy === 'default' || cacheStrategy === 'max-age') && method === 'GET';

        const normalizedCacheTimeMilliseconds =
          (cacheStrategy === 'default' ? 86400000 * 7 : cacheTimeMilliseconds) || 0;

        const path = this.applyParamsToPath(_path, fetchOptions);

        // @ts-expect-error
        this.addAbortController(endpoint, fetchOptions, new AbortController());

        if (isCacheableRequest) {
          const cached = await this.cache.readEntry<T>(path);

          if (cached) {
            const isCacheOutdated = Date.now() >= cached.expires;

            if (!isCacheOutdated) {
              return cached.value;
            }

            this.cache.removeEntry(path);
          }
        }

        const serializedHeaders: Record<string, string> = {};

        try {
          // @ts-ignore
          const fetchFunc = requestType === 'raw' ? this._fetch.raw : this._fetch;

          const options = {
            ...fetchOptions,
            params: undefined,
            query: undefined,
          };

          const data = await fetchFunc<T>(path, options);

          data?.headers?.forEach((value, key) => {
            serializedHeaders[key] = value;
          });

          if (isCacheableRequest) {
            if (requestType === 'raw') {
              this.cache.addEntry(
                path,
                {
                  data: data._data,
                  headers: serializedHeaders,
                },
                { expires: normalizedCacheTimeMilliseconds },
              );
            } else {
              this.cache.addEntry(path, data, { expires: normalizedCacheTimeMilliseconds });
            }
          }

          if (requestType === 'raw') {
            return {
              headers: serializedHeaders,
              data: data._data,
            } as unknown as Promise<T>;
          }

          return data as unknown as Promise<T>;
        } catch (error) {
          const isSessionRequest = path.includes('session');

          let updatedResponseData: any;

          if (retryCount < 3) {
            if (error instanceof ApiError && error.is(HTTPStatusCode.Unauthorized)) {
              await sessionStore.refreshSession();

              if (isClient && !isSessionRequest) {
                updatedResponseData = await this.processRequest(
                  endpoint,
                  requestType,
                  fetchOptions,
                  options,
                  retryCount + 1,
                );
              }
            }
          }

          if (updatedResponseData) {
            return updatedResponseData;
          }

          throw error;
        }
      };

      return await makeRequest();
    };

    public sendRequest = async <T>(
      method: HttpRequestMethod | string,
      endpoint: HttpRequestEndpoint,
      body?: HttpRequestBody,
      options: HttpRequestOptions = {},
    ) => {
      const { requestId, headers, signRequest, params, query, retry, response, parseResponse, isForceAPIAuthToken } =
        options;

      const fetchOptions = {
        method,
        body,
        requestId,
        headers,
        signRequest,
        params,
        query,
        retry,
        response,
        parseResponse,
        isForceAPIAuthToken,
      } as NitroFetchOptions<any>;

      const startTime = performance.now();

      try {
        const data = await this.processRequest<T>(endpoint, 'default', fetchOptions, options);

        if (!isClient) {
          const endTime = performance.now();
          logger.info(`HTTP Client/User - ${incomingRequestId}`, `Executed in ms: ${endTime - startTime}`);
        }

        return data;
      } catch (error) {
        const endTime = performance.now();

        if (!isClient) {
          logger.error(
            `HTTP Client/User - ${incomingRequestId}`,
            `

      Executed in ms: ${endTime - startTime}\`
      Error - ${error}`,
          );
        }

        throw error;
      }
    };

    public sendRawRequest = <T>(
      method: HttpRequestMethod | string,
      path: HttpRequestEndpoint,
      body?: HttpRequestBody,
      options?: HttpRequestOptions,
    ) => {
      const { requestId, headers, signRequest, params, query, retry, response, parseResponse, isForceAPIAuthToken } =
        options || {};

      const fetchOptions = {
        method,
        body,
        requestId,
        headers,
        signRequest,
        params,
        query,
        retry,
        response,
        parseResponse,
        isForceAPIAuthToken,
      } as NitroFetchOptions<any>;

      return this.processRequest<T>(path, 'raw', fetchOptions, options);
    };

    private addAbortController = (
      endpoint: Pick<HttpRequestEndpoint, 'path'>,
      fetchOptions: Pick<FetchOptions, 'signal'>,
      controller: AbortController,
    ) => {
      this._requestControllers.set(endpoint.path, controller);
      fetchOptions.signal = controller.signal;
    };

    private applyParamsToPath(path: string, options: FetchOptions) {
      let _path = path;

      if (options.params) {
        _path = replaceURLVariables(path, options.params);
      }

      if (options.query) {
        const queryStr = decodeURIComponent(qs.stringify(options.query, { arrayFormat: 'brackets' }));

        _path = _path + '?' + queryStr;
      }

      return ensureStartSlash(_path);
    }
  }

  const httpClient = new HttpClient(normalizedApiBaseUrl.value);
  const flagsHttpClient = new HttpClient(normalizedApiFlagsBaseUrl.value);

  provide('http', httpClient);
  provide('flagsHttp', flagsHttpClient);
});
