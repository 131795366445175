<template>
  <app-sdk-button
    :ref="(comp) => (buttonEl = comp?.$el)"
    :text="text"
    :title="title"
    :aria-label="ariaLabel"
    :is-loading="isLoading"
    :is-disabled="isDisabled"
    :variation="variation"
    :size="size"
    :class="$style.appButton"
    :text-class-name="textClassName"
  >
    <template #loader>
      <app-loader-icon v-if="isLoading" :class="$style.loader" mode="dark" :width="100" :height="20" />
    </template>
    <template #icon>
      <slot name="icon"></slot>
    </template>
  </app-sdk-button>
</template>

<script lang="ts" setup>
import AppSdkButton from '@package/ui/src/components/AppButton.vue';
import type { AppButtonSize, AppButtonVariation } from '@package/ui/src/types';
import { onMounted, ref } from 'vue';

import AppLoaderIcon from './AppLoaderIcon.vue';

const props = withDefaults(
  defineProps<{
    autofocus?: boolean;
    textClassName?: string;
    text: string;
    isLoading?: boolean;
    isDisabled?: boolean;
    title?: string;
    ariaLabel?: string;
    variation?: AppButtonVariation;
    size?: AppButtonSize;
  }>(),
  {
    title: '',
    ariaLabel: '',
    isLoading: false,
    isDisabled: false,
    variation: 'button-primary',
    size: 'medium',
    autofocus: false,
  },
);

const buttonEl = ref<HTMLElement>();

onMounted(() => {
  if (props.autofocus) {
    buttonEl.value?.focus();
  }
});
</script>

<style lang="scss" module>
.loader {
  position: absolute;
  left: calc(66% - 100px);
}

.appButton {
  position: relative;
}
</style>
