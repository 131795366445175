import type { Bonus } from '@package/sdk/src/api';
import type { PromoOfferV2 } from '@package/sdk/src/api/offers/offer-v2';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const usePromocodeStore = defineStore('promocode', () => {
  const savedPromocode = ref('');

  const setSavedPromocode = (value: string) => {
    savedPromocode.value = value;
  };

  const promocodeCheckInfo = ref<PromoOfferV2>();
  const setPromocodeCheckInfo = (value?: PromoOfferV2) => {
    promocodeCheckInfo.value = value;
  };

  const appliedBonusInfo = ref<Bonus>();
  const setAppliedBonusInfo = (value: Bonus) => {
    appliedBonusInfo.value = value;
  };

  const reset = () => {
    promocodeCheckInfo.value = undefined;
    appliedBonusInfo.value = undefined;
    savedPromocode.value = '';
  };

  return {
    reset,
    appliedBonusInfo,
    setAppliedBonusInfo,
    savedPromocode,
    setSavedPromocode,
    promocodeCheckInfo,
    setPromocodeCheckInfo,
  };
});
