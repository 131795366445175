import { useNuxtApp } from 'nuxt/app';

import { ENDPOINTS } from '@/platform/http';

export function useProfilesApi() {
  const app = useNuxtApp();

  const addProfile = (name: string) => {
    return app.$http.post<void>(ENDPOINTS.SESSIONS_USER_PROFILES, { name });
  };

  const setProfile = (id: string) => {
    return app.$http.patch<void>(ENDPOINTS.SESSIONS_CURRENT_CHANGE_PROFILE, { profile_id: id });
  };

  const removeProfile = (id: string) => {
    return app.$http.delete<void>(ENDPOINTS.SESSIONS_USER_PROFILES_ID, { params: { id } });
  };

  return { addProfile, setProfile, removeProfile };
}
