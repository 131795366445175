import { ProcessingMapper } from '../base/processing-mapper';
import { ApiSitemapsItem } from './seo-types/sitemaps-item';
import { SitemapsItem } from './types/sitemaps';

export class SitemapsItemMapper {
  static mapMany(items: ApiSitemapsItem[]): SitemapsItem[] {
    return ProcessingMapper.process(items.map(SitemapsItemMapper.map));
  }

  static map({ type, title, total_pages, total_items }: ApiSitemapsItem): SitemapsItem {
    return ProcessingMapper.process({
      type,
      title,
      totalItems: total_items,
      totalPages: total_pages,
    });
  }
}
