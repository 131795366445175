import { AppLanguage } from '../localization/language';
import { getAmCompanyInformation } from './am-variation';
import { AppVariation } from './interfaces';
import { ruCompanyInformation, socialMediaLinks } from './ru-variation';
import type { IAppStoreLink, ISocialMediaLink } from './variation-types';

export function getCompanyInformation(variation: AppVariation, appLanguage: AppLanguage) {
  if (variation === AppVariation.Ru) {
    return ruCompanyInformation;
  }

  if (variation === AppVariation.Am) {
    return getAmCompanyInformation(appLanguage);
  }

  return ruCompanyInformation;
}

export function getSocialMediaLinks(appVariation: AppVariation): ISocialMediaLink[] {
  if (appVariation === AppVariation.Am) {
    return [];
  }

  return socialMediaLinks;
}

export function getAppStoreLinks(): IAppStoreLink[] {
  return [];
}
