export enum OpenGraphMetaTag {
  Video = 'og:video',
  Type = 'og:type',
  Description = 'og:description',
  Image = 'og:image',
  Title = 'og:title',
  Locale = 'og:locale',
  Url = 'og:url',
  SiteName = 'og:site_name',
}
