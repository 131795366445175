import type { DSMLAnalyticApi, SendEventOptions } from '@package/dsml-js/src/core/dsml';
import { DsmlEventError } from '@package/dsml-js/src/core/platform/errors';
import useLogger from '@package/logger/src/use-logger';
import * as Sentry from '@sentry/browser';

interface DsmlInitializeOptions {
  login?: string;
  password?: string;
  appVersion: string;
  isMobile: boolean;
  appVariation: 'am' | 'ru';
  isRelease: boolean;
  visitorId: string;
  profileId?: string;
  userId?: string;
  userIpKey: string;
  userIp: string;
}

export class DsmlEventSender {
  private readonly tech: DSMLAnalyticApi | undefined;

  constructor() {
    this.tech = window.$dsml as DSMLAnalyticApi;
  }

  public sendEvent(data: SendEventOptions): void {
    try {
      this.tech?.sendEvent(data);
    } catch (error) {
      console.error(error);
    }
  }

  public async initialize(options: DsmlInitializeOptions) {
    const {
      login,
      password,
      isRelease,
      appVersion,
      appVariation,
      isMobile,
      userIp,
      userIpKey,
      userId,
      profileId,
      visitorId,
    } = options;

    const logger = useLogger('dsml-sender');

    if (!login || !password) {
      logger.warn('Expect dsml.partner id and dsml.password to be defined');
      logger.warn(`Got: partnerId: ${login}, password: ${password}`);
      return;
    }

    const onDsmlError = (error: DsmlEventError) => {
      console.error(error.toJSON());
      Sentry.captureException(error);
    };

    // скрипт dsml мог не подгрузиться - на всякий попытаемся еще раз
    if (!this.tech) {
      window.setTimeout(() => this.initialize(options), 5000);
      return;
    }

    this.tech?.setEnvironment(isRelease ? 'production' : 'development');

    this.tech.setLogger(logger);

    await this.tech?.init({
      partnerId: login,
      password,
      clientType: isMobile ? 'web_mobile' : 'web_desktop',
      appVersion,
      variation: appVariation,
      eventSenderMode: 'default',
    });

    this.tech?.setUser({
      visitorId,
      profileId,
      userId,
      [userIpKey]: userIp,
    });

    this.tech?.addEventListener('error', onDsmlError);
  }
}
