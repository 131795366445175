import { PromoOfferErrorType } from '@package/sdk/src/api/offers/promo-offer-error-type';

import useLocale from '../../platform/localization/use-locale';

export function getPromoOfferErrorMessage(errorCode: string) {
  const { translate } = useLocale();

  const errorMessages = {
    [PromoOfferErrorType.EntityNotFound]: translate('promocode.errorMessage.entityNotFound'),
    [PromoOfferErrorType.BonusRestrictedForCountry]: translate('promocode.errorMessage.bonusRestrictedForCountry'),
    [PromoOfferErrorType.BonusDateIsInvalid]: translate('promocode.errorMessage.bonusDateIsInvalid'),
    [PromoOfferErrorType.UserFromPartner]: translate('promocode.errorMessage.userFromPartner'),
    [PromoOfferErrorType.UserHasExternalSubscription]: translate('promocode.errorMessage.userHasExternalSubscription'),
    [PromoOfferErrorType.UserHadSubscriptionBefore]: translate('promocode.errorMessage.userHadSubscriptionBefore'),
    [PromoOfferErrorType.BonusIsNotProfitable]: translate('promocode.errorMessage.bonusIsNotProfitable'),
    [PromoOfferErrorType.BonusTypeIsInvalid]: translate('promocode.errorMessage.bonusTypeIsInvalid'),
    [PromoOfferErrorType.BonusAlreadyActivated]: translate('promocode.errorMessage.bonusAlreadyActivated'),
    [PromoOfferErrorType.BonusAlreadyRejected]: translate('promocode.errorMessage.bonusAlreadyActivated'),
  };

  const message = errorMessages[errorCode as PromoOfferErrorType];

  return message || translate('lk.bonuses.promocodeError');
}
