<template>
  <app-sdk-textarea
    :model-value="modelValue"
    :placeholder="placeholder"
    :max-length="maxLength"
    @update:model-value="onUpdate"
  />
</template>

<script lang="ts" setup>
import AppSdkTextarea from '@package/ui/src/components/AppTextarea.vue';

defineProps<{
  modelValue: string;
  placeholder: string;
  maxLength: number;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const onUpdate = (value: string) => {
  emit('update:modelValue', value);
};
</script>
