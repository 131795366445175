import { ProcessingMapper } from '../base/processing-mapper';
import { ApiFeatureToggleItem } from './feature-toggle';
import { FeatureToggleItem } from './types/feature';

export class FeatureToggleItemMapper {
  static mapMany(items: ApiFeatureToggleItem[]): FeatureToggleItem[] {
    return ProcessingMapper.process(items.map(FeatureToggleItemMapper.map));
  }

  static map({ name, status, variant }: ApiFeatureToggleItem): FeatureToggleItem {
    return ProcessingMapper.process({
      name,
      status,
      variant: variant ? { name: variant.name, type: variant.type, value: variant.value } : undefined,
    });
  }
}
