<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.75 2.5C0.75 2.08579 1.08579 1.75 1.5 1.75H10.5C10.9142 1.75 11.25 2.08579 11.25 2.5C11.25 2.91421 10.9142 3.25 10.5 3.25H1.5C1.08579 3.25 0.75 2.91421 0.75 2.5ZM3.75 6C3.75 5.58579 4.08579 5.25 4.5 5.25H10.5C10.9142 5.25 11.25 5.58579 11.25 6C11.25 6.41421 10.9142 6.75 10.5 6.75H4.5C4.08579 6.75 3.75 6.41421 3.75 6ZM1.5 8.75C1.08579 8.75 0.75 9.08579 0.75 9.5C0.75 9.91421 1.08579 10.25 1.5 10.25H10.5C10.9142 10.25 11.25 9.91421 11.25 9.5C11.25 9.08579 10.9142 8.75 10.5 8.75H1.5Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.25 2.75C0.25 2.19772 0.697715 1.75 1.25 1.75H14.75C15.3023 1.75 15.75 2.19772 15.75 2.75C15.75 3.30228 15.3023 3.75 14.75 3.75H1.25C0.697715 3.75 0.25 3.30228 0.25 2.75ZM4.75 8C4.75 7.44772 5.19772 7 5.75 7H14.75C15.3023 7 15.75 7.44772 15.75 8C15.75 8.55228 15.3023 9 14.75 9H5.75C5.19772 9 4.75 8.55228 4.75 8ZM1.25 12.25C0.697715 12.25 0.25 12.6977 0.25 13.25C0.25 13.8023 0.697715 14.25 1.25 14.25H14.75C15.3023 14.25 15.75 13.8023 15.75 13.25C15.75 12.6977 15.3023 12.25 14.75 12.25H1.25Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 5C2 4.44772 2.44772 4 3 4H21C21.5523 4 22 4.44772 22 5C22 5.55228 21.5523 6 21 6H3C2.44772 6 2 5.55228 2 5ZM8 12C8 11.4477 8.44772 11 9 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H9C8.44772 13 8 12.5523 8 12ZM3 18C2.44771 18 2 18.4477 2 19C2 19.5523 2.44771 20 3 20H21C21.5523 20 22 19.5523 22 19C22 18.4477 21.5523 18 21 18H3Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large'">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M1.875 6.0835C1.875 5.3241 2.49061 4.7085 3.25 4.7085H28.75C29.5094 4.7085 30.125 5.3241 30.125 6.0835C30.125 6.84289 29.5094 7.4585 28.75 7.4585H3.25C2.49061 7.4585 1.875 6.84289 1.875 6.0835ZM10.375 16.0002C10.375 15.2408 10.9906 14.6252 11.75 14.6252H28.75C29.5094 14.6252 30.125 15.2408 30.125 16.0002C30.125 16.7596 29.5094 17.3752 28.75 17.3752H11.75C10.9906 17.3752 10.375 16.7596 10.375 16.0002ZM3.25 24.5418C2.49061 24.5418 1.875 25.1574 1.875 25.9168C1.875 26.6762 2.49061 27.2918 3.25 27.2918H28.75C29.5094 27.2918 30.125 26.6762 30.125 25.9168C30.125 25.1574 29.5094 24.5418 28.75 24.5418H3.25Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import AppIcon from './AppIcon.vue';
import { type IconSize, getBoundsByIconSize, getSVGViewboxByIconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
