<template>
  <div :class="$style.langMenuWrapper" @mouseover="showLanguageList()" @mouseleave="hideLanguageList()">
    <app-slot-button :class="$style.langButtonWrapper">
      <icon-language size="small" :filled="true" />
    </app-slot-button>
    <transition name="fade">
      <div v-show="isLanguageListShown" :class="$style.langListWrapper">
        <language-switch-button
          v-for="language in languageList"
          :key="language.value"
          :language="language.value"
          :current-language="appLanguage"
          :class="$style.langListItemButton"
          @click="setLanguage(language.value)"
        />
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import { useAccountPageAnalytics } from '@package/sdk/src/analytics';
import { ref, watch } from 'vue';

import { AppLanguage } from '@/platform/localization/language';
import { AppRoute } from '@/platform/router/routes';

import getAnalyticPageValue from '../../code/kmza/get-kmza-page-value';
import useAppLangSwitcher from '../../platform/localization/use-app-lang-switcher';
import useHostVariation from '../../platform/variation/use-host-variation';
import IconLanguage from '../icons/IconLanguage.vue';
import AppSlotButton from '../ui/AppSlotButton.vue';
import LanguageSwitchButton from './LanguageSwitchButton.vue';

const props = defineProps<{
  appLanguage: AppLanguage;
}>();

const { $analyticSender } = useNuxtApp();
const route = useRoute();
const accountPageAnalytics = useAccountPageAnalytics($analyticSender);

const { languageList } = useHostVariation();
const { setLanguage } = useAppLangSwitcher();

const isLanguageListShown = ref(false);

const showLanguageList = () => {
  isLanguageListShown.value = true;
};

const hideLanguageList = () => {
  isLanguageListShown.value = false;
};

watch(isLanguageListShown, (isShown) => {
  if (!isShown) {
    return;
  }

  accountPageAnalytics.onClickLanguageChange({
    currentLang: props.appLanguage,
    page: getAnalyticPageValue(route.name as AppRoute),
  });
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;

.langMenuWrapper {
  position: relative;
}

.langButtonWrapper {
  display: flex;
  align-items: center;
  margin-right: var(--g-spacing-24);
  width: 40px;
  height: 40px;
  border-radius: var(--g-border-round-12);
  background-color: var(--color-bg-secondary-80);

  &:hover {
    background-color: var(--color-states-bg-secondary-hover-70);
  }
}

.langListWrapper {
  position: absolute;
  top: 64px;
  right: 0;
  padding: var(--g-spacing-24) var(--g-spacing-32);
  border-radius: var(--g-border-round-24);
  background-color: var(--color-bg-secondary);
  min-width: 316px;
  transition: all 0.3s ease-in-out;
}

.langListItemButton {
  padding: var(--g-spacing-6) 0;
  color: var(--color-text-additional);
  transition: color 0.3s ease-in-out;

  @include fonts.WebBody-2;

  &:not(:last-child) {
    margin-bottom: var(--g-spacing-16);
  }

  &:hover {
    color: var(--color-text-primary);
  }

  &:disabled:hover {
    color: var(--color-notheme-text-disabled);
  }
}
</style>
