import useLogger from '@package/logger/src/use-logger';

import { ENDPOINTS } from '@/platform/http';

export default function useParentalControlApi() {
  const app = useNuxtApp();
  const logger = useLogger('use-parental-control-api');

  const setNoChildrenAccess = (): Promise<void> => {
    return app.$http.post(ENDPOINTS.PARENTAL_SET_NO_CHILDREN_ACCESS, null);
  };

  const setChildrenAccess = (code: string): Promise<void> => {
    return app.$http.post(ENDPOINTS.PARENTAL_SET_CHILDREN_ACCESS, { code });
  };

  const checkPinCode = async (code: string): Promise<boolean> => {
    try {
      await app.$http.get(ENDPOINTS.PARENTAL_CHECK_PIN_CODE, { query: { code } });
      return true;
    } catch (error) {
      logger.error('useParentalControlApi#checkpinCode', error);

      return false;
    }
  };

  return { setChildrenAccess, setNoChildrenAccess, checkPinCode };
}
