import type { ApiSubscription, ApiSubscriptionInfoV2, Subscription, SubscriptionInfoV2 } from '@package/sdk/src/api';
import { SubscriptionInfoMapperV2, SubscriptionMapper } from '@package/sdk/src/api';
import type { ApiOfferV2 } from '@package/sdk/src/api/offers/api-offer-v2-type';
import type { ApiOffersV2 } from '@package/sdk/src/api/offers/api-offers-v2-type';
import type { OfferV2 } from '@package/sdk/src/api/offers/offer-v2';
import { OfferMapperV2, OffersMapperV2 } from '@package/sdk/src/api/offers/offer-v2-mapper';
import type { OffersV2 } from '@package/sdk/src/api/offers/offers-v2';

import { ENDPOINTS } from '@/platform/http';
import useHostVariation from '@/platform/variation/use-host-variation';

export function useOffersApi() {
  const app = useNuxtApp();

  const { appLanguage } = useHostVariation();

  const getById = (id: string): Promise<OfferV2 | undefined> => {
    return app.$http
      .get<ApiOfferV2>(ENDPOINTS.OFFERS_ID, {
        params: { id },
        query: {
          is_active: true,
          lang: appLanguage.value,
        },
      })
      .then(OfferMapperV2.map)
      .catch(() => undefined);
  };

  const MAX_OFFERS_LIMIT_COUNT = 3;

  const getAll = (): Promise<OffersV2> => {
    return app.$http
      .get<ApiOffersV2>(ENDPOINTS.OFFERS, {
        query: {
          platform: 'web',
          lang: appLanguage.value,
          limit: MAX_OFFERS_LIMIT_COUNT,
        },
      })
      .then(OffersMapperV2.map);
  };

  const toggleAutoRenew = (id: string, cancelReasonsIds?: string[], reasonText?: string): Promise<Subscription> => {
    return app.$http
      .patch<ApiSubscription>(
        ENDPOINTS.SUBSCRIPTION_TOGGLE_AUTO_RENEW,
        { cancel_reason_ids: cancelReasonsIds, cancel_reason: reasonText || undefined },
        { params: { id } },
      )
      .then(SubscriptionMapper.map);
  };

  // работает только с продовским API
  const cancelSberAutoRenew = (id: string): Promise<Subscription> => {
    return app.$http
      .patch<ApiSubscription>(ENDPOINTS.SUBSCRIPTION_SBER_CANCEL_AUTO_RENEW, {}, { params: { id } })
      .then(SubscriptionMapper.map);
  };

  const getSubscriptionInfo = (id: string): Promise<SubscriptionInfoV2> => {
    return app.$http
      .get<ApiSubscriptionInfoV2>(ENDPOINTS.SUBSCRIPTION_INFO, {
        params: { id },
        query: {
          lang: appLanguage.value,
        },
      })
      .then(SubscriptionInfoMapperV2.map);
  };

  return {
    getAll,
    getById,
    toggleAutoRenew,
    cancelSberAutoRenew,
    getSubscriptionInfo,
  };
}
