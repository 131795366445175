export enum AppRoute {
  AuthEnter = 'auth-enter',
  AuthEnterPassword = 'auth-enter-password',
  AuthRestorePassword = 'auth-restore-password',
  AuthPasswordRestoreEmail = 'auth-password-restore-email',
  AuthPasswordRestorePhone = 'auth-password-restore-phone',
  AuthPasswordLinkExpired = 'auth-password-link-expired',
  InternalServerError = '503',
  Movies = 'movies',
  MovieCard = 'movies-id',
  MoviePlayer = 'movies-id-player',
  MovieFilter = 'movies-f-hd',
  SerialFilter = 'series-f-hd',
  Serials = 'series',
  SerialCard = 'series-id',
  SerialSeasonCard = 'series-id-season-seasonId',
  SerialEpisodeCard = 'series-id-season-seasonId-ep-episodeId',
  SerialPlayer = 'series-id-season-seasonId-ep-episodeId-player',
  Contacts = 'contacts',
  Offers = 'offers',
  OffersPromo = 'offers-promo-promocode',
  OffersPay = 'offers-pay-payload',
  Playlists = 'playlists',
  PlaylistItem = 'playlists-id',
  Search = 'search',
  Auth = 'auth',
  Account = 'account',
  AccountInfo = 'account-info',
  AccountDevices = 'account-devices',
  AccountSubscriptionManage = 'account-subscription-manage',
  AccountSubscriptionInfo = 'account-subscription-info',
  AccountProfiles = 'account-profiles',
  AccountBonuses = 'account-bonuses',
  AccountBonusesReferral = 'account-bonuses-referral',
  AccountBonusesPromocode = 'account-bonuses-promocode',
  AccountLanguage = 'account-language',
  TV = 'tv-channels',
  TVChannel = 'tv-channels-id',
  MyCollection = 'my-collection',
  MyCollectionKinom = 'my-collection-moments',
  MyCollectionContent = 'my-collection-contents',
  Sitemap = 'sitemap',
  RecommendationTechnologies = 'docs-recommendation-technologies',
  PrivacyPolicy = 'docs-privacy-policy',
  UserAgreement = 'docs-user-agreement',
  Referral = 'referral',
  Index = 'index',
  MyChannel = 'my-channel',
  MyChannelMoment = 'my-channel-id',
  ThankYou = 'thank-you',
  Compilations = 'collections',
  CompilationsItem = 'collections-id',
  Download = 'download',
  DownloadMobApp = 'download-mob-app',
  TVCodePage = 'code',
  GoTVCodePage = 'gotv',
  SandboxUiPage = 'sandbox-storybook',
  SandboxIconsPage = 'sandbox-icons',
  SandboxDebugPage = 'sandbox-debug',
  SandboxPlayerPage = 'sandbox-player',
}
