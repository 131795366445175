import { AppVariation } from '@/platform/variation/interfaces';

export default function useVariationVariables() {
  const runtimeConfig = useRuntimeConfig();
  const appVariation = runtimeConfig.public.APP_VARIATION as AppVariation;

  const getFlocktoryId = (variation: AppVariation) => {
    switch (variation) {
      case AppVariation.Ru:
        return '3931';
      default:
        return undefined;
    }
  };

  const getGoogleTagManagerId = (variation: AppVariation) => {
    switch (variation) {
      case AppVariation.Ru:
        return 'GTM-W74KLJR';
      case AppVariation.Am:
        return 'GTM-NZTLDGNQ';
      default:
        return undefined;
    }
  };

  const getGoogleAnalyticsId = (variation: AppVariation) => {
    switch (variation) {
      case AppVariation.Am:
        return 'G-Y2CWNQEQ9N';
      default:
        return undefined;
    }
  };

  const getAppsFlyerId = (variation: AppVariation) => {
    switch (variation) {
      case AppVariation.Ru:
      case AppVariation.Am:
        return '60c6d80f-ed60-4b5d-b458-08326ff1c206';
      default:
        return undefined;
    }
  };

  const getYandexMetricaId = (variation: AppVariation) => {
    switch (variation) {
      case AppVariation.Ru:
        return '84842932';
      case AppVariation.Am:
        return '94437248';
      default:
        return undefined;
    }
  };

  const getVKId1 = (variation: AppVariation) => {
    switch (variation) {
      case AppVariation.Ru:
        return 'VK-RTRG-1158759-cEfPx';
      default:
        return undefined;
    }
  };

  const getVKId2 = (variation: AppVariation) => {
    switch (variation) {
      case AppVariation.Ru:
        return 'VK-RTRG-1638140-hKVSH';
      default:
        return undefined;
    }
  };

  const getTopmailId = (variation: AppVariation) => {
    switch (variation) {
      case AppVariation.Ru:
        return 3259859;
      case AppVariation.Am:
        return 3522935;
      default:
        return undefined;
    }
  };

  return {
    appVariation,
    getGoogleAnalyticsId,
    getAppsFlyerId,
    getTopmailId,
    getVKId1,
    getVKId2,
    getYandexMetricaId,
    getGoogleTagManagerId,
    getFlocktoryId,
  };
}
