import { ProcessingMapper } from '../base/processing-mapper';
import { ApiSitemaps } from './seo-types/sitemaps';
import { SitemapsItemMapper } from './sitemaps-item';
import { Sitemaps } from './types/common-sitemaps';

export class SitemapsMapper {
  static map({ pages, allowed_types }: ApiSitemaps): Sitemaps {
    return ProcessingMapper.process({
      pages: pages ? SitemapsItemMapper.mapMany(pages) : [],
      allowedTypes: allowed_types || [],
    });
  }
}
