import type { AnyFn } from '@vueuse/core';
import { isClient } from '@vueuse/core';

const store = new WeakMap();

export async function once<T>(fn: () => Promise<T>): Promise<T> {
  const isFulfilled = store.has(fn);

  if (!isFulfilled) {
    const value = await fn();
    store.set(fn, value);
    return value;
  } else {
    return store.get(fn);
  }
}

export function executeWithSafeClientContext(func: AnyFn) {
  if (!isClient) {
    return;
  }

  return Reflect.apply(func, undefined, []);
}

export function pick<T extends Record<string, any>, K extends keyof T>(obj: T, keysArray: K[]): Partial<T> {
  const newObj: Partial<T> = {};
  for (const key of keysArray) {
    if (key in obj) {
      newObj[key] = obj[key];
    }
  }

  return newObj;
}
