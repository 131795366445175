import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAnalyticsStore = defineStore('analytics-store', () => {
  const cityAdsClickId = ref('');

  return {
    cityAdsClickId,
  };
});
