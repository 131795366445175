import useLogger from '@package/logger/src/use-logger';
import type { JwtPayload } from 'jwt-decode';
import jwtDecode from 'jwt-decode';

export type AuthTokenPayload = JwtPayload & {
  user_id: string;
  profile_id: string;
  visitor_id: string;
};

export function useJwtDecoder() {
  const logger = useLogger('use-jwt-decoder');

  const decode = (token: string): AuthTokenPayload | undefined => {
    try {
      return jwtDecode<AuthTokenPayload>(token);
    } catch (err) {
      logger.error('Unexpected situation: Bad JWT token.');
    }
  };

  return { decode };
}
