import type { MaybeComputedRef, UseStorageOptions } from '@vueuse/core';
import { useSessionStorage } from '@vueuse/core';

export enum SessionStorageKey {
  SkipOfferRedirect = 'viju.skip_offer_redirect',
  LastOpenContentId = 'viju.last_open_content_id',
  IndexedDBCacheEnabled = 'viju.indexeddb_cached_enabled',
}

export default function useAppSessionStorage<T>(
  key: SessionStorageKey,
  initialValue: MaybeComputedRef<T>,
  options?: UseStorageOptions<T>,
) {
  return useSessionStorage<T>(key, initialValue, options);
}
