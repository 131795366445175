<template>
  <div
    ref="modalWrapperRef"
    :class="{
      [$style.wrapper]: true,
      [$style.wrapperPadding]: withPadding,
    }"
    :style="{
      maxWidth: props.maxWidth ? maxWidthInPx : undefined,
    }"
    tabindex="0"
    @click="onClick"
    @keydown.esc="closeModal"
  >
    <app-slot-button
      v-if="hasModalCloseIcon"
      :class="$style.icon"
      :title="$t('ariaLabel.modal.closeModal')"
      :aria-label="$t('ariaLabel.modal.closeModal')"
      :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Common, 'closeModal')"
      @click="closeModal"
    >
      <icon-close size="medium" :class="$style.svgIcon" />
    </app-slot-button>

    <slot />
  </div>
</template>

<script lang="ts" setup>
import { toPixel } from '@package/sdk/src/core';
import { storeToRefs } from 'pinia';

import { useLayoutStore } from '@/stores/use-layout-store';

import IconClose from '../icons/IconClose.vue';
import AppSlotButton from '../ui/AppSlotButton.vue';

interface Props {
  maxWidth?: number;
  minHeight?: number;
  withPadding?: boolean;
  isLoading?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  maxWidth: 360,
  minHeight: 200,
  withPadding: true,
  isLoading: true,
});

const layoutStore = useLayoutStore();

const { hasModalCloseIcon } = storeToRefs(layoutStore);

const modalWrapperRef = ref<HTMLDivElement>();

const maxWidthInPx = computed(() => toPixel(props.maxWidth));
const minHeightInPx = computed(() => toPixel(props.minHeight));

const closeModal = () => {
  if (hasModalCloseIcon.value) {
    layoutStore.setCurrentModalName(null);
  }
};

const onClick = (e: Event) => {
  e.stopPropagation();
};

onMounted(() => {
  modalWrapperRef.value?.focus();
});
</script>

<style lang="scss" module>
@use '../../assets/breakpoints' as breakpoints;

.wrapper {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 360px;
  min-height: v-bind(minHeightInPx);
  height: fit-content;
  border-radius: 24px;
  background-color: var(--color-bg-modal);
  overflow: auto;
  outline: none;
}

.wrapperPadding {
  padding: var(--g-spacing-32);
}

.svgIcon {
  color: var(--color-text-secondary);
}

.icon {
  position: absolute;
  top: var(--g-spacing-20);
  right: var(--g-spacing-20);
  z-index: 2;
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
  justify-content: center;

  &:hover {
    .svgIcon {
      color: var(--color-text-primary);
    }
  }
}

@include breakpoints.max-width-800 {
  .wrapper {
    width: 100%;
    // пришлось сделать это из-за модалок родительского контроля
    // т.к изначально она на всю ширину из-за того, что computed еще не посчитался
    max-width: 100% !important;
    height: fit-content;
    max-height: 90%;
    border-radius: 24px 24px 0 0;
    overflow: auto;
    margin-bottom: 0;
  }
}

@include breakpoints.max-width-540 {
  .wrapperPadding {
    padding: 30px var(--g-spacing-20);
  }
}
</style>
