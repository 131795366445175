import { ProcessingMapper } from '../base/processing-mapper';
import { ApiPlaylists, Playlist } from './playlists';

export class PlaylistsMapper {
  static map(payload: Playlist): Playlist {
    return ProcessingMapper.process({
      ...payload,
      icon: payload.icon.replace('.png', '.svg'),
    });
  }

  static mapMany(payload: ApiPlaylists): Playlist[] {
    return ProcessingMapper.process(payload.playlists.map(PlaylistsMapper.map));
  }
}
