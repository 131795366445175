export default {
  account: () => import("/srv/app/apps/web/src/layouts/account.vue"),
  auth: () => import("/srv/app/apps/web/src/layouts/auth.vue"),
  catalog: () => import("/srv/app/apps/web/src/layouts/catalog.vue"),
  "content-layout": () => import("/srv/app/apps/web/src/layouts/content-layout.vue"),
  default: () => import("/srv/app/apps/web/src/layouts/default.vue"),
  "my-channel": () => import("/srv/app/apps/web/src/layouts/my-channel.vue"),
  "my-collection": () => import("/srv/app/apps/web/src/layouts/my-collection.vue"),
  "player-layout": () => import("/srv/app/apps/web/src/layouts/player-layout.vue"),
  sandbox: () => import("/srv/app/apps/web/src/layouts/sandbox.vue")
}