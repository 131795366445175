<template>
  <div :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.ParentalModal, 'setPinCodeModal')">
    <modal-title :title="$t('parentalControl.modal.setPinCode')" />

    <modal-description :description="$t('parentalControl.modal.createPinCode')" />
    <pin-code-input
      :model-value="pinCode"
      autofocus
      :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.ParentalModal, 'setPinCodeInput')"
      :class="$style.pinCodeInput"
      @update:model-value="updatePinCode"
      @submit="onCodeSubmit"
    />
    <div :class="$style.buttons">
      <app-button
        v-if="isLogoutButtonShown"
        :class="$style.logoutButton"
        variation="link-alert"
        :text="$t('auth.accountLogout')"
        :aria-label="$t('ariaLabel.parentalControl.logout')"
        :title="$t('ariaLabel.parentalControl.logout')"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.ParentalModal, 'logoutButton')"
        @click="onLogoutClick()"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useParentalControlAnalytics } from '@package/sdk/src/analytics';
import { storeToRefs } from 'pinia';
import { onMounted, onUnmounted } from 'vue';

import useAuthActions from '../../code/auth/use-auth-actions';
import { useLayoutStore } from '../../stores/use-layout-store';
import useParentalControlStore from '../../stores/use-parental-control-store';
import ModalDescription from '../modals/ModalDescription.vue';
import ModalTitle from '../modals/ModalTitle.vue';
import AppButton from '../ui/AppButton.vue';
import PinCodeInput from './PinCodeInput.vue';

defineProps<{
  isLogoutButtonShown: boolean;
  isRequestLoading: boolean;
}>();

const emit = defineEmits<{
  (e: 'set', code: string): void;
}>();

const { $analyticSender } = useNuxtApp();
const { onAutoParentalCodeCreateDisplayed } = useParentalControlAnalytics($analyticSender);
const { logoutUser } = useAuthActions();

const parentalControlStore = useParentalControlStore();

const { pinCode } = storeToRefs(parentalControlStore);
const layoutStore = useLayoutStore();

const onLogoutClick = () => {
  logoutUser();
  layoutStore.setCurrentModalName(null);
};

const updatePinCode = (value: string) => {
  parentalControlStore.setPinCode(value);
};

const onCodeSubmit = () => {
  emit('set', pinCode.value);
};

onMounted(() => {
  onAutoParentalCodeCreateDisplayed();
});

onUnmounted(() => {
  parentalControlStore.setIsLogoutButtonShown(true);
});
</script>

<style lang="scss" module>
.buttons {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.logoutButton {
  margin-top: var(--g-spacing-8);
}

.pinCodeInput {
  margin: var(--g-spacing-16) 0;
}
</style>
