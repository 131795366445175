<template>
  <app-modal-wrapper :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'logoutModalWrapper')">
    <modal-title :class="$style.title" :title="$t('lkPage.logout.modalTitle')" />
    <modal-description :description="$t('lkPage.logout.warningText')" />

    <div :class="$style.buttons">
      <app-button
        :aria-label="$t('ariaLabel.logoutModal.cancelLogout')"
        :title="$t('ariaLabel.logoutModal.cancelLogout')"
        variation="button-primary"
        :text="$t('lkPage.logout.preventLogout')"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'logoutStayButton')"
        @click="onCancelLogout()"
      />
      <app-button
        variation="link-alert"
        :aria-label="$t('ariaLabel.logoutModal.logout')"
        :title="$t('ariaLabel.logoutModal.logout')"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'logoutLeaveButton')"
        :text="$t('lkPage.logout.applyLogout')"
        @click="logout()"
      />
    </div>
  </app-modal-wrapper>
</template>

<script lang="ts" setup>
import useLogger from '@package/logger/src/use-logger';
import { useAccountPageAnalytics } from '@package/sdk/src/analytics';

import useAuthActions from '@/code/auth/use-auth-actions';
import ModalDescription from '@/components/modals/ModalDescription.vue';
import ModalTitle from '@/components/modals/ModalTitle.vue';
import AppButton from '@/components/ui/AppButton.vue';
import { useLayoutStore } from '@/stores/use-layout-store';

const { $analyticSender } = useNuxtApp();
const layoutStore = useLayoutStore();
const { logoutUser: doLogout } = useAuthActions();
const logger = useLogger('LogoutModal.vue');
const accountPageAnalytics = useAccountPageAnalytics($analyticSender);

const logout = async () => {
  try {
    await doLogout();
  } catch (e) {
    logger.error(e);
  } finally {
    accountPageAnalytics.onClickExitConfirm();
    closeModal();
  }
};

const onCancelLogout = () => {
  accountPageAnalytics.onClickExitCancel();
  closeModal();
};

const closeModal = () => {
  layoutStore.setCurrentModalName(null);
};
</script>

<style lang="scss" module>
.title {
  padding-right: var(--g-spacing-16);
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--g-spacing-8);
  margin-top: var(--g-spacing-24);
}
</style>
