<template>
  <textarea
    :placeholder="placeholder"
    :class="$style.textarea"
    :maxlength="maxLength"
    :value="modelValue"
    @input="onInput"
  ></textarea>
</template>

<script>
export default {
  props: {
    modelValue: { type: String },
    placeholder: { type: String },
    maxLength: { type: Number },
  },
  emits: ['update:modelValue'],
  methods: {
    onInput(event) {
      this.$emit('update:modelValue', event.target?.value);
    },
  },
};
</script>

<style lang="scss" module>
.textarea {
  padding: var(--g-spacing-12);
  width: 100%;
  height: 100%;
  border-radius: var(--g-border-round-12);
  border: none;
  background-color: var(--color-bg-secondary);
  outline: none;
  resize: vertical;
  color: var(--color-text-primary);
}
</style>
