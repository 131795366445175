import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "64",
  height: "64",
  fill: "none",
  viewBox: "0 0 64 64"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<path fill=\"#0A1E1F\" fill-rule=\"evenodd\" d=\"M10 12a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H11a1 1 0 0 1-1-1z\" clip-rule=\"evenodd\"></path><path fill=\"#0A1E1F\" fill-rule=\"evenodd\" d=\"M8 5a4 4 0 0 0-4 4v46a4 4 0 0 0 4 4h48a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4zm3 14a1 1 0 0 0-1 1v32a1 1 0 0 0 1 1h42a1 1 0 0 0 1-1V20a1 1 0 0 0-1-1z\" clip-rule=\"evenodd\"></path><circle cx=\"52\" cy=\"13\" r=\"2\" fill=\"#00C7B2\"></circle><circle cx=\"45\" cy=\"13\" r=\"2\" fill=\"#00C7B2\"></circle><path fill=\"#00C7B2\" fill-rule=\"evenodd\" d=\"M32.548 27.255a2.29 2.29 0 0 1 1.97-1.134c1.266 0 2.293 1.043 2.293 2.33 0 1.285-1.027 2.329-2.293 2.329-.838 0-1.57-.456-1.97-1.135a2.29 2.29 0 0 1-1.97 1.135c-1.266 0-2.293-1.044-2.293-2.33s1.027-2.329 2.294-2.329c.837 0 1.57.456 1.97 1.134m-15.381 4.333h2.942l2.991 6.43 3.042-6.43h2.792l-4.587 10.178h-2.543zm14.857 0h-2.642v10.178h2.642zm3.89 0h-2.692L33.197 42.5c-.175 1.291-1.222 1.24-1.222 1.24h-2.393v2.38h2.742a4 4 0 0 0 1.346-.303s.664-.274 1.147-.71c1.207-1.088 1.112-3.113 1.097-3.341zm.996 0h2.693v6.178c.02.157.114.825.698 1.316.518.44 1.097.456 1.296.456.19 0 .743 0 1.261-.395.689-.527.773-1.342.783-1.479v-6.076h2.693v6.785s-.25 2.836-3.042 3.494a8.6 8.6 0 0 1-1.366.162 8.8 8.8 0 0 1-2.323-.213 4 4 0 0 1-1.352-.835 4.1 4.1 0 0 1-1.042-1.595s-.15-.253-.299-1.52z\" clip-rule=\"evenodd\"></path>", 5)
  ])))
}
export default { render: render }