import type { ApiVpnStatus, VpnStatus } from '@package/sdk/src/api';
import { VpnNoticeMapper } from '@package/sdk/src/api';
import { useNuxtApp } from 'nuxt/app';

import { ENDPOINTS } from '@/platform/http';

export function useVpnNoticeApi() {
  const { $http } = useNuxtApp();

  const checkVpnStatus = async (): Promise<VpnStatus> => {
    try {
      return await $http.get<ApiVpnStatus>(ENDPOINTS.VPN_NOTICE).then(VpnNoticeMapper.map);
    } catch (error) {
      return {
        possibleVpn: false,
      };
    }
  };

  return { checkVpnStatus };
}
