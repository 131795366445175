import SVGDzenLogo from '../../../static/images/logos/dzen.svg?raw';
import SVGOkLogo from '../../../static/images/logos/ok.svg?raw';
import SVGTelegramLogo from '../../../static/images/logos/telegram.svg?raw';
import SVGVkLogo from '../../../static/images/logos/vk.svg?raw';
import type { ICompanyInformation, ISocialMediaLink } from './variation-types';
import { SocialMediaType } from './variation-types';

const TECH_SUPPORT_EMAIL = 'help@viju.ru';
const TECH_SUPPORT_PHONE = '8 800 775-60-70';
const OFFICE_ADDRESS = '123242, г. Москва, ул. Зоологическая, д.1. стр.1';
const BUSINESS_CENTER = '';

const SocialMediaLink = {
  Telegram: 'https://t.me/viju_kino',
  TelegramBot: 'https://t.me/vijusupport_bot',
  Vk: 'https://vk.me/viju_kino',
  Ok: 'https://ok.ru/vijukino',
  Dzen: 'https://dzen.ru/viju',
};

export const ruCompanyInformation: ICompanyInformation = {
  supportEmail: TECH_SUPPORT_EMAIL,
  contacts: [
    {
      title: 'Email:',
      value: TECH_SUPPORT_EMAIL,
    },
    {
      title: 'Колл-центр:',
      value: TECH_SUPPORT_PHONE,
    },
    {
      title: 'Офис:',
      value: '+7 495 225-73-01',
    },
    {
      title: 'Факс:',
      value: '+7 495 231-20-52',
    },
  ],
  socials: SocialMediaLink,
  officeAddress: OFFICE_ADDRESS,
  businessCenter: BUSINESS_CENTER,
  workTime: 'Понедельник — пятница с 9:00 до 18:00',
  companyInformation: ['ООО «Виасат Глобал»', 'ИНН: 7717567251', 'ОГРН: 5067746471766'],
};

export const socialMediaLinks: ISocialMediaLink[] = [
  {
    name: SocialMediaType.Telegram,
    icon: SVGTelegramLogo,
    href: SocialMediaLink.Telegram,
  },
  {
    name: SocialMediaType.Vk,
    icon: SVGVkLogo,
    href: SocialMediaLink.Vk,
  },
  {
    name: SocialMediaType.Ok,
    icon: SVGOkLogo,
    href: SocialMediaLink.Ok,
  },
  {
    name: SocialMediaType.Dzen,
    icon: SVGDzenLogo,
    href: SocialMediaLink.Dzen,
  },
];
