import { ProcessingMapper } from '../base/processing-mapper';
import { ApiVpnStatus, VpnStatus } from './vpn-types';

export class VpnNoticeMapper {
  static map({ possible_vpn }: ApiVpnStatus): VpnStatus {
    return ProcessingMapper.process({
      possibleVpn: possible_vpn,
    });
  }
}
