import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "64",
  height: "64",
  fill: "none",
  viewBox: "0 0 64 64"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#0A1E1F",
      "fill-rule": "evenodd",
      d: "M8 5a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h48a4 4 0 0 0 4-4V9a4 4 0 0 0-4-4zm3 6a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1zm36 2a2 2 0 1 1-4 0 2 2 0 0 1 4 0m5 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4M8 24a4 4 0 0 0-4 4v8a4 4 0 0 0 4 4h48a4 4 0 0 0 4-4v-8a4 4 0 0 0-4-4zm3 6a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1zm36 2a2 2 0 1 1-4 0 2 2 0 0 1 4 0m5 2a2 2 0 1 0 0-4 2 2 0 0 0 0 4M4 47a4 4 0 0 1 4-4h48a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4H8a4 4 0 0 1-4-4zm6 3a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H11a1 1 0 0 1-1-1zm35 3a2 2 0 1 0 0-4 2 2 0 0 0 0 4m9-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0",
      "clip-rule": "evenodd"
    }, null, -1),
    _createElementVNode("path", {
      fill: "#00C7B2",
      "fill-rule": "evenodd",
      d: "M11 11a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1zm34 4a2 2 0 1 0 0-4 2 2 0 0 0 0 4m9-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0m0 19a2 2 0 1 1-4 0 2 2 0 0 1 4 0m-7 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0m5 21a2 2 0 1 0 0-4 2 2 0 0 0 0 4m-5-2a2 2 0 1 1-4 0 2 2 0 0 1 4 0M10 31a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1H11a1 1 0 0 1-1-1zm1 18a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1z",
      "clip-rule": "evenodd"
    }, null, -1)
  ])))
}
export default { render: render }