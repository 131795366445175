<template>
  <client-only>
    <teleport v-if="isMounted && labelContainerEl" :to="labelContainerEl">
      <app-label
        v-show="isLabelShown"
        :ref="(comp) => setLabelElement(comp?.$el)"
        :class="[labelClass, $style.label]"
        position="absolute"
        :left="position.left"
        :top="position.top"
        :right="position.right"
      >
        <slot></slot>
      </app-label>
    </teleport>
  </client-only>
</template>

<script lang="ts" setup>
import useLogger from '@package/logger/src/use-logger';
import { isDefined } from '@package/sdk/src/core';
import { isClient, refDebounced, useMounted, useScroll, watchOnce } from '@vueuse/core';
import type { Ref } from 'vue';
import { computed, inject, nextTick, onBeforeUnmount, ref, toRefs, watch } from 'vue';

import calculateLabelPosition from '@/code/layout/calculate-label-position';
import useMobile from '@/platform/layout/use-mobile';
import useMousePresenceDetection from '@/platform/layout/use-mouse-presence-detection';

import AppLabel from './AppLabel.vue';

type LabelPosition = {
  left?: number;
  top: number;
  right?: number;
};

const props = withDefaults(
  defineProps<{
    focusEl: HTMLElement;
    isDisabled?: boolean;
    labelClass?: string;
    focusOnLabel?: boolean;
    isForcedShown?: boolean | undefined;
    labelPosition: 'left' | 'right';
  }>(),
  {
    focusOnLabel: true,
    isForcedShown: undefined,
  },
);

const labelContainerEl = inject('labelEl') as Ref<HTMLElement>;
const labelEl = ref<HTMLElement>();
const position = ref<LabelPosition>({ top: 0, left: 0, right: 0 });
const logger = useLogger('NotificationAppLabel');

const { focusEl } = toRefs(props);
const { isMouseIn: isMouseInFocus } = useMousePresenceDetection(focusEl);
const { isScrolling } = useScroll(isClient ? window : undefined);

const isMounted = useMounted();

const isMobile = useMobile();
const isMouseInDebounced = refDebounced(isMouseInFocus, 100);

watchOnce(isScrolling, () => {
  if (!labelEl.value) {
    return;
  }

  labelEl.value.style.display = 'none';
});

const isLabelMouseIn = ref(false);

const onLabelPointerIn = () => {
  isLabelMouseIn.value = true;
};

const onLabelPointerLeave = () => {
  isLabelMouseIn.value = false;
};

const setLabelElement = (el: HTMLElement) => {
  if (!el) {
    return;
  }

  labelEl.value = el;

  if (props.focusOnLabel) {
    el.onpointerenter = onLabelPointerIn;
    el.onpointerleave = onLabelPointerLeave;
  }
};

const isLabelShown = computed(() => {
  if (isDefined(props.isForcedShown)) {
    return props.isForcedShown;
  }

  if (props.isDisabled || isMobile) {
    return false;
  }

  return isMouseInDebounced.value || isLabelMouseIn.value;
});

onBeforeUnmount(() => {
  if (labelEl.value) {
    labelEl.value.onpointerleave = null;
    labelEl.value.onpointerenter = null;
  }
});

watch(isLabelShown, async (isShown) => {
  if (!isShown) {
    return;
  }

  if (!props.focusEl || !labelEl.value) {
    return logger.warn('Expect focusEl | labelEl to be defined');
  }

  await nextTick();

  position.value = calculateLabelPosition(props.labelPosition)(props.focusEl);
});
</script>

<style lang="scss" module>
.label {
  :global {
    animation: zoomIn;
    animation-duration: 0.1s;
    animation-iteration-count: 1;
  }
}
</style>
