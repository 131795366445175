export enum AnalyticEventType {
  ADRIVER,
  WEBORAMA,
  CITY_ADS,
}

export function getAnalyticApplicationType(eventType: AnalyticEventType, eventLabel: string) {
  if (eventType === AnalyticEventType.ADRIVER) {
    return `application/x-adriver_${eventLabel}`;
  }

  if (eventType === AnalyticEventType.WEBORAMA) {
    return `application/x-weborama_${eventLabel}`;
  }

  if (eventType === AnalyticEventType.CITY_ADS) {
    return `application/x-city-ads_${eventLabel}`;
  }

  return '';
}
