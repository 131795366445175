import { AnalyticEventNameNew } from '@package/sdk/src/analytics';
import { KeyboardHandler, setCSSVariable, toPixel } from '@package/sdk/src/core';
import { isClient, useWindowSize } from '@vueuse/core';
import type { H3Event } from 'h3';
import type { Emitter } from 'mitt';
import mitt from 'mitt';
import { watch } from 'vue';

import type { WidgetId } from '@/code/captcha/smart-captcha';
import { ContentCacheManager } from '@/code/content/content-cache-manager';
import { ElementTestIdentifierScope, getTestElementIdentifier } from '@/code/e2e-testing/element-test-identifier';
import type { CommonMoment } from '@/code/moments/moments';
import { LocalWatchingItemDatabase } from '@/code/watching-items/local-watching-item-database';
import AppSlider from '@/components/app-slider/AppSlider.vue';
import AuthLoginInput from '@/components/auth/AuthLoginInput.vue';
import AuthPromocodeInput from '@/components/auth/AuthPromocodeInput.vue';
import BonusDisableAutoRenewalModal from '@/components/lk/modals/BonusDisableAutoRenewalModal.vue';
import ConfirmDisableAutoRenewalModal from '@/components/lk/modals/ConfirmDisableAutoRenewalModal.vue';
import DeleteAccountConfirmModal from '@/components/lk/modals/DeleteAccountConfirmModal.vue';
import DeleteAccountHasActiveSubscriptionModal from '@/components/lk/modals/DeleteAccountHasActiveSubscriptionModal.vue';
import DeleteAccountSuccessModal from '@/components/lk/modals/DeleteAccountSuccessModal.vue';
import DeleteDeviceModal from '@/components/lk/modals/DeleteDeviceModal.vue';
import LogoutModal from '@/components/lk/modals/LogoutModal.vue';
import ReasonsDisableAutoRenewalModal from '@/components/lk/modals/ReasonsDisableAutoRenewalModal.vue';
import TrialAvailableUserAutoRenewalModal from '@/components/lk/modals/TrialAvailableUserAutoRenewalModal.vue';
import CloudpaymentsWidgetModal from '@/components/modals/CloudpaymentsWidgetModal.vue';
import ExploreMyChannelModal from '@/components/modals/ExploreMyChannelModal.vue';
import MyChannelModal from '@/components/modals/MyChannelModal.vue';
import ParentalControlModal from '@/components/modals/ParentalControlModal.vue';
import ParentalControlProfileModal from '@/components/modals/ParentalControlProfileModal.vue';
import ReferralModal from '@/components/modals/ReferralModal.vue';
import TvPromoModal from '@/components/modals/TvPromoModal.vue';
import WatchAllEpisodesModal from '@/components/modals/WatchAllEpisodesModal.vue';
import PlayerPlaylist from '@/components/player-playlist/PlayerPlaylist.vue';
import AppButton from '@/components/ui/AppButton.vue';
import AppInput from '@/components/ui/AppInput.vue';
import AppLink from '@/components/ui/AppLink.vue';
import AppTitle from '@/components/ui/AppTitle.vue';
import { isMobileDevice } from '@/platform/base/dom';
import { executeWithSafeClientContext } from '@/platform/base/function';
import useEnvironment from '@/platform/environment/use-environment';
import { RouteQuery } from '@/platform/router/query';
import { AppRoute } from '@/platform/router/routes';
import type { BreadcrumbsItem, CurrentModalName } from '@/stores/use-layout-store';

import type { BroadcastChannelPayload } from './broadcast-channel';
import { BroadcastChannelEvent } from './broadcast-channel';

interface CustomBroadcastChannel extends Omit<BroadcastChannel, 'postMessage'> {
  postMessage: <E extends BroadcastChannelEvent>(payload: BroadcastChannelPayload<E>) => void;
}

declare module 'nuxt/app' {
  interface NuxtApp {
    $getTestElementIdentifier: typeof getTestElementIdentifier;
    $toPixel: typeof toPixel;
    $isMobile: boolean;
    $contentCacheManager: ContentCacheManager;
    $ElementTestIdentifierScope: typeof ElementTestIdentifierScope;
    $RouteQuery: typeof RouteQuery;
    $AppRoute: typeof AppRoute;
    $AnalyticEventNameNew: typeof AnalyticEventNameNew;
    $broadcastChannel: CustomBroadcastChannel;
    $keyboardHandler: KeyboardHandler;
    $emitter: Emitter<EmitterEvents>;
    $localWatchingItemDb: LocalWatchingItemDatabase;
  }
}

export type EmitterEvents = {
  'user.registration.complete': void;
  'user.login.complete': void;
  'user.logout.complete': void;
  'user.subscription.created': void;
  'user.profile.updated': void;

  'offer.loadRequest': void;

  'smart-captcha.created': WidgetId;
  'smart-captcha.success': string;
  'smart-captcha.visibility-changed': boolean;

  'app.features.updated': void;
  'app.player.loaded': void;
  'app.modal.created': CurrentModalName;
  'app.modal.disposed': void;
  'app.breadcrumbs.updated': BreadcrumbsItem[];

  'app.modal.delete-device': void;
  'app.modal.my-channel.moments-updated': CommonMoment[];
  'app.modal.my-channel.load-more': void;

  'app.modal.cancelConfirm.firstStep': void;
  'app.modal.parental-control.pin-code-success': void;
};

export default defineNuxtPlugin({
  name: 'global-variables-injector',
  setup(app) {
    const { isRelease } = useEnvironment();
    const req = useRequestEvent();
    const isMobile = isMobileDevice(req as unknown as H3Event);
    const contentCacheManager = new ContentCacheManager();

    const localWatchingItemDb = new LocalWatchingItemDatabase();

    const emitter = mitt<EmitterEvents>();

    const keyboardHandler = new KeyboardHandler();
    executeWithSafeClientContext(() => keyboardHandler.init({ keyup: true, keydown: false }));

    app.vueApp.component('AppTitle', AppTitle);
    app.vueApp.component('AuthLoginInput', AuthLoginInput);
    app.vueApp.component('AuthPromocodeInput', AuthPromocodeInput);
    app.vueApp.component('AppButton', AppButton);
    app.vueApp.component('AppLink', AppLink);
    app.vueApp.component('AppInput', AppInput);

    app.vueApp.component('ParentalControlModal', ParentalControlModal);
    app.vueApp.component('ParentalControlProfileModal', ParentalControlProfileModal);
    app.vueApp.component('ReferralModal', ReferralModal);
    app.vueApp.component('TvPromoModal', TvPromoModal);
    app.vueApp.component('CloudpaymentsWidgetModal', CloudpaymentsWidgetModal);
    app.vueApp.component('TrialAvailableUserAutoRenewalModal', TrialAvailableUserAutoRenewalModal);
    app.vueApp.component('DeleteDeviceModal', DeleteDeviceModal);
    app.vueApp.component('LogoutModal', LogoutModal);
    app.vueApp.component('ConfirmDisableAutoRenewalModal', ConfirmDisableAutoRenewalModal);
    app.vueApp.component('BonusDisableAutoRenewalModal', BonusDisableAutoRenewalModal);
    app.vueApp.component('ReasonsDisableAutoRenewalModal', ReasonsDisableAutoRenewalModal);
    app.vueApp.component('ExploreMyChannelModal', ExploreMyChannelModal);
    app.vueApp.component('DeleteAccountConfirmModal', DeleteAccountConfirmModal);
    app.vueApp.component('DeleteAccountHasActiveSubscriptionModal', DeleteAccountHasActiveSubscriptionModal);
    app.vueApp.component('DeleteAccountSuccessModal', DeleteAccountSuccessModal);
    app.vueApp.component('WatchAllEpisodesModal', WatchAllEpisodesModal);
    app.vueApp.component('MyChannelModal', MyChannelModal);

    app.vueApp.component('AppSlider', AppSlider);
    app.vueApp.component('PlayerPlaylist', PlayerPlaylist);

    const getNullTestIdentifier = () => undefined;

    const { width, height } = useWindowSize();

    watch(width, (val) => setCSSVariable('app-width', toPixel(val)), { immediate: isClient });
    watch(height, (val) => setCSSVariable('app-height', toPixel(val)), { immediate: isClient });

    return {
      provide: {
        getTestElementIdentifier: isRelease ? getNullTestIdentifier : getTestElementIdentifier,
        emitter,
        keyboardHandler,
        contentCacheManager,
        ElementTestIdentifierScope,
        AppRoute,
        RouteQuery,
        AnalyticEventNameNew,
        toPixel,
        isMobile,
        localWatchingItemDb,
      },
    };
  },
});
