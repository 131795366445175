import useLogger from '@package/logger/src/use-logger';
import type {
  Actor,
  Channel,
  Compilation,
  Director,
  Episode,
  Genre,
  Media,
  Moment,
  MomentPlaylist,
  Season,
  Serial,
} from '@package/sdk/src/api';
import { MediaContentType, OpenGraphMetaTag, YandexMetaTag } from '@package/sdk/src/api';
import { coalesce, getFirstElement, isDefined, isFunction } from '@package/sdk/src/core';
import type { Meta } from '@unhead/vue';
import { callWithNuxt } from 'nuxt/app';
import { joinURL } from 'ufo';
import { computed } from 'vue';

import { AppLanguage } from '@/platform/localization/language';
import useLocale from '@/platform/localization/use-locale';
import { AppRoute } from '@/platform/router/routes';
import { useRouteUtils } from '@/platform/router/use-route-utils';
import useHostVariation from '@/platform/variation/use-host-variation';

import useFetchCatalogContent from '../content/use-fetch-catalog-content';

const NO_INDEX_ROBOTS_PAGES = [
  AppRoute.Account,
  AppRoute.AccountInfo,
  AppRoute.AccountDevices,
  AppRoute.AccountProfiles,
  AppRoute.AccountSubscriptionManage,
  AppRoute.AccountSubscriptionInfo,
  AppRoute.AccountBonuses,
  AppRoute.AccountLanguage,
  AppRoute.Auth,
  AppRoute.AuthEnter,
  AppRoute.AuthPasswordRestoreEmail,
  AppRoute.AuthPasswordRestorePhone,
  AppRoute.AuthPasswordLinkExpired,
  AppRoute.AuthRestorePassword,
  AppRoute.AuthEnterPassword,
  AppRoute.Search,
  AppRoute.TVCodePage,
  AppRoute.GoTVCodePage,
  AppRoute.SandboxPlayerPage,
  AppRoute.SandboxUiPage,
  AppRoute.SandboxIconsPage,
  AppRoute.SandboxDebugPage,
];

const hrefs = [
  { lang: 'ru', base: 'https://viju.ru/' },
  { lang: 'ru-am', base: 'https://viju.am/' },
];

const getHrefLangMetaTags = (fullPath: string) =>
  hrefs.map((hreflang) => {
    const { lang, base } = hreflang;

    return {
      rel: 'alternate',
      hreflang: lang,
      href: joinURL(base, fullPath),
    };
  });

interface ISeoPageMetatags {
  title: string;
  metatags?: Meta[];
}

export enum SeoMetaPage {
  Home = 'home',
  Contacts = 'contacts',
  TvChannels = 'tv-channels',
  TvChannel = 'tv-channel',
  Playlist = 'playlist',
  Catalog = 'catalog',
  Content = 'content',
  Compilation = 'compilation',
  Compilations = 'compilations',
  MyChannel = 'my-channel',
  MyChannelMoment = 'my-channel-moment',
}

interface ISetupSeoMetadata {
  page: SeoMetaPage;
  args?: any[];
}

interface IUseCatalogMetaTagsOptions {
  contents: Media[];
  title: string;
  isFiltersEnabled: boolean;
}

type PageMode = 'default' | 'season' | 'episode' | 'year';

export function usePageMeta() {
  const meta = useState<Meta[]>('page-meta', () => []);
  const title = useState('page-title', () => '');

  const route = useRoute();
  const app = useNuxtApp();
  const { fullRoute } = useRouteUtils();
  const { hostName, appLanguage } = useHostVariation();
  const { translate } = useLocale();
  const logger = useLogger('use-page-meta');

  const addMetaTags = (metatags: Meta[]) => {
    meta.value = metatags;

    return useHead({ meta: meta.value });
  };

  const setPageMeta = (input: ISeoPageMetatags) => {
    meta.value = input.metatags || [];
    title.value = input.title;

    return useHead({ title: title.value, meta: meta.value });
  };

  const SeoDataMap: Record<SeoMetaPage, (...args: any[]) => ISeoPageMetatags> = {
    [SeoMetaPage.Compilation]: (compilation: Compilation) => {
      const title =
        compilation.seoText?.meta_title || translate('compilationPage.seo.title', { title: compilation.title });
      const description =
        compilation.seoText?.meta_description ||
        translate('compilationPage.seo.description', { title: compilation.title });
      const image = compilation?.posters[0] || '';

      return {
        title,
        metatags: [
          { name: 'description', content: description },
          { property: OpenGraphMetaTag.Type, content: 'website' },
          { property: OpenGraphMetaTag.Title, content: title },
          { property: OpenGraphMetaTag.Description, content: description },
          { property: OpenGraphMetaTag.Image, content: image },
        ],
      };
    },
    [SeoMetaPage.Compilations]: () => {
      const title = translate('compilationsPage.seo.title');
      const description = translate('compilationsPage.seo.description');

      return {
        title,
        metatags: [
          { name: 'description', content: description },
          { property: OpenGraphMetaTag.Type, content: 'website' },
          { property: OpenGraphMetaTag.Title, content: title },
          { property: OpenGraphMetaTag.Description, content: description },
        ],
      };
    },
    [SeoMetaPage.Content]: (content: Media) => {
      const { paramsEpisodeNumber, paramsSeasonNumber } = useFetchCatalogContent();
      const router = useRouter();

      const isMovieContent = content.contentType === MediaContentType.MOVIE;
      const isSerialContent = !isMovieContent;

      const serial = content as Serial;
      const seasons = serial.seasons || [];

      let selectedSeason = seasons?.find((season: Season) => season.number === paramsSeasonNumber.value);
      let selectedEpisode = selectedSeason?.episodes?.find(
        (episode: Episode) => episode.number === paramsEpisodeNumber.value,
      );

      const selectedSeasonPage = router.currentRoute.value.params.seasonId;
      const selectedEpisodePage = router.currentRoute.value.params.episodeId;

      if (paramsSeasonNumber && !selectedSeason) {
        selectedSeason = seasons[0];
        selectedEpisode = seasons[0]?.episodes[0];
      } else if (paramsEpisodeNumber && !selectedEpisode) {
        selectedEpisode = seasons[0]?.episodes[0];
      }

      // Список актеров
      const actors = content.actors || [];
      // Год выпуска
      const year = content.releaseDate ? `${new Date(content.releaseDate).getFullYear()}` : content.year;
      // Режиссеры
      const directors = content.directors || [];
      // Год релиза
      const releaseDate = selectedSeason?.releaseDate || content.releaseDate;
      // Список жанров
      const genres = content.genres || [];

      // Главная картинка
      const poster = selectedSeason?.poster || content.poster;
      // Возрастной лимит
      const ageLimit = selectedSeason?.ageLimit || content.ageLimit || 0;
      // Заголовок на английском
      const titleEn = content.titleEn ? content.titleEn : '';

      const contentType =
        content.contentType === MediaContentType.MOVIE
          ? translate('seo.movie.contentType')
          : translate('seo.serial.contentType');

      const descriptionYear = year
        ? translate('seo.description.parenthesesYear', { contentType, year })
        : translate('seo.parentheses', { contentType });

      const pageMode: PageMode = (() => {
        if (isMovieContent) {
          if (year) {
            return 'year';
          }

          return 'default';
        }

        if (!selectedSeasonPage && !selectedEpisodePage) {
          if (year) {
            return 'year';
          }

          return 'default';
        }

        return selectedEpisodePage ? 'episode' : 'season';
      })();

      const selectedSeasonNumber = selectedSeason?.number || 1;
      const selectedEpisodeNumber = selectedEpisode?.number || 1;

      const selectedSeasonYearNumber = selectedSeason?.year
        ? translate('contentPage.serial.seoTitle.seasonYear', { seasonYear: selectedSeason.year })
        : '';

      const titleCollection: Record<PageMode, string> = {
        season: translate('contentPage.serial.seoTitle.withSeason', {
          title: content.title,
          season: selectedSeasonNumber,
          seasonYear: selectedSeasonYearNumber,
        }),
        episode: translate('contentPage.serial.seoTitle.withSeasonAndEpisode', {
          title: content.title,
          episode: selectedEpisodeNumber,
          season: selectedSeasonNumber,
        }),
        year: isSerialContent
          ? translate('contentPage.serial.seoTitle.withYear', { title: content.title, year })
          : translate('contentPage.movie.seoTitle.withYear', { title: content.title, year }),
        default: isSerialContent
          ? translate('contentPage.serial.seoTitle', { title: content.title })
          : translate('contentPage.movie.seoTitle', { title: content.title }),
      };

      const descriptionCollection: Record<PageMode, string> = {
        season: translate('seo.serial.description.season', {
          title: content.title,
          season: selectedSeasonNumber,
          titleEn,
        }),
        episode: translate('seo.serial.description.episode', {
          title: content.title,
          episode: selectedEpisodeNumber,
          season: selectedSeasonNumber,
          year: descriptionYear,
        }),
        year: isSerialContent
          ? translate('seo.description.unselectedSerial', {
              title: content.title,
              year: descriptionYear,
            })
          : translate('seo.description.unselectedMovie', {
              title: content.title,
              year: descriptionYear,
            }),
        default: isSerialContent
          ? translate('seo.description.unselectedSerial', {
              title: content.title,
              year: descriptionYear,
            })
          : translate('seo.description.unselectedMovie', {
              title: content.title,
              year: descriptionYear,
            }),
      };

      const keywordsCollection: Record<PageMode, string> = {
        season: translate('contentPage.seo.keywordsSeason', {
          title: content.title,
          seasonNumber: selectedSeasonNumber,
        }),
        episode: translate('contentPage.seo.keywordsEpisode', {
          title: content.title,
          seasonNumber: selectedSeasonNumber,
          episodeNumber: selectedEpisodeNumber,
        }),
        default: translate('contentPage.seo.keywordsDefault', { title: content.title, year }),
        year: translate('contentPage.seo.keywordsYear', { title: content.title, year }),
      };

      const metatags: Meta[] = [
        { name: 'description', content: descriptionCollection[pageMode] },
        { property: 'keywords', content: keywordsCollection[pageMode] },
        { property: 'video:release_date', content: releaseDate },
        ...genres.map((genre: Genre) => ({ property: 'video:tag', content: genre.title })),
        ...directors.map((director: Director) => ({ property: 'video:director', content: director.name })),
        ...actors.map((actor: Actor) => ({ property: 'video:actor', content: actor.name })),
        { property: OpenGraphMetaTag.Video, content: hostName + route.fullPath },
        { property: OpenGraphMetaTag.Type, content: isMovieContent ? 'video.movie' : 'video.tv_show' },
        { property: OpenGraphMetaTag.Title, content: titleCollection[pageMode] },
        { property: OpenGraphMetaTag.Description, content: descriptionCollection[pageMode] },
        { property: OpenGraphMetaTag.Image, content: poster },
        { property: YandexMetaTag.UploadDate, content: content.licenseDateStart },
        { property: YandexMetaTag.Adult, content: ageLimit >= 18 ? 'true' : 'false' },
      ];

      if (isSerialContent) {
        const episodeContentTag = coalesce(
          seasons.map((season: Season) =>
            selectedSeason && selectedSeason.number !== season.number
              ? undefined
              : coalesce(
                  season.episodes.map((episode: Episode) =>
                    selectedEpisode && selectedEpisode.number !== episode.number
                      ? undefined
                      : `${season.number}-${episode.number}`,
                  ),
                ).join(', '),
          ),
        ).join(', ');

        metatags.push({
          property: YandexMetaTag.Episode,
          content: episodeContentTag,
        });

        const videoContentDuration = (() => {
          if (isSerialContent) {
            return selectedEpisode?.duration || (selectedSeason || seasons[0])?.episodes[0]?.duration || 0;
          }

          return content.duration;
        })();

        metatags.push({ property: 'video:duration', content: videoContentDuration });
      }

      return {
        title: titleCollection[pageMode],
        metatags,
      };
    },
    [SeoMetaPage.Catalog]: (options: IUseCatalogMetaTagsOptions) => {
      const { contents, title, isFiltersEnabled } = options;

      const keywords = translate('catalogPage.seo.keywords');
      const description = translate('catalogPage.seo.description', { title });
      const image = getFirstElement(contents)?.background;

      const normalizedPageTitle = isFiltersEnabled
        ? translate('catalogPage.seo.titleWithFilters', { title })
        : translate('catalogPage.seo.titleWithoutFilters', { title });

      return {
        title: normalizedPageTitle,
        metatags: [
          { name: 'description', content: description },
          { property: 'keywords', content: keywords },
          { property: OpenGraphMetaTag.Type, content: 'website' },
          { property: OpenGraphMetaTag.Image, content: image },
          { property: OpenGraphMetaTag.Title, content: title },
          { property: OpenGraphMetaTag.Description, content: description },
        ],
      };
    },
    [SeoMetaPage.Playlist]: (playlist: MomentPlaylist) => {
      // мета теги нужны только для плейлистов с типом default
      if (playlist.playlistType !== 'default') {
        return { title: translate('commonPage.seo.defaultTitle'), metatags: [] };
      }

      const firstMoment = getFirstElement(playlist.moments);
      const playlistTitle = playlist.title || firstMoment?.contentTitle || '';
      const playlistYear = firstMoment?.contentYear || '';
      const isMovieContent = playlist.contentType === 'movie';

      const title = isMovieContent
        ? translate('playlistPageItem.seo.titleMovie', { title: playlistTitle })
        : translate('playlistPageItem.seo.titleSerial', { title: playlistTitle });

      const description = isMovieContent
        ? translate('playlistPageItem.seo.descriptionMovie', { title: playlistTitle, year: playlistYear })
        : translate('playlistPageItem.seo.descriptionSerial', { title: playlistTitle, year: playlistYear });

      const keywords = translate('playlistPage.seo.playlist.keywords', { title: playlistTitle });
      const image = playlist.preview;

      return {
        title,
        metatags: [
          { name: 'description', content: description },
          { property: 'keywords', content: keywords },
          { property: OpenGraphMetaTag.Type, content: 'website' },
          { property: OpenGraphMetaTag.Title, content: title },
          { property: OpenGraphMetaTag.Description, content: description },
          { property: OpenGraphMetaTag.Image, content: image },
        ],
      };
    },
    [SeoMetaPage.TvChannels]: (channel: Channel) => {
      const title = translate('tvPage.seo.title');
      const description = translate('tvPage.seo.description');
      const keywords = translate('tvPage.seo.keywords');

      const metatags = [
        { name: 'description', content: description },
        { property: 'keywords', content: keywords },
        { property: OpenGraphMetaTag.Type, content: 'website' },
        { property: OpenGraphMetaTag.Title, content: title },
        { property: OpenGraphMetaTag.Description, content: description },
      ];

      const image = channel?.logo;

      if (image) {
        metatags.push({ property: OpenGraphMetaTag.Image, content: image });
      }

      return {
        title,
        metatags,
      };
    },
    [SeoMetaPage.TvChannel]: (channel: Channel) => {
      const title = translate('tvPageItem.seo.title', { channelTitle: channel.title });
      const description = translate('tvPageItem.seo.description', { channelTitle: channel.title });
      const keywords = translate('tvPageItem.seo.keywords', { channelTitle: channel.title });

      const metatags = [
        { name: 'description', content: description },
        { property: 'keywords', content: keywords },
        { property: OpenGraphMetaTag.Title, content: title },
        { property: OpenGraphMetaTag.Type, content: 'website' },
        { property: OpenGraphMetaTag.Description, content: description },
      ];

      const image = channel?.logo || '';

      if (image) {
        metatags.push({ property: OpenGraphMetaTag.Image, content: image });
      }

      return {
        title,
        metatags,
      };
    },
    [SeoMetaPage.Contacts]: () => {
      return {
        title: translate('contactPage.seo.title'),
        metatags: [
          { name: 'description', content: translate('contactPage.seo.description') },
          { property: OpenGraphMetaTag.Title, content: translate('contactPage.seo.title') },
          { property: OpenGraphMetaTag.Description, content: translate('contactPage.seo.description') },
          { property: OpenGraphMetaTag.Type, content: 'website' },
          { property: OpenGraphMetaTag.Image, content: `https://${hostName}/icons/logo.svg` },
        ],
      };
    },
    [SeoMetaPage.Home]: (firstMoment?: Moment) => {
      const description = translate('mainPage.seo.description');
      const keywords = translate('mainPage.seo.keywords');
      const imageSrc = firstMoment?.contentPoster;

      const metatags = [
        { name: 'description', content: description },
        { property: 'keywords', content: keywords },
        { property: OpenGraphMetaTag.Title, content: translate('commonPage.seo.defaultTitle') },
        { property: OpenGraphMetaTag.Description, content: description },
        { property: OpenGraphMetaTag.Type, content: 'website' },
      ];

      if (imageSrc) {
        metatags.push({ property: OpenGraphMetaTag.Image, content: imageSrc });
      }

      return {
        title: translate('mainPage.seo.title'),
        metatags,
      };
    },
    [SeoMetaPage.MyChannel]: (moment: Moment) => {
      const title = translate('myChannelPage.seo.title');
      const description = translate('myChannelPage.seo.description');
      const ogDescription = translate('myChannelMomentPage.seo.ogDescription', {
        contentTitle: moment.contentTitle,
        momentTitle: moment.title,
      });
      const imageSrc = 'https://viasat-small.cdnvideo.ru/viasat/viju_app/logos/viju_social.png';

      const metatags = [
        { name: 'description', content: description },
        { property: OpenGraphMetaTag.Title, content: title },
        { property: 'title', content: title },
        { property: OpenGraphMetaTag.Description, content: ogDescription },
        { property: OpenGraphMetaTag.Type, content: 'website' },
        { property: OpenGraphMetaTag.SiteName, content: 'viju' },
        { property: OpenGraphMetaTag.Image, content: imageSrc },
        { property: 'twitter:image', content: imageSrc },
      ];

      return {
        title: translate('myChannelPage.seo.defaultTitle'),
        metatags,
      };
    },
    [SeoMetaPage.MyChannelMoment]: (moment: Moment) => {
      const description = translate('myChannelPage.seo.description');
      const ogDescription = translate('myChannelMomentPage.seo.ogDescription', {
        contentTitle: moment.contentTitle,
        momentTitle: moment.title,
      });

      const imageSrc = moment.contentPoster;

      const metatags = [
        { name: 'description', content: description },
        { property: 'title', content: moment.title },
        { property: OpenGraphMetaTag.Title, content: moment.title },
        { property: OpenGraphMetaTag.Description, content: ogDescription },
        { property: OpenGraphMetaTag.Type, content: 'website' },
        { property: OpenGraphMetaTag.SiteName, content: 'VIJU' },
      ];

      if (imageSrc) {
        metatags.push({ property: OpenGraphMetaTag.Image, content: imageSrc });
        metatags.push({ property: 'twitter:image', content: imageSrc });
      }

      return {
        title: translate('myChannelPage.seo.defaultTitle'),
        metatags,
      };
    },
  };

  const setupSeoMetadata = (options: ISetupSeoMetadata) => {
    const { page, args } = options;

    callWithNuxt(app, () => {
      const result = SeoDataMap[page];

      if (isFunction(result)) {
        try {
          const { title, metatags } = Reflect.apply(result, undefined, isDefined(args) ? [...args] : []);

          setPageMeta({ title, metatags });
        } catch (error) {
          logger.error(error);
        }
      }
    });
  };

  const defaultSeoMeta = computed(() => {
    const robotsContent = NO_INDEX_ROBOTS_PAGES.includes(route.name as AppRoute)
      ? 'noindex, nofollow'
      : 'index, follow, max-image-preview:large';

    return [
      { name: OpenGraphMetaTag.Locale, content: appLanguage.value === AppLanguage.RU ? 'ru_RU' : 'en_US' },
      { name: OpenGraphMetaTag.Url, content: fullRoute.value },
      { name: OpenGraphMetaTag.SiteName, content: hostName },
      { name: 'robots', content: robotsContent },
      { name: 'twitter:card', content: 'summary_large_image' },
    ];
  });

  const defaultSeoLinks = computed(() => {
    const links = [
      {
        rel: 'canonical',
        href: fullRoute.value,
      },
    ];

    links.push(...getHrefLangMetaTags(route.fullPath));

    return links;
  });

  const setupDefaultSeoMetadata = () => {
    useHead(() => ({ meta: defaultSeoMeta.value, link: defaultSeoLinks.value }));
  };

  const useHeadRaw: typeof useHead = (input, options) => useHead(input, options);

  return {
    setupSeoMetadata,
    useHeadRaw,
    setupDefaultSeoMetadata,
    setPageMeta,
    addMetaTags,
  };
}
