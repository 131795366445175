<template>
  <app-input
    :model-value="modelValue"
    :placeholder="placeholder"
    type="text"
    :maxlength="14"
    name="promocode"
    :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Auth, 'promocodeInput')"
    @update:model-value="onUpdate"
  />
</template>

<script setup lang="ts">
import AppInput from '../ui/AppInput.vue';

defineProps<{
  modelValue: string;
  placeholder: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const onUpdate = (value: string) => {
  emit('update:modelValue', value);
};
</script>
