import useLogger from '@package/logger/src/use-logger';
import { LikeState } from '@package/sdk/src/api';

import { useLikesApi } from '../likes/use-likes-api';
import type { RemoteLikes } from './likes';

export function useRemoteLikes(): RemoteLikes {
  const api = useLikesApi();
  const logger = useLogger('use-remote-likes');

  const addLike = (id: string) =>
    api
      .postLike(id, LikeState.Like)
      .then(() => true)
      .catch((error: Error) => {
        logger.error(error);
        return false;
      });

  const addDislike = (id: string) =>
    api
      .postLike(id, LikeState.Dislike)
      .then(() => true)
      .catch((error: Error) => {
        logger.error(error);
        return false;
      });

  const removeLike = (id: string) =>
    api
      .postLike(id, LikeState.Cancel)
      .then(() => true)
      .catch((error: Error) => {
        logger.error(error);
        return false;
      });

  const removeDislike = (id: string) =>
    api
      .postLike(id, LikeState.Cancel)
      .then(() => true)
      .catch((error: Error) => {
        logger.error(error);
        return false;
      });

  return {
    context: { addLike, addDislike, removeLike, removeDislike },
  };
}
