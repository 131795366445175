import { FrameController } from './frame-controller';
import { OAuthProviderType, OAuthState } from './o-auth';
import type { OAuthResponse } from './types/o-auth';

function isJsonString(str: string) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

const getPathQueries = (uri: string) => new URLSearchParams(uri.split('?')[1] || '');

export abstract class BaseOAuthProvider {
  abstract type: OAuthProviderType;
  abstract responseQueryName: string;
  protected readonly _frameController: FrameController;

  constructor() {
    this._frameController = new FrameController();
  }

  get _hostName() {
    return window?.location.origin || '';
  }

  get redirectUri() {
    const url = new URL(`${this._hostName}/auth/enter`);
    url.searchParams.set('provider', this.type);
    return url.href;
  }

  public showFrame(callback = (_: OAuthResponse) => {}) {
    if (this._frameController.frame) {
      this._frameController.close();
    }

    this._frameController
      .show(this.createOAuthUri(), 'oAuthPopup')
      .subscribeToReceivedMessage(({ data }: MessageEvent) => {
        if (!isJsonString(data)) {
          return;
        }

        const authResponse = JSON.parse(data) as OAuthResponse;

        if (authResponse?.provider) {
          this._frameController.close();
          callback(authResponse);
        }
      });
  }

  public closeFrame() {
    this._frameController.close();
  }

  public handleOAuthResponseUri(uri = window.location.href) {
    const token = getPathQueries(uri).get(this.responseQueryName) || '';

    const state = token ? OAuthState.Success : OAuthState.Failed;
    const response = { provider: this.type, state, token } as OAuthResponse;
    window.opener.postMessage(JSON.stringify(response), window.location.origin);
  }

  protected abstract createOAuthUri(): string;
}
