import type { RouterConfig } from '@nuxt/schema';
import { isObject } from '@vueuse/core';
import { storeToRefs } from 'pinia';

import { AppRoute } from '@/platform/router/routes';
import { useLayoutStore } from '@/stores/use-layout-store';

const SCROLLABLE_BODY_ELEMENT_ID = '#inner-body';

const routesWithSavingPosition = [
  AppRoute.Movies,
  AppRoute.MovieFilter,
  AppRoute.Serials,
  AppRoute.SerialFilter,
  AppRoute.Index,
];

const routesWithoutSavingPosition = [
  AppRoute.SerialSeasonCard,
  AppRoute.TV,
  AppRoute.TVChannel,
  AppRoute.MovieCard,
  AppRoute.SerialCard,
  AppRoute.Search,
  AppRoute.Compilations,
  AppRoute.CompilationsItem,
];

/**
 * @description
 *
 * Кастомный scrollBehaviour, так как у нас в приложение скролится теперь не body, а <div id="inner-body">
 */
export default <RouterConfig>{
  strict: false,
  scrollBehavior(to, from) {
    const bodyEl = document.querySelector(SCROLLABLE_BODY_ELEMENT_ID);
    const layoutStore = useLayoutStore();
    const { scrollPositionMap } = storeToRefs(layoutStore);
    const hashElement = document.getElementById(to.hash);

    if (hashElement) {
      hashElement.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    if (
      isObject(scrollPositionMap?.value) &&
      Reflect.has(scrollPositionMap.value, to.name as AppRoute) &&
      routesWithSavingPosition.includes(to.name as AppRoute)
    ) {
      window.setTimeout(() => bodyEl?.scrollTo(scrollPositionMap.value[to.name as AppRoute]), 300);
      return;
    }

    if (routesWithoutSavingPosition.includes(from.name as AppRoute)) {
      // это хорошо работает с TV-каналами, моментально подтягивает кверху стр с рекомендаций, поиск
      return bodyEl?.scrollTo({ top: 0 });
    }

    // это хорошо работает с главной, каталогом
    return { top: 0 };
  },
};
