import type { ChannelProgram, ContentAccessKind, ContentMoment, Media } from '@package/sdk/src/api';
import { ContentAccessTypes, MediaContentType } from '@package/sdk/src/api';
import { ensureEndSlash } from '@package/sdk/src/core';
import { isClient } from '@vueuse/core';
import normalizeUrl from 'normalize-url';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';

import type { CommonMoment } from '@/code/moments/moments';
import { useOffersStore } from '@/stores/use-offers-store';

import useEnvironment from '../environment/use-environment';
import { AppRoute } from './routes';

const createAppLink = (route: AppRoute, ...paths: string[]): string => `/${route}/${paths.join('/')}`;

interface GetContentRouteOptions {
  isPlayerPage?: boolean;
  episodeId?: number;
  seasonId?: number;
}

export function useRouteUtils() {
  const route = useRoute();
  const headers = useRequestHeaders();
  const { isDev } = useEnvironment();

  const { isActiveSubscription, isPartnerSubscription } = storeToRefs(useOffersStore());

  const createExternalLink = (url: string) => ensureEndSlash(`${fullHostName.value}${url}`);

  const fullHostName = computed(() =>
    normalizeUrl(isClient ? window.location.host : `${headers.host}`, { forceHttps: !isDev }),
  );

  const fullRoute = computed(() =>
    route.path === '/'
      ? fullHostName.value
      : normalizeUrl(`${fullHostName.value}${route.path}/`, { removeTrailingSlash: false }),
  );

  const getContentExternalLink = (content: Media) => {
    const { slug, contentType } = content;

    const isMovieContent = contentType === MediaContentType.MOVIE;
    const contentRoute = isMovieContent ? AppRoute.Movies : AppRoute.Serials;

    return createExternalLink(createAppLink(contentRoute, slug));
  };

  const getContentRoute = (
    content: Media | CommonMoment | ContentMoment | ChannelProgram,
    options: GetContentRouteOptions = {},
  ): RouteLocationNamedRaw => {
    const { slug, serialId } = content as Media;
    const { contentId, contentSlug, contentType } = content as CommonMoment;
    const { isPlayerPage, episodeId, seasonId } = options;
    const watchingItem = (content as Media).watchingItem;

    const id = (() => {
      if (contentType === MediaContentType.EPISODE) {
        const watchingItem = (content as Media).watchingItem;

        if (watchingItem?.slug) {
          return watchingItem.slug;
        }

        return serialId as string;
      }

      return contentSlug || slug || contentId || content.id;
    })();

    const params: Record<string, string> = { id };

    const seasonNumber = (() => {
      if (contentType !== MediaContentType.EPISODE && contentType !== MediaContentType.SERIAL) {
        return;
      }

      if (seasonId) {
        return seasonId;
      }

      const { seasonNumber: season } = content as ContentMoment;
      if (season) {
        return season;
      }

      const watchingItem = (content as Media).watchingItem;
      if (watchingItem?.seasonNumber) {
        return watchingItem.seasonNumber;
      }
    })();

    const episodeNumber = (() => {
      if (contentType !== MediaContentType.EPISODE && contentType !== MediaContentType.SERIAL) {
        return;
      }

      if (episodeId) {
        return episodeId;
      }

      const { episodeNumber: episode } = content as ContentMoment;
      if (episode) {
        return episode;
      }

      if (watchingItem?.episodeNumber) {
        return watchingItem.episodeNumber;
      }
    })();

    if (seasonNumber) {
      params.seasonId = String(seasonNumber);
    }

    if (episodeNumber) {
      params.episodeId = String(episodeNumber);
    }

    const name = (() => {
      const isSerialPlayerPage =
        (contentType === MediaContentType.SERIAL || contentType === MediaContentType.EPISODE) && isPlayerPage;
      const isMoviePlayerPage = contentType === MediaContentType.MOVIE && isPlayerPage;

      if (isMoviePlayerPage) {
        return AppRoute.MoviePlayer;
      }

      if (isSerialPlayerPage) {
        return AppRoute.SerialPlayer;
      }

      if (contentType === MediaContentType.MOVIE) {
        return AppRoute.MovieCard;
      }

      if (episodeNumber) {
        return AppRoute.SerialEpisodeCard;
      }

      if (seasonNumber) {
        return AppRoute.SerialSeasonCard;
      }

      return AppRoute.SerialCard;
    })();

    return { name, params, query: route.query };
  };

  const shouldRedirectToPlayerPage = (accessKind: ContentAccessKind) =>
    isActiveSubscription.value || (accessKind === ContentAccessTypes.AllUsers && !isPartnerSubscription.value);

  return {
    fullRoute,
    fullHostName,
    getContentRoute,
    getContentExternalLink,
    createExternalLink,
    createAppLink,
    shouldRedirectToPlayerPage,
  };
}
