import { MediaPlayerAnalyzerInstance } from '@package/media-player-analyzer/src/plugin/media-player-instance';
import { storeToRefs } from 'pinia';

import useEnvironment from '@/platform/environment/use-environment';
import { useOffersStore } from '@/stores/use-offers-store';
import { usePromocodeStore } from '@/stores/use-promocode-store';
import { useSessionStore } from '@/stores/use-session-store';

export default function useAsyncPlayer() {
  const sessionsStore = useSessionStore();
  const offersStore = useOffersStore();
  const { user } = storeToRefs(sessionsStore);
  const { currentOffer, currentOfferCurrencySign } = storeToRefs(offersStore);
  const { promocodeCheckInfo } = storeToRefs(usePromocodeStore());

  const { isRelease } = useEnvironment();

  const loadPlayer = async () => {
    const chunk = await import('@package/media-player/src/player/index');

    const { WebVijuPlayer } = chunk;

    WebVijuPlayer.setCurrency(currentOfferCurrencySign.value);
    WebVijuPlayer.setEnvironment(isRelease ? 'production' : 'development');
    WebVijuPlayer.addPlugin(MediaPlayerAnalyzerInstance);

    WebVijuPlayer.setSession({
      user: user.value as any,
      offer: currentOffer.value as any,
      promoOffer: promocodeCheckInfo.value,
    });

    return chunk;
  };

  return {
    loadPlayer,
  };
}
